import React from "react";
import { Link } from "react-router-dom";

export default class AL extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundColor: "#f78b00",
      height: "200px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#fff",
    };
    const myBorder = {
      borderBottom: "2px solid #eaeaea",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-4">
            <div className="row">
              <div className="col-sm-12">
                <h3>Thuê xe tại sân bay với Budget</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <Link
                      to={"/findlocation"}
                      className="breadcrumb-item myLink"
                      aria-current="page"
                    >
                      Trở lại địa điểm
                    </Link>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-4">
            <div className="row" style={myBorder}>
              <div className="col-sm-12">
                <h3 style={{ color: "#000" }}>
                  Thuê xe tại sân bay với Budget
                </h3>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-4">
            <div className="row mt-3">
              <div className="col-sm-12">
                <h5 style={{ color: "#000" }}>
                  <b>Hoa Kỳ</b>
                </h5>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myBorder}>
                <h5 style={{ color: "#000" }}>Alaska</h5>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Albania</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Albania</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Albania</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Albania</a>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myBorder}>
                <h5 style={{ color: "#000" }}>Alabama</h5>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Bahamas</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Bahamas</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Bahamas</a>
              </div>
              <div className="col-sm-4 mt-2">
                <a href="#">Bahamas</a>
              </div>
            </div>
          </div>
        </section>
        <section className="footer-one">
          <div className="container py-3">
            <div className="row">
              <div className="col-sm-12">
                <h4 className="text-white">Mẹo thuê xe tại sân bay</h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <p>
                  Bạn đã hạ cánh và nhận hành lý xong. Tiếp theo là gì? Hãy theo
                  các biển chỉ dẫn đến quầy thuê xe sân bay. Tùy vào sân bay,
                  quầy thường nằm gần khu vực nhận hành lý. Sau khi bạn tìm thấy
                  quầy, bạn sẽ làm thủ tục, ký giấy tờ và nhận chìa khóa. Chẳng
                  bao lâu nữa, bạn sẽ sẵn sàng đến điểm đến tiếp theo.
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <p>
                  Thuê xe có thể hơi khó khăn đôi khi, nhưng với 6 mẹo từ Budget
                  này, bạn sẽ lái chiếc xe thuê từ sân bay trong thời gian ngắn!
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12" style={myPara}>
                <ol>
                  <li className="mb-3">
                    <b>+ Tải ứng dụng Budget:</b> Với ứng dụng Budget, bạn có
                    thể cập nhật đặt chỗ và kiểm tra trạng thái thuê xe trước
                    khi nhận xe.
                  </li>
                  <li className="mb-3">
                    <b>+ Lên kế hoạch tuyến đường:</b> Trước khi đến sân bay,
                    hãy kiểm tra xem điểm đến tiếp theo của bạn cách xa bao
                    nhiêu và nếu có địa điểm cụ thể nào bạn muốn dừng lại trên
                    đường.
                  </li>
                  <li className="mb-3">
                    <b>+ Tìm trạm xăng khi rời sân bay:</b> Để tiết kiệm tiền,
                    bạn nên đổ xăng trước khi trả xe thuê tại sân bay. Khi rời
                    sân bay, hãy tìm trạm xăng gần đó.
                  </li>
                  <li className="mb-3">
                    <b>+ Chọn xe bạn cảm thấy thoải mái:</b> Bạn sẽ lái xe trong
                    một thành phố mới, vì vậy thay vì thuê xe rẻ nhất, hãy xem
                    xét thuê chiếc xe tương tự như bạn lái ở nhà.
                  </li>
                  <li className="mb-3">
                    <b>+ Kiểm tra trước khi nhận xe:</b> Trước khi bắt đầu
                    chuyến đi, hãy kiểm tra nhanh để đảm bảo rằng xe thuê ở
                    trong tình trạng tốt.
                  </li>
                  <li className="mb-3">
                    <b>+ Kiểm tra trước khi trả xe:</b> Dù bạn có vội, hãy chắc
                    chắn kiểm tra giữa các ghế và trong túi xách để xem có vật
                    dụng cá nhân nào bị bỏ quên không.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
