import React from "react";
import { Link } from "react-router-dom";

export default class Pro extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/productservice.avif)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // height: "200px"
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
      cursor: "pointer",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
      cursor: "pointer",
    };
    const myCard = {
      backgroundColor: "#fff",
      cursor: "pointer",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
      cursor: "pointer",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Sản phẩm và Dịch vụ</a>
                    </li>
                    <li className="breadcrumb-item myLink" aria-current="page">
                      Bảo vệ và Bảo hiểm
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Bảo vệ và các gói bảo hiểm cho thuê xe</h1>
                <label className="my-3">
                  <b>Sự an tâm cho lần thuê xe tiếp theo của bạn</b>
                </label>
                <p className="mb-3">
                  Bạn đang tìm kiếm bảo hiểm cho xe thuê của mình? Nếu đúng vậy,
                  bạn đang thực hiện một điều thông minh để bảo vệ bản thân khỏi
                  các tình huống bất ngờ có thể xảy ra khi bạn lái xe. Budget
                  cung cấp nhiều lựa chọn bảo hiểm xe thuê. Cho dù bạn đang đi
                  công tác hay đi nghỉ dưỡng, cùng gia đình hay một mình, bạn sẽ
                  tìm thấy một gói bảo hiểm phù hợp với mình. Bảo hiểm xe thuê
                  phù hợp sẽ mang lại cho bạn sự an tâm, bất kể điều gì xảy ra
                  trên đường. Khám phá các gói bảo hiểm của chúng tôi để bảo vệ
                  bạn trong trường hợp tai nạn, chấn thương, hư hỏng hoặc trộm
                  cắp. Kiểm tra các lựa chọn bảo hiểm xe thuê của chúng tôi ở
                  trên.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-4">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/detail/protection/Loss-Damage-Waiver-(LDW)`,
                  }}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Miễn trừ thiệt hại do mất mát (LDW)
                    </label>
                    <label style={myParas}>
                      Miễn trừ thiệt hại do mất mát (LDW) là một tùy chọn giúp
                      bạn không phải chịu trách nhiệm tài chính nếu xe thuê của
                      bạn bị hư hỏng hoặc bị đánh cắp. Tại một số quốc gia, điều
                      này được gọi là miễn trừ thiệt hại do va chạm (CDW).
                    </label>
                    <label style={myLink}>Tìm hiểu thêm </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/detail/protection/Theft-Protection-(TP)`,
                  }}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Bảo vệ chống trộm (TP)
                    </label>
                    <label style={myParas}>
                      Nếu xe thuê của bạn bị đánh cắp, bảo vệ này sẽ chi trả chi
                      phí thay thế xe hoặc các bộ phận của nó. Tuy nhiên, bạn có
                      thể phải trả khoản khấu trừ.
                    </label>
                    <label style={myLink}>Tìm hiểu thêm </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/detail/protection/Personal-Accident-and-Effects-(PAE)`,
                  }}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Bảo hiểm tai nạn và tài sản cá nhân (PAE)
                    </label>
                    <label style={myParas}>
                      PAE bảo vệ bạn và hành khách khỏi tai nạn, thương tích và
                      mất tài sản cá nhân.
                    </label>
                    <label style={myLink}>Tìm hiểu thêm </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/detail/protection/Supplemental-Liability-Insurance-(SLI)`,
                  }}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Bảo hiểm trách nhiệm bổ sung (SLI)
                    </label>
                    <label style={myParas}>
                      Bảo hiểm trách nhiệm bổ sung bảo vệ bạn và tất cả những
                      người lái xe được ủy quyền khỏi các khiếu nại thương tích
                      và thiệt hại tài sản của bên thứ ba trong khi thuê tại Hoa
                      Kỳ.
                    </label>
                    <label style={myLink}>Tìm hiểu thêm </label>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
