import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";
import FuelPlan from "../components/fuel/FuelPlan";
import Title from "../Title";

const RentalCarFuelPlans = () => {
  Title("Fuel Plan");

  const breadcrumb = [
    "Ưu đãi",
    "Ưu đãi tại Hoa Kỳ",
    "Kế hoạch nhiên liệu cho xe thuê",
  ];

  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <LandingSelectCar
        image=""
        title="Kế hoạch nhiên liệu cho xe thuê Budget"
        sub="Sự tiện lợi và các tùy chọn tốt nhất"
      />

      <FuelPlan />
      <FooterOne />
    </>
  );
};

export default RentalCarFuelPlans;
