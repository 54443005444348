import React from "react";
import { Link } from "react-router-dom";

const ContentTermsofUse = () => {
  return (
    <div className="container py-5 text-dark">
      <h4 className="text-center mb-3">Budget Rent a Car System, Inc.</h4>
      <p className="text-center mb-3">
        <b>Điều khoản sử dụng</b>
      </p>
      <p className="mb-1">Những điều bạn cần biết khi sử dụng budget.com</p>
      <p className="mb-4">
        <b>Tóm tắt:</b> Các Điều khoản này điều chỉnh việc sử dụng Trang web và
        áp dụng cho tất cả lưu lượng truy cập Internet truy cập vào Trang web.
        Bằng cách truy cập hoặc sử dụng Trang web này, bạn đồng ý với các Điều
        khoản này.
      </p>
      <p className="mb-4">
        <b>Cập nhật lần cuối:</b> Ngày 25 tháng 1 năm 2023
      </p>
      <p className="mb-2">
        Chào mừng bạn đến với trang web Budget Rent A Car. VUI LÒNG ĐỌC KỸ CÁC
        ĐIỀU KHOẢN NÀY TRƯỚC KHI TIẾP TỤC SỬ DỤNG TRANG WEB NÀY, ĐẶC BIỆT VÌ CÁC
        ĐIỀU KHOẢN NÀY CÓ THỂ ẢNH HƯỞNG ĐẾN QUYỀN HỢP PHÁP CỦA BẠN, CHẲNG HẠN
        NHƯ{" "}
        <b>
          YÊU CẦU TRỌNG TÀI CÁ NHÂN BẮT BUỘC, TỪ BỎ QUYỀN XÉT XỬ CỦA BẠN BỞI BỒI
          THẨM ĐOÀN VÀ GIỚI HẠN QUYỀN KHỞI KIỆN HOẶC KIỆN TỤNG TẬP THỂ
        </b>{" "}
        – XEM <span className="linked">MỤC 12</span> BÊN DƯỚI. TRANG WEB NÀY VÀ
        THÔNG TIN TRÊN ĐÓ ĐƯỢC KIỂM SOÁT BỞI BUDGET RENT A CAR TẠI HOA KỲ. VUI
        LÒNG XEM THÔNG BÁO QUYỀN RIÊNG TƯ CỦA CHÚNG TÔI ĐỂ BIẾT THÊM THÔNG TIN.
      </p>
      <p className="mb-4">
        Các Điều khoản này nhằm bảo vệ tất cả khách truy cập Trang web của chúng
        tôi và việc bạn sử dụng Trang web này có nghĩa là bạn đồng ý với các
        Điều khoản này. Nếu bạn đang chấp nhận hoặc đồng ý với các Điều khoản
        thay mặt cho một công ty hoặc thực thể pháp lý khác, bạn tuyên bố và bảo
        đảm rằng bạn có quyền hành động thay mặt cho thực thể đó và bị ràng buộc
        với các Điều khoản này. NẾU BẠN KHÔNG ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN NÀY,
        ĐỪNG SỬ DỤNG HOẶC TRUY CẬP TRANG WEB NÀY.
      </p>
      <h1 className="mb-5">1. VỀ CÁC ĐIỀU KHOẢN</h1>
      <h5 className="mb-3">1.1 Định nghĩa.</h5>
      <p className="mb-3">Như được sử dụng trong các Điều khoản Sử dụng này:</p>
      <p className="mb-3">
        ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>“Ứng dụng”</b> đề cập đến bất kỳ ứng
        dụng có thể tải xuống nào (bao gồm ứng dụng di động) do Budget sở hữu
        hoặc điều hành. Tham chiếu đến "Ứng dụng" bao gồm bất kỳ và tất cả các
        tính năng, chức năng, công cụ và nội dung có sẵn trên hoặc thông qua mỗi
        ứng dụng đó.
      </p>
      <p className="mb-3">
        ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>“Người dùng”</b> có nghĩa là bất kỳ
        và tất cả những người truy cập hoặc sử dụng Trang web. Tham chiếu đến
        "truy cập" và/hoặc "sử dụng" Trang web (và bất kỳ biến thể nào của
        chúng) bao gồm các hành vi truy cập hoặc duyệt Trang web hoặc Ứng dụng.
      </p>
      <p className="mb-3">
        ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>“Trang web”</b> đề cập đến bất kỳ ứng
        dụng hoặc trang web nào do Budget sở hữu hoặc điều hành (bao gồm trang
        web hiện tại nằm tại <Link className="linked">www.budget.com</Link>
        ). Tham chiếu đến "Trang web" bao gồm bất kỳ và tất cả các tính năng,
        chức năng, công cụ và nội dung có sẵn trên hoặc thông qua mỗi trang web
        đó.
      </p>
      <p className="mb-4">
        ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>“Budget,”</b> <b>“chúng tôi,”</b>{" "}
        hoặc <b>“chúng tôi”</b> đề cập đến Budget Rent A Car System, Inc. và các
        cán bộ, giám đốc, nhân viên, nhà thầu và đại lý của chúng tôi. Trong
        phạm vi áp dụng, họ cũng đề cập đến các chi nhánh, nhà cung cấp dịch vụ
        và người cấp phép của chúng tôi, cũng như các cán bộ, giám đốc, nhân
        viên, nhà thầu và đại lý tương ứng của họ.
      </p>

      {/* Tiếp tục dịch nội dung bên dưới theo cú pháp tương tự */}
    </div>
  );
};

export default ContentTermsofUse;
