import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OptionHeader from "../common/header/OptionHeader";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Banner from "../components/rent/Banner";
import ChooseCar from "../components/rent/ChooseCar";

const VanCar = () => {
  const breadcrumb = ["Ô tô", "Xe tải"];
  return (
    <>
      <HeaderOne optionHeader={true} />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Banner rent={"Thuê xe tải"} image="banner.webp" type="rentType" sub="" />
      <ChooseCar
        item={"Van"}
        desc={
          "Thuê xe tải là lựa chọn hoàn hảo cho những ai muốn di chuyển cùng một nhóm lớn. Cho dù bạn đi nghỉ với gia đình, công tác với đồng nghiệp hay du lịch cùng bạn bè, bạn sẽ yêu thích không gian và sự thoải mái của chiếc xe tải. Chọn một chiếc minivan để dễ dàng chứa ghế an toàn cho trẻ em hoặc chọn xe tải hành khách để nhóm lên tới 15 người có thể di chuyển cùng nhau. Tìm hiểu thêm về các tùy chọn xe tải từ Budget dưới đây."
        }
      />
      <FooterOne />
    </>
  );
};

export default VanCar;
