import React from "react";

const RoadsideAssitancePlus = () => {
  return (
    <div className="container">
      <hr />
      <h3>Dịch vụ Hỗ trợ Khẩn cấp Plus</h3>
      <b className="mt-3">
        Hỗ trợ 24/7 trong trường hợp xe hỏng hoặc khẩn cấp trên đường
      </b>
      <p className="mt-3 mb-3">
        Dịch vụ Hỗ trợ Khẩn cấp Plus của Budget cung cấp bảo hiểm cho các sự cố
        không liên quan đến động cơ xảy ra trong chuyến đi của bạn mà không phải
        lo lắng về phí gọi cứu hộ.
      </p>
      <b className="mt-3">Dịch vụ Hỗ trợ Khẩn cấp Plus là gì?</b>
      <p className="mt-3 mb-3">
        Bạn sẽ có thêm sự an tâm khi thêm Dịch vụ Hỗ trợ Khẩn cấp Plus vào thuê
        xe của bạn. Bạn sẽ được bảo vệ trong các trường hợp như hết pin hoặc để
        quên chìa khóa trong xe mà không phải trả phí gọi cứu hộ.
      </p>
      <b className="mt-3">Những gì bao gồm trong dịch vụ?</b>
      <p className="mt-3">
        Ngoài hỗ trợ sự cố xe hỏng đã bao gồm trong thuê xe của bạn, bạn còn
        được bảo vệ trong các trường hợp sự cố phổ biến không liên quan đến động
        cơ bao gồm:
      </p>
      <p className="">
        • Hỗ trợ lốp xe - Chúng tôi sẽ thay lốp xẹp hoặc hỏng bằng lốp dự phòng,
        hoặc cung cấp dịch vụ kéo xe (Lưu ý: bạn có thể phải trả phí sửa chữa
        hoặc thay lốp, tùy theo tình huống).
      </p>
      <p className="">
        • Hết pin - Chúng tôi sẽ giúp bạn khởi động lại xe của mình.
      </p>
      <p className="">
        • Hỗ trợ mở cửa - Nếu bạn để quên chìa khóa trong xe, chúng tôi sẽ giúp
        bạn mở cửa xe.
      </p>
      <p className="">
        • Cung cấp nhiên liệu - Nếu bạn hết xăng, chúng tôi sẽ mang nhiên liệu
        đến vị trí của bạn. Có thể áp dụng phí dịch vụ.
      </p>
      <p className="mb-3">
        • Mất chìa khóa - Chúng tôi sẽ cung cấp chìa khóa dự phòng nếu bạn làm
        mất chìa khóa.
      </p>
      <b className="mt-3">Thông tin bổ sung</b>
      <p className="mt-3">
        Dịch vụ Hỗ trợ Khẩn cấp Plus có sẵn tại các trạm Budget trên khắp Vương
        quốc Anh và Châu Âu. Dịch vụ Hỗ trợ Khẩn cấp Plus có sẵn tại các địa
        điểm Budget tham gia tại Hoa Kỳ, Canada, Puerto Rico và Quần đảo Virgin
        thuộc Hoa Kỳ. Phí hàng ngày khác nhau tùy theo địa điểm thuê và có thể
        thay đổi. Việc sử dụng xe không được phép có thể khiến tùy chọn này bị
        vô hiệu. Trong những trường hợp đó, hỗ trợ từ dịch vụ khẩn cấp trên
        đường vẫn có sẵn, nhưng các dịch vụ tiêu chuẩn như mất chìa khóa, cung
        cấp nhiên liệu, v.v. có thể bị tính phí.
      </p>
      <p className="mt-3">
        Dịch vụ hỗ trợ khẩn cấp tương tự có sẵn tại tất cả các địa điểm thuê
        Budget khác với một khoản phí bổ sung.
      </p>
      <p className="mt-3">
        Tất cả các xe của Budget đều có dịch vụ hỗ trợ khẩn cấp 24 giờ tự động
        trong trường hợp hỏng động cơ không phải do lỗi của người thuê. Nếu bạn
        cần hỗ trợ trong chuyến đi, vui lòng gọi số điện thoại được liệt kê
        trong hợp đồng thuê, và chúng tôi sẽ giúp bạn quay trở lại đường sớm
        nhất có thể. Phí có thể áp dụng trong các tình huống yêu cầu hỗ trợ khẩn
        cấp do lỗi của bạn.
      </p>
      <p className="mt-3">
        Lưu ý: Cấp độ hỗ trợ khẩn cấp cơ bản được mô tả ở trên áp dụng cho các
        địa điểm Budget thuộc sở hữu của công ty. Dịch vụ hỗ trợ cho thuê tại
        các địa điểm được cấp phép có thể khác nhau.
      </p>
      <p className="mt-3">
        Xin lưu ý rằng chi phí cho Dịch vụ Hỗ trợ Khẩn cấp Plus có thể khác nhau
        tùy theo quốc gia. Dịch vụ có thể được mua tại quầy khi bạn nhận xe.
      </p>
      <hr />
    </div>
  );
};

export default RoadsideAssitancePlus;
