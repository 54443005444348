import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Header from "../common/header/Header";
import History from "../components/about/History";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Title from "../Title";

const MilestoneEvents = () => {
  Title("Historical Milestones");
  const breadcrumb = [
    "Giới thiệu về Budget Rent A Car",
    "Thông tin công ty",
    "Các cột mốc lịch sử",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Header
        title="Lịch sử của Budget Car Rental"
        subtitle="Lịch sử của Budget Rent A Car"
      />
      <History />
      <FooterOne />
    </>
  );
};

export default MilestoneEvents;
