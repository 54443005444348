import React from "react";

const OfferDetails = () => {
  return (
    <div className="container">
      <hr />
      <h3>Chia sẻ việc lái xe...</h3>
      <p className="mt-3 mb-3">
        Thêm một hoặc nhiều tài xế bổ sung vào hợp đồng thuê xe của bạn để bạn
        có thể nghỉ ngơi trong khi người khác được phép lái xe. Bằng cách chia
        sẻ trách nhiệm lái xe, bạn có thể giảm nguy cơ tai nạn. Lưu ý rằng nếu
        người lái xe không được chỉ định gây tai nạn, bảo hiểm của bạn sẽ bị hủy
        bỏ.
      </p>
      <p className="mt-3 mb-3">
        Tài xế bổ sung được phép lái xe nếu họ đáp ứng các yêu cầu sau:
      </p>
      <ol className="mb-3">
        <li>
          • Khi nhận xe, tất cả tài xế bổ sung phải có mặt và xuất trình bằng
          lái xe của họ.
        </li>
        <li>
          • Nếu tài xế bổ sung dưới 25 tuổi (tuổi có thể khác nhau tùy theo quốc
          gia thuê xe), có thể bị tính phí bổ sung.
        </li>
      </ol>
      <p className="mt-3 mb-3">
        Tài xế bổ sung phải tuân thủ các điều khoản và điều kiện giống như tài
        xế chính để được bảo hiểm theo hợp đồng thuê xe giống nhau.
      </p>
      <p className="mt-3 mb-3">
        Bạn có thể thêm tài xế bổ sung vào hợp đồng thuê xe của mình khi nhận
        xe. Sẽ có phí tài xế bổ sung cho mỗi người được thêm vào.
      </p>
    </div>
  );
};

export default OfferDetails;
