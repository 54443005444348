import React from "react";
import { Link } from "react-router-dom";

export default class Ser extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      // backgroundImage: "url("+publicUrl+"assets/images/resources/productservice.avif)",
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
      // backgroundRepeat: 'no-repeat',
      // height: "200px"
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
      minHeight: "10px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };
    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link>Sản phẩm và Dịch vụ</Link>
                    </li>
                    <li className="breadcrumb-item myLink" aria-current="page">
                      Dịch vụ
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Dịch vụ thuê xe Budget</h1>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-4">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={process.env.PUBLIC_URL + `/fuel-plans`}>
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        Kế hoạch nhiên liệu
                      </label>
                      <label style={myParas}>
                        Những cuộc phiêu lưu trên đường đôi khi xảy ra bất ngờ
                        và bạn không biết hành trình sẽ đưa bạn đến đâu. Đó là
                        lý do tại sao chúng tôi có 3 tùy chọn nhiên liệu dành
                        cho bạn, phù hợp với mọi loại hành trình.
                      </label>
                      <label style={myLink}>Tìm hiểu thêm </label>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={process.env.PUBLIC_URL + `/roadside-assistance`}>
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        Dịch vụ Hỗ trợ Khẩn cấp Plus
                      </label>
                      <label style={myParas}>
                        Nhận hỗ trợ khẩn cấp từ Budget để thay chìa khóa, thay
                        lốp, khởi động xe và nhiều dịch vụ khác dễ dàng.
                      </label>
                      <label style={myLink}>Tìm hiểu thêm </label>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={process.env.PUBLIC_URL + `/onewaycarrental`}>
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        Thuê xe một chiều
                      </label>
                      <label style={myParas}>
                        Lên kế hoạch cho chuyến đi một chiều của bạn để nhận xe
                        tại một địa điểm và trả xe tại địa điểm khác. Ghé thăm
                        Budget để có mức giá cạnh tranh.
                      </label>
                      <label style={myLink}>Tìm hiểu thêm </label>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={process.env.PUBLIC_URL + `/additional-driver`}>
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        Tài xế bổ sung
                      </label>
                      <label style={myParas}>
                        Chia sẻ việc lái xe và thêm một hoặc nhiều tài xế bổ
                        sung vào hợp đồng thuê của bạn với Budget.
                      </label>
                      <label style={myLink}>Tìm hiểu thêm </label>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={process.env.PUBLIC_URL + `/budget-worry-free-promise`}
                >
                  <div className="row">
                    <div className="col-12 p-3" style={myCard}>
                      <label className="mb-2" style={myHead}>
                        Cam kết Không lo lắng của Budget
                      </label>
                      <label style={myParas}>
                        Chúng tôi đảm bảo rằng chúng tôi đã thực hiện vô số biện
                        pháp an toàn để đảm bảo trải nghiệm này an toàn cho tất
                        cả mọi người.
                      </label>
                      <label style={myLink}>Tìm hiểu thêm </label>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
