import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";
import RoadsideAssitancePlus from "../components/road/RoadsideAsistancePlus";
import Title from "../Title";

const RoadsideAssitance = () => {
  Title("Roadside Asistance");

  const breadcrumb = [
    "Ưu đãi",
    "Ưu đãi tại Hoa Kỳ",
    "Dịch vụ Hỗ trợ Khẩn cấp Plus",
  ];

  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <LandingSelectCar
        image=""
        title="Dịch vụ Hỗ trợ Khẩn cấp Plus của Budget Rental Car"
        sub="Tận hưởng trải nghiệm lái xe an toàn và không lo lắng!"
      />

      <RoadsideAssitancePlus />
      <FooterOne />
    </>
  );
};

export default RoadsideAssitance;
