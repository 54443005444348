import React from "react";
import FindOut from "../rent/FindOut";
import Van from "./Van";

const VanContainer = () => {
  return (
    <div className="container pb-5">
      <div className="row mb-5">
        <Van
          image={"van1.webp"}
          title={"Thuê xe minivan"}
          desc={
            "Thuê xe minivan như Chrysler Voyager và các mẫu tương tự có thể chở thoải mái tới 7 hành khách và có cửa trượt tự động giúp việc ra vào dễ dàng."
          }
          button={"Minivan"}
        />
        <Van
          image={"van2.webp"}
          title={"Thuê xe tải 12 chỗ"}
          desc={
            "Lái xe tải Ford Transit 12 chỗ hoặc các mẫu tương tự và tận hưởng không gian rộng rãi với nhiều chỗ để hành lý cho vali và đồ lưu niệm."
          }
          button={"Xe tải 12 chỗ"}
        />
        <Van
          image={"van3.webp"}
          title={"Thuê xe tải 15 chỗ"}
          desc={
            "Xe tải cho thuê Chevrolet Express 15 chỗ là lựa chọn tốt nhất cho những nhóm lớn nhất, lý tưởng cho các chuyến du lịch theo nhóm, chuyến đi của trường học và các buổi họp mặt gia đình."
          }
          button={"Xe tải 15 chỗ"}
        />
      </div>
      <FindOut
        title={"Tìm hiểu các mẫu xe tải cho thuê"}
        desc1={
          "Nếu bạn có kế hoạch thuê xe tải từ Budget, đừng chờ đợi nữa, bắt đầu ngay lập tức. Chúng tôi làm cho việc đặt xe của bạn trở nên dễ dàng với mức giá tuyệt vời mỗi ngày. Chỉ cần điền vào biểu mẫu trên với địa điểm nhận và trả xe, ngày và giờ bạn mong muốn, sau đó bạn sẽ có thể xem các tùy chọn phương tiện có sẵn cho chuyến đi của bạn trong trang tiếp theo."
        }
        desc2={
          "Hãng xe, mẫu mã, trang trí, tính năng và màu sắc của xe không thể được đảm bảo và phụ thuộc vào địa điểm và tình trạng sẵn có. Tính năng của xe có thể thay đổi tùy thuộc vào năm sản xuất của mẫu xe."
        }
      />
    </div>
  );
};

export default VanContainer;
