import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OfferDetails from "../components/additionaldriver/OfferDetails";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";

const AdditonalDriver = () => {
  const breadcrumb = ["Sản phẩm và Dịch vụ", "Dịch vụ", "Tài xế bổ sung"];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <LandingSelectCar
        image=""
        title="Tài xế bổ sung của Budget"
        sub="Sự tiện lợi và các tùy chọn tốt nhất"
      />
      <OfferDetails />
      <FooterOne />
    </>
  );
};

export default AdditonalDriver;
