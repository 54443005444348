import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Header from "../common/header/Header";
import AU from "../components/about/Au";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Title from "../Title";

const AboutUs = () => {
  Title("About us");
  const breadcrumb = [
    "Về Budget Rent A Car",
    "Thông Tin Công Ty",
    "Về Chúng Tôi",
  ];

  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Header title="Về Chúng Tôi" subtitle="" />
      <AU />
      <FooterOne />
    </>
  );
};

export default AboutUs;
