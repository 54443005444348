import React from "react";
import Card from "../../common/card/Card";

const Info = () => {
  return (
    <div className="carsContainer">
      <div className="container py-5 px-4">
        <div className="row py-4">
          <Card
            title="Về Chúng Tôi"
            description="Budget kết hợp sức mạnh của một trong những thương hiệu cho thuê xe lớn nhất thế giới cùng với kiến thức địa phương chi tiết, sự linh hoạt và giá trị tuyệt vời."
            link="/about-budget/company-information/about-us"
          />
          <Card
            title="Lịch Sử Ngắn Gọn của Budget"
            description="Những Dấu Mốc Lịch Sử"
            description2="Nhìn lại lịch sử của công ty cho thuê xe Budget qua các mốc thời gian. Xem cách Budget đã phát triển qua nhiều năm thành một công ty cho thuê xe được công nhận và tôn trọng toàn cầu."
            link="/about-budget/company-information/milestone-events"
          />
          <Card
            title="Thông Báo Bản Quyền"
            description="Hình Ảnh Ô Tô, Bản Quyền [2000 – 2019] izmo, Inc. Tất Cả Quyền Được Bảo Lưu."
            link="/copyright-notices"
          />
        </div>
      </div>
    </div>
  );
};

export default Info;
