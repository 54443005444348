import React from "react";
import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <div className="container pb-5">
      <h1>คำถามที่พบบ่อย</h1>
      <p className="question mt-2 mb-2">ฉันจะเช่ารถหรูได้อย่างไร?</p>
      <p>
        เมื่อคุณพร้อมที่จะเริ่มการจอง
        เพียงกรอกแบบฟอร์มด้านบนด้วยสถานที่รับและวันที่เช่าที่คุณวางแผนไว้
        จากนั้นยืนยันยานพาหนะที่มีให้บริการ
      </p>
      <p className="question mt-2 mb-2">
        ฉันสามารถเช่ารถหรูจาก Budget ได้ที่ไหน?
      </p>
      <p>
        Budget มีบริการเช่ารถหรูในบางสถานที่ทั่วสหรัฐอเมริกา
        หากการเดินทางของคุณนำคุณไปยังสถานที่ Budget ที่มีบริการรถหรูเหล่านี้
        ให้ใช้โอกาสนี้และปรนเปรอตัวเองด้วยการเช่ารถเก๋งหรู
        ส่งสถานที่รับและวันที่ที่คุณต้องการในแบบฟอร์มด้านบนเพื่อดูความพร้อมของยานพาหนะ
      </p>
      <p className="similar">ตัวเลือกรถที่คล้ายกัน</p>
      <ul className="similarCarOpt ps-4">
        <li>
          <Link to={`${process.env.PUBLIC_URL}/car-explore`}>
            สำรวจรถเก๋งสปอร์ตหรู
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/car-explore`}>
            สำรวจรถเก๋งหรูขนาดใหญ่
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/car-explore`}>
            สำรวจรถเก๋งหรูขนาดกลาง
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default FAQ;
