import React from "react";
import { Link } from "react-router-dom";

const History = () => {
  return (
    <div className="container py-4">
      <p className="mb-2">
        <b>1958</b>
      </p>
      <p className="mb-2">
        Budget Rent A Car được thành lập tại Los Angeles bởi Morris Mirkin với
        khoản đầu tư 10.000 đô la. Tên công ty được chọn để thu hút những người
        thuê "quan tâm đến ngân sách" hoặc "coi trọng giá trị".
      </p>
      <p className="mb-2">
        <b>1959</b>
      </p>
      <p className="mb-2">
        Người họ hàng xa của Mirkin, Jules Lederer, bị hấp dẫn bởi ý tưởng của
        Budget và tạo ra một công ty cho thuê để giúp Mirkin tăng đội xe và mở
        rộng doanh nghiệp.
      </p>
      <p className="mb-2">
        <b>1960</b>
      </p>
      <p className="mb-2">
        Lederer và Mirkin thành lập Budget Rent A Car Corporation tại Chicago,
        và công ty nhanh chóng phát triển cả trong nước và quốc tế.
      </p>
      <p className="mb-2">
        <b>1986</b>
      </p>
      <p className="mb-2">
        Gibbons, Green và van Amerongen Ltd., cùng với ban quản lý và các nhà
        đầu tư chọn lọc, mua lại Budget từ Transamerica Corp. trong một giao
        dịch mua lại có đòn bẩy.
      </p>
      <p className="mb-2">
        <b>1987</b>
      </p>
      <p className="mb-2">
        Budget thông báo đợt chào bán công khai lần đầu với 3,2 triệu cổ phiếu.
      </p>
      <p className="mb-2">
        <b>1992</b>
      </p>
      <p className="mb-2">
        Budget chuyển trụ sở chính tại trung tâm Chicago lâu năm đến Lisle, IL.
      </p>
      <p className="mb-2">
        <b>1997</b>
      </p>
      <p className="mb-2">
        Budget được mua lại bởi Team Rental Group, nhà nhượng quyền lớn nhất của
        Budget tại Mỹ, và đổi tên thành Budget Group, Inc. Budget Group bắt đầu
        giao dịch trên NYSE. Cuối năm đó, Budget ra mắt chương trình khách hàng
        thân thiết cho đại lý du lịch "Unlimited Budget", qua đó thành viên có
        thể nhận thẻ MasterCard ghi nợ Unlimited Budget miễn phí và kiếm điểm
        thưởng giá trị cho các lượt đặt chỗ đã hoàn tất.
      </p>
      <p className="mb-2">
        <b>1998</b>
      </p>
      <p className="mb-2">
        Budget giới thiệu dịch vụ "Fastbreak", dịch vụ giao dịch không cần giấy
        tờ.
      </p>
      <p className="mb-2">
        <b>2002</b>
      </p>
      <p className="mb-2">
        Budget Group bị xóa niêm yết khỏi NYSE và bán tài sản Budget Rent A Car
        tại Hoa Kỳ, Canada, Mỹ Latinh, Caribbean, Úc và New Zealand cho Cendant
        Corporation.
      </p>
      <p className="mb-2">
        Các tài sản toàn cầu khác được bán cho Avis Europe plc.
      </p>
      <p className="mb-2">
        Cendant chuyển trụ sở chính của Budget từ Lisle, IL đến Parsippany, NJ.
      </p>
      <p className="mb-2">
        <b>2003</b>
      </p>
      <p className="mb-2">
        Budget triển khai “Chương trình Doanh nghiệp Budget”, một chương trình
        giảm giá dành cho doanh nghiệp cung cấp cho các doanh nghiệp nhỏ và vừa
        mức giá ưu đãi doanh nghiệp, dịch vụ Fastbreak miễn phí và không tính
        phí lái xe bổ sung.
      </p>
      <p className="mb-2">
        Budget ra mắt “Budget Pride”, một chương trình động viên nhân viên toàn
        diện.
      </p>
      <p className="mb-2">
        <b>2006</b>
      </p>
      <p className="mb-2">
        Tập đoàn Cendant tách ra thành bốn công ty độc lập: Realogy, Wyndham
        Worldwide, Travelport và Avis Budget Group, Inc. Thông qua sự tách rời
        này, Budget Rent A Car System, Inc. trở thành công ty con của Avis
        Budget Group.
      </p>
      <p className="mb-2">
        <b>2009</b>
      </p>
      <p className="mb-2">
        Avis Budget Group phản hồi lại ý kiến khách hàng và áp dụng chính sách
        không hút thuốc trong các phương tiện của mình. Công ty cũng thiết lập
        chính sách không hút thuốc đối với tất cả nhân viên và nhà thầu lái xe.
      </p>
      <p className="mb-2">
        <b>2011</b>
      </p>
      <p className="mb-2">
        Avis Budget Group mua lại Avis Europe plc. Giao dịch này tái hợp Avis
        Budget Group với đơn vị nhượng quyền tại châu Âu, Trung Đông, Châu Phi
        và Châu Á.
      </p>
      <p className="mb-2">
        Avis Budget Group tạo ra ba khu vực hoạt động. Bao gồm: Bắc Mỹ, Châu Âu,
        Trung Đông và Châu Phi (EMEA), và Mỹ Latinh/Châu Á.
      </p>
      <Link
        to={`${process.env.PUBLIC_URL}/makereservation`}
        className="btnCar d-inline-block mt-3 ms-3"
      >
        Đặt xe
      </Link>
    </div>
  );
};

export default History;
