import React from "react";
import Card from "../../common/card/Card";

const ContentFraudScam = () => {
  return (
    <div className="carsContainer">
      <div className="container py-5 px-4">
        <div className="row">
          <Card
            title="Liên hệ chúng tôi"
            description="Tìm câu trả lời cho tất cả các câu hỏi của bạn về dịch vụ thuê xe Budget tại trang Câu hỏi thường gặp. Từ giới hạn độ tuổi đến câu hỏi về tín dụng và bảo hiểm, bạn sẽ tìm thấy câu trả lời tại đây."
            link="/contact-us"
          />
          <Card
            title="Đặt chỗ / Hủy"
            description="Tìm câu trả lời cho tất cả các câu hỏi của bạn về dịch vụ thuê xe Budget tại trang Câu hỏi thường gặp. Từ giới hạn độ tuổi đến câu hỏi về tín dụng và bảo hiểm, bạn sẽ tìm thấy câu trả lời tại đây."
            link="/view-cancel"
          />
          <Card
            title="Sơ đồ trang web"
            description="Tìm câu trả lời cho tất cả các câu hỏi của bạn về dịch vụ thuê xe Budget tại trang Câu hỏi thường gặp. Từ giới hạn độ tuổi đến câu hỏi về tín dụng và bảo hiểm, bạn sẽ tìm thấy câu trả lời tại đây."
            link="/sitemap"
          />
          <Card
            title="Điều khoản và điều kiện cho thuê địa điểm"
            description="Tìm câu trả lời cho tất cả các câu hỏi của bạn về dịch vụ thuê xe Budget tại trang Câu hỏi thường gặp. Từ giới hạn độ tuổi đến câu hỏi về tín dụng và bảo hiểm, bạn sẽ tìm thấy câu trả lời tại đây."
            link="https://www.budget.co.uk/TermsAndCondition"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentFraudScam;
