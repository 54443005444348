import React, { useState } from "react";
import { Link } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import Nav from "./Nav";
import OptionHeader from "./OptionHeader";
import SignModal from "./SignModal";

const HeaderOne = (props) => {
  const [btnMenu, setBtnMenu] = useState(false);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <header
        className="main-header main-header-one clearfix"
        style={{
          background: "#fff",
          position: "sticky",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "99",
        }}
      >
        <div className="main-header-one__wrapper">
          <div className="main-header-one__top">
            <div className="container">
              <div className="main-header-one__top-inner">
                <div className="main-header-one__top-address">
                  <ul>
                    <li>
                      {/* <div className="icon"><span className="icon-pin"></span></div>
                                                <div className="text"><p>27/52 Avenue, NY USA 685.</p></div> */}
                    </li>

                    <li>
                      {/* <div className="icon"><span className="icon-clock"></span></div>
                                                <div className="text"><p>Mon - Sat 8.00 - 18.00. Sun Closed</p></div> */}
                    </li>
                  </ul>
                </div>

                <div className="main-header-one__top-address d-xl-flex d-none">
                  <ul>
                    {localStorage.getItem("token") ? (
                      <li className="dropdown">
                        <a href={publicUrl + "userhome"}>
                          <span className="fa fa-user"></span>{" "}
                          {localStorage.getItem("name")}
                        </a>
                      </li>
                    ) : (
                      <>
                        <li className="dropdown">
                          <Link to={process.env.PUBLIC_URL + `/register`}>
                            Đăng ký
                          </Link>
                        </li>
                        <li className="dropdown pe-2 border-end">
                          <Link to={process.env.PUBLIC_URL + `#`}>
                            <SignModal />
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <Link to={publicUrl + "customer-care"}>
                        Dịch vụ khách hàng
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://faq.budget.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Câu hỏi thường gặp
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="main-header-one__bottom">
            <nav className="main-menu main-menu--1">
              <div className="main-menu__wrapper">
                <div className="container">
                  <div className="main-menu__wrapper-inner">
                    <div className="main-header-one__bottom-left">
                      <div className="logo">
                        <Link to={process.env.PUBLIC_URL + `/`}>
                          <img
                            src={publicUrl + "assets/images/icon/budget.png"}
                            alt=""
                            width="450"
                          />
                        </Link>
                      </div>
                    </div>

                    <div className="main-header-one__bottom-right">
                      <Nav btnIsMenu={btnMenu} setIsMenu={setBtnMenu} />

                      {/* <div className="main-header-one__bottom-right-search">
                                                    <SearchButton />
                                                </div> */}

                      {/* <LanguageSwitcher /> */}

                      {/* <div className="main-header-one__bottom-right-btn">
                                                    <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn">+ Add Listing </Link>
                                                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {props.optionHeader ? btnMenu ? "" : <OptionHeader /> : ""}
    </>
  );
};

export default HeaderOne;
