import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderAlt from "../common/header/HeaderAlt";
import CC from "../components/choosecars/CC";
// import CCN from "../components/choosecars/CC";

const ChooseCars = () => {
  const before = useLocation();
  const navigate = useNavigate();
  // console.log(before);
  return (
    <>
      <HeaderAlt />
      <CC before={before.state} navigate={navigate} />
      <FooterOne />
    </>
  );
};

export default ChooseCars;
