import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Header from "../common/header/Header";
import ContentAbout from "../components/about/ContentAbout";
import Title from "../Title";

const About = () => {
  Title("About");
  return (
    <>
      <HeaderOne />
      <Header
        title="Về Budget Rent a Car"
        subtitle="Cho thuê những chiếc xe tuyệt vời với giá trị hợp lý cho khách hàng từ năm 1958"
      />

      <ContentAbout />
      <FooterOne />
    </>
  );
};

export default About;
