import React from "react";
import { Link } from "react-router-dom";
import List from "./List";

const ContentSiteMap = () => {
  return (
    <>
      <hr />
      <h3>Đặt chỗ</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/makereservation"}>Thực hiện đặt chỗ</Link>
          </p>
          <p>
            <Link to={"/view-cancel"}>Xác nhận/Hủy đặt chỗ</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Sự kiện và ưu đãi đặc biệt</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/international"}>Khuyến mãi</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Hướng dẫn xe</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/van-rental"}>Xe tải 7-15 chỗ ngồi</Link>
          </p>
          <p>
            <Link to={"/suv-rental"}>SUV mọi kích thước</Link>
          </p>
          <p>
            <Link to={"/pickup-truck-rent"}>Xe bán tải chở hàng</Link>
          </p>
          <p>
            <Link to={"/rent-car-sizes"}>Ô tô từ tiết kiệm đến sang trọng</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Sản phẩm và dịch vụ</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/productservice/products"}>Sản phẩm</Link>
          </p>
          <p>
            <Link to={"/productservice/services"}>Dịch vụ</Link>
          </p>
          <p>
            <Link to={"/productservice/protection"}>Bảo hiểm và bảo vệ</Link>
          </p>
          <p>
            <Link to={"/productservice/program"}>Chương trình</Link>
          </p>
        </div>
        <div className="col-md-4">
          <p>
            <Link>Giảm giá thuê xe cho cựu chiến binh</Link>
          </p>
          <p>
            <Link>Giảm giá thuê xe cho thành viên AARP</Link>
          </p>
          <p>
            <Link>Giảm giá thuê xe từ đối tác hàng không</Link>
          </p>
          <p>
            <Link>Giảm giá thuê xe từ đối tác khách sạn</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Địa điểm</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/findlocation"}>Tìm địa điểm</Link>
          </p>
          <p>
            <Link to={"/airportlocation"}>Địa điểm phổ biến tại sân bay</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Tìm kiếm phổ biến</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <a
              href="https://faq.budget.co.uk/?Brand=Budget"
              target="_blank"
              rel="noreferrer"
            >
              Câu hỏi thường gặp
            </a>
          </p>
          <p>
            <Link to={"/onewaycarrental"}>Thuê xe một chiều</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Dịch vụ và chương trình</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/productservice/protection"}>Bảo hiểm và bảo vệ</Link>
          </p>
          <p>
            <Link to={"/roadside-assistance"}>
              Dịch vụ hỗ trợ bên đường Plus
            </Link>
          </p>
          <p>
            <Link to={"/roadside-assistance"}>Kế hoạch nhiên liệu</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Hỗ trợ khách hàng</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/contact-us"}>Liên hệ chúng tôi</Link>
          </p>
          <p>
            <Link to={"/sitemap"}>Sơ đồ trang web</Link>
          </p>
        </div>
      </div>

      <hr />
      <h3>Thông tin công ty</h3>
      <div className="siteMapList row pt-3 pb-4">
        <div className="col-md-4">
          <p>
            <Link to={"/about-budget"}>Về Budget</Link>
          </p>
          <p>
            <Link to={"/about-budget/company-information/milestone-events"}>
              Lịch sử tóm tắt
            </Link>
          </p>
          <p>
            <Link to={"/copyright-notices"}>Thông báo bản quyền</Link>
          </p>
          <p>
            <Link to={"/terms-of-use"}>Điều khoản sử dụng</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default ContentSiteMap;
