import React from "react";
import { Link } from "react-router-dom";
import SignModal from "../../common/header/SignModal";
import Axios from "axios";
import { API_URL } from "../../core/constant";
import { getToken } from "../../core/functions";

export default class vmc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      last_name: "",
      bookingCode: "",
      countryCode: "ID",
      isShowBooking: false,
      bookingData: {},
      isLoading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const thiss = this;
    const state = this.state;
    const props = this.props;
    thiss.setState({ isLoading: true });
    getToken();
    Axios.get(
      `https://stage.abgapiservices.com/cars/reservation/v2?brand=Budget&last_name=${this.state.last_name}&confirmation_number=${this.state.bookingCode}`,
      {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("bebe"),
          client_id: "04e19f27",
        },
      }
    )
      .then(function (response) {
        // handle success
        props.navigate("/view_booking", {
          state: {
            bookingData: response.data,
          },
        });
        return response.data;
      })
      .then(function (data) {
        // handle success
        Axios.get(
          API_URL +
            "/api/view-booking?bookingNumber=" +
            data.reservation?.confirmation?.number
        )
          .then((response2) => {
            console.log(response2.data.booking);
            props.navigate("/view_booking", {
              state: {
                bookingData: data,
                bookingDataApi: response2.data.booking,
              },
            });
            thiss.setState({ isLoading: false });
          })
          .catch((error2) => {
            console.log(error2);
            alert("Booking not found or Please try again");
            thiss.setState({ isLoading: false });
            getToken();
            window.location.reload();
          });
        thiss.setState({ isLoading: false });
      })
      .catch(function (error) {
        alert("Booking not found or Please try again");
        thiss.setState({ isLoading: false });
        getToken();
        window.location.reload();
      })
      .finally(function () {
        // always executed
        thiss.setState({ isLoading: false });
      });
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/reservation.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    const myLink = {
      color: "rgb(212, 0, 42)",
    };

    return (
      <>
        {/* Bắt đầu Slogan One */}
        <section style={myStyle}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center pt-5">
                <h2 style={{ color: "#000" }}>Xem / Hủy</h2>
              </div>
            </div>
            <div
              className="row mt-5 mb-5"
              style={{
                backgroundColor: "#fff",
                textAlign: "center",
              }}
            >
              <div className="col-sm-12 p-4 text-budget">
                {this.state.isShowBooking ? (
                  <>
                    <h3 style={myLink}>Đặt chỗ của bạn</h3>

                    <div
                      style={{ backgroundColor: "#f0f1f2" }}
                      className="mt-3"
                    >
                      <h5 className="text-start p-3 text-dark fw-bold">
                        <b>Mã tham chiếu của bạn: 4046-5154-ID-2</b>
                      </h5>

                      <div
                        style={{
                          borderTop: "1px solid #dedfe0",
                          borderBottom: "1px solid #dedfe0",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{ color: "#000", flexGrow: "1.5" }}
                          className="text-start p-3"
                        >
                          <span style={myLink}>Thời gian & Địa điểm</span>
                          <br />
                          <br />
                          18 Thg 9 10:00 → 19 Thg 9 10:00
                          <br />
                          Sân bay London Heathrow T2,3 và 4T
                        </div>
                        <div
                          style={{ color: "#000", flexGrow: "2" }}
                          className="text-start p-3"
                        >
                          <span style={myLink}>Phương tiện</span>
                          <br />
                          <br />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p>Nhỏ</p>
                            <p>$151</p>
                          </div>
                          <p style={{ fontSize: "13px" }}>Xe nhóm A Flat 500</p>

                          <img
                            className="mt-3"
                            src="https://www.budget.com/content/dam/cars/s/2020/ford/2020-ford-transit-350-xlt-low-roof-passenger-van-white.png"
                            width={200}
                          />
                        </div>
                        <div
                          style={{ color: "#000", flexGrow: "1.5" }}
                          className="text-start p-3"
                        >
                          <span style={myLink}>Thêm</span>
                          <br />
                          <br />

                          <p>Không có lựa chọn bổ sung nào</p>
                        </div>
                      </div>

                      <h5 className="text-end p-3 fw-bold text-dark">
                        Tổng cộng $550
                      </h5>
                    </div>

                    <div
                      className="p-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "start",
                        width: "100%",
                      }}
                    >
                      <div className="me-3" style={{ flexGrow: "1" }}>
                        <h5 className="text-start text-dark fw-bold">
                          <b>Khi bạn nhận xe</b>
                        </h5>

                        <p style={{ color: "black", textAlign: "left" }}>
                          Một thẻ thanh toán phải đứng tên của người lái xe
                          chính
                          <br /> (trừ khi được đặt bằng thẻ tín dụng công ty
                          hoặc theo hợp đồng)
                          <br /> và bạn cần xuất trình các tài liệu sau tại
                          quầy:
                        </p>

                        <ul className="mt-3 text-start">
                          <li>
                            <span style={myLink}>&#10003;</span> Bằng lái xe hợp
                            lệ
                          </li>
                          <li>
                            <span style={myLink}>&#10003;</span> 1 thẻ thanh
                            toán
                          </li>
                        </ul>
                      </div>
                      <div className="flex-grow-1" style={{ flexGrow: "2" }}>
                        <p className="text-start text-dark fw-bold">
                          Đặt chỗ của bạn bao gồm:
                        </p>

                        <p style={{ color: "black", textAlign: "left" }}>
                          (Những gì bao gồm có thể phụ thuộc vào tỷ lệ hợp đồng
                          của bạn)
                        </p>

                        <ul className="mt-3 text-start">
                          <li>
                            <span style={myLink}>&#10003;</span> Bằng lái xe hợp
                            lệ
                          </li>
                          <li>
                            <span style={myLink}>&#10003;</span> 1 thẻ thanh
                            toán
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      style={{ backgroundColor: "#f0f1f2" }}
                      className="mt-3 p-3"
                    >
                      <h5 className="text-start text-dark fw-bold">
                        <b>Hướng dẫn đến trạm cho thuê xe của bạn</b>
                      </h5>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "start",
                          width: "100%",
                        }}
                      >
                        <div>
                          <h6 className="text-start mt-3 fw-bold text-dark">
                            Nhận xe
                          </h6>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "start",
                              marginTop: "10px",
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                textAlign: "left",
                                marginRight: "10px",
                              }}
                            >
                              Sân bay London Heathrow T2,3 và 4T
                              <br />
                              London
                              <br />
                              TW6 2GW
                              <br />
                              Vương quốc Anh
                            </p>
                            <p style={{ color: "black", textAlign: "left" }}>
                              Giờ mở cửa vào ngày nhận xe của bạn:
                              <br /> 05:00 - 23:59
                            </p>
                          </div>

                          <p className="text-start mt-3">
                            Điện thoại: 08324733432
                          </p>
                        </div>
                        <div>
                          <h6 className="text-start mt-3 fw-bold text-dark">
                            Trả xe
                          </h6>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "start",
                              marginTop: "10px",
                            }}
                          >
                            <p
                              style={{
                                color: "black",
                                textAlign: "left",
                                marginRight: "10px",
                              }}
                            >
                              Sân bay London Heathrow T2,3 và 4T
                              <br />
                              London
                              <br />
                              TW6 2GW
                              <br />
                              Vương quốc Anh
                            </p>
                            <p style={{ color: "black", textAlign: "left" }}>
                              Giờ mở cửa vào ngày trả xe của bạn:
                              <br /> 05:00 - 23:59
                            </p>
                          </div>

                          <p className="text-start mt-3">
                            Điện thoại: 08324733432
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "20px",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-lg"
                        style={{
                          backgroundColor: "#f0f1f2",
                          color: "black",
                          marginRight: "10px",
                        }}
                        onClick={() => this.setState({ isShowBooking: false })}
                      >
                        Quay lại
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg"
                        style={{
                          backgroundColor: "rgb(212, 0, 42)",
                          color: "white",
                        }}
                      >
                        Hủy
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p style={{ color: "#000" }}>
                      Đặt chỗ qua Budget-id.com có thể xem, chỉnh sửa hoặc hủy
                      bằng cách nhập thông tin bên dưới hoặc{" "}
                      <a
                        href="#"
                        onClick={() => <SignModal />}
                        style={{ color: "rgb(212, 0, 42)" }}
                      >
                        Đăng nhập
                      </a>{" "}
                      và truy cập trang đặt chỗ của bạn
                    </p>

                    <form onSubmit={(e) => this.handleSubmit(e)}>
                      <div className="row flex-row mt-3 pb-3">
                        <div className="col-sm-6">
                          <div className="form-group d-flex flex-column align-items-start">
                            <label htmlFor="lastName">Họ</label>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              style={{
                                backgroundColor: "#ebeff5",
                                fontSize: "20px",
                              }}
                              id="lastName"
                              onChange={(e) =>
                                this.setState({ last_name: e.target.value })
                              }
                              value={this.state.last_name}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group d-flex flex-column align-items-start">
                            <label htmlFor="reservNum">Mã đặt chỗ</label>
                            <input
                              type="text"
                              className="form-control rounded-0"
                              style={{
                                backgroundColor: "#ebeff5",
                                fontSize: "20px",
                              }}
                              id="reservNum"
                              onChange={(e) =>
                                this.setState({ bookingCode: e.target.value })
                              }
                              value={this.state.bookingCode}
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary rounded-0"
                        disabled={this.state.isLoading}
                        style={{
                          backgroundColor: "rgb(212, 0, 42)",
                          boxShadow: "none",
                        }}
                      >
                        Tìm kiếm đặt chỗ
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
            <div className="row mt-5 pb-5"></div>
          </div>
        </section>
        {/* Kết thúc Slogan One */}
      </>
    );
  }
}
