import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Title from "../Title";

const CopyrightNotices = () => {
  Title("Copyright Notices");
  return (
    <>
      <HeaderOne />
      <div className="container py-5">
        <hr />
        <h3 className="mt-3">Thông báo bản quyền</h3>
        <p className="mt-5">
          Hình ảnh ô tô, Bản quyền [2000 – 2019] izmo, Inc. Tất cả các quyền
          được bảo lưu. Các hình ảnh ô tô có trong tài liệu này là tài sản của
          izmo, Inc. và được bảo vệ theo luật bản quyền của Hoa Kỳ và quốc tế.
          Việc truy cập và sử dụng các hình ảnh này bị hạn chế bởi các điều
          khoản và điều kiện của thỏa thuận giấy phép riêng. Mọi hành vi sử dụng
          trái phép, sao chép, phân phối, ghi lại hoặc sửa đổi các hình ảnh này
          đều bị nghiêm cấm.
        </p>
      </div>

      <FooterOne />
    </>
  );
};

export default CopyrightNotices;
