import React from "react";
import { Link } from "react-router-dom";

export default class PS extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/productservice.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
      cursor: "pointer",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
      cursor: "pointer",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
      cursor: "pointer",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Sản phẩm cho thuê xe của Budget</h1>
                <p style={myLink}>
                  Tiết kiệm tiền, thời gian và sự lo lắng với các sản phẩm hữu
                  ích từ Budget.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-sm-12">
                <p style={myPara}>
                  Các ưu đãi tuyệt vời về thuê xe không phải là điều duy nhất
                  bạn sẽ nhận được từ Budget. Bạn sẽ có trải nghiệm thuê xe dễ
                  dàng và thuận tiện hơn với các dịch vụ thông minh của Budget.
                  Nhận sản phẩm và lợi ích khi bạn nhận xe, hoặc chọn chúng dễ
                  dàng khi bạn{" "}
                  <a href="#" style={myLink}>
                    đặt xe trực tuyến
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={"/van-rental"} className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Thẻ Adventurer (Chỉ Hoa Kỳ/Canada)
                    </label>
                    <label style={myParas}>
                      Khám phá các sản phẩm cho thuê xe đa dạng mà Budget cung
                      cấp để nâng cao trải nghiệm của bạn.
                    </label>
                    <label style={myLink}>Tìm hiểu thêm </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link to={"/gps-navigation"} className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Hệ thống định vị GPS
                    </label>
                    <label style={myParas}>
                      Khám phá các sản phẩm cho thuê xe đa dạng mà Budget cung
                      cấp để nâng cao trải nghiệm của bạn.
                    </label>
                    <label style={myLink}>Tìm hiểu thêm </label>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6 pe-5 pb-5">
                <Link
                  to={"/productservice/products/child-safety-seats"}
                  className="row"
                >
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      Ghế an toàn cho trẻ em
                    </label>
                    <label style={myParas}>
                      Khám phá các sản phẩm cho thuê xe đa dạng mà Budget cung
                      cấp để nâng cao trải nghiệm của bạn.
                    </label>
                    <label style={myLink}>Tìm hiểu thêm </label>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
