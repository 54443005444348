import React from "react";

const Benefit = () => {
  return (
    <div className="container pt-5 pb-5">
      <h2>ประโยชน์ของการเช่ารถหรู</h2>
      <p>
        ความหรูหรา แค่คำนี้ก็น่าดึงดูดแล้วใช่ไหม? คุณรู้ว่ามีบางสิ่งที่พิเศษ
        บางสิ่งที่ได้รับการอัพเกรด บางสิ่งที่มากกว่า
        รถหรูทุกคันในกองทัพรถเช่าของ Budget
        มอบบางสิ่งพิเศษให้กับผู้ขับขี่และผู้โดยสาร
        แต่คุณต้องนั่งหลังพวงมาลัยถึงจะรู้ว่ามันเป็นอย่างไรจริงๆ
      </p>
      <p>
        รถเช่าหรูมาพร้อมกับประโยชน์มากมายที่เห็นได้ชัด
        แต่หากคุณยังต้องการการชักจูงเพิ่มเติม
        นี่คือข้อดีบางประการที่คุณจะได้รับเมื่อคุณเลือกเช่ารถระดับไฮเอนด์จาก
        Budget:
      </p>
      <div className="listInfo d-flex flex-wrap mt-3 pt-3 ps-4 pb-3">
        <div className="me-5">รถสมรรถนะสูง</div>
        <div className="me-5">ภายในที่สะดวกสบาย</div>
        <div className="me-5">ความน่าเชื่อถือ</div>
        <div className="me-5">ประสบการณ์การขับขี่ที่เพลิดเพลิน</div>
      </div>
    </div>
  );
};

export default Benefit;
