import React from "react";

const OfferDetails = () => {
  return (
    <div className="container">
      <hr />
      <h3>Cam kết Không lo lắng của Budget</h3>
      <p className="mt-3 mb-3">
        Chúng tôi đảm bảo rằng đã thực hiện vô số biện pháp an toàn để đảm bảo
        rằng trải nghiệm này an toàn cho tất cả mọi người.
      </p>
      <b className="mt-3">Chuyến đi an toàn với Budget</b>
      <p className="mt-3 mb-3">
        Không có gì quan trọng hơn sức khỏe và sự an toàn của khách hàng và nhân
        viên của chúng tôi. Đây là một cam kết quan trọng và liên tục từ Budget.
      </p>
      <p className="mt-3 mb-3">
        Chính sách an toàn của chúng tôi bao gồm tất cả các khía cạnh của trải
        nghiệm thuê xe của bạn, từ chất lượng của các phương tiện được bảo trì
        tốt, đến bảo vệ dữ liệu của bạn và nâng cao trải nghiệm kỹ thuật số,
        đồng thời đảm bảo các địa điểm thuê xe của chúng tôi sạch sẽ và an toàn,
        và phương tiện của bạn được vệ sinh kỹ lưỡng.
      </p>
      <p className="mt-3 mb-3">
        Chúng tôi ở đây để tiếp tục sự tin tưởng mà bạn đã trao cho chúng tôi.
        Hãy yên tâm rằng tất cả nhân viên của chúng tôi đều làm việc chăm chỉ để
        cung cấp các tiêu chuẩn an toàn cao nhất.
      </p>
      <b className="mt-3">Các trạm cho thuê của chúng tôi</b>
      <ul>
        <li>
          • Chúng tôi tiếp tục thực hiện mọi bước có thể để tạo ra một môi
          trường sạch sẽ và an toàn. Điều này có nghĩa là tuân theo các khuyến
          nghị du lịch của chính phủ và đảm bảo rằng các quy trình của chúng tôi
          tuân thủ tất cả các yêu cầu pháp lý.
        </li>
        <li>
          • Quầy lễ tân và các bề mặt cứng trong các địa điểm thuê xe của chúng
          tôi vẫn được làm sạch định kỳ.
        </li>
        <li>• Nước rửa tay có sẵn tại các trạm.</li>
        <li>
          • Nhân viên của chúng tôi rửa tay thường xuyên và được đào tạo về các
          tiêu chuẩn cao về sức khỏe và an toàn, bao gồm cả các hướng dẫn quy
          định về các sản phẩm và quy trình đảm bảo an toàn cho bạn.
        </li>
      </ul>
      <b className="mt-3">Phương tiện của bạn</b>
      <ul>
        <li>
          • Mỗi khi bạn đi du lịch với Budget, chúng tôi muốn bạn biết rằng
          chúng tôi sẵn sàng phục vụ bạn và tất cả nhân viên của chúng tôi đều
          làm việc chăm chỉ để cung cấp các tiêu chuẩn an toàn cao nhất cho
          phương tiện được bảo trì tốt. Để đảm bảo an toàn cho bạn, chúng tôi
          làm sạch xe kỹ lưỡng trước mỗi lần thuê.
        </li>
        <li>
          • Phương tiện thuê của chúng tôi được kiểm tra kỹ lưỡng trước mỗi lần
          thuê: điều này bao gồm kiểm tra lốp, mức dầu và nước làm mát, đèn và
          gạt mưa.
        </li>
        <li>
          • Chúng tôi đặc biệt chú ý đến vô lăng, đèn xi nhan, bảng điều khiển,
          tay nắm, điều khiển giải trí trong xe, giá đựng cốc, bảng điều khiển
          trung tâm và tay nắm cửa bên trong và bên ngoài.
        </li>
      </ul>
    </div>
  );
};

export default OfferDetails;
