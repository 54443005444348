import React, { useState } from "react";
import ExpandCollapse from "../common/collapse/ExpandCollapse";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Title from "../Title";

const ContactUs = () => {
  Title("Contact Us");

  const breadcrumb = ["Dịch vụ chăm sóc khách hàng", "Liên hệ chúng tôi"];

  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <div className="container py-3">
        <h1 className="mb-3">Liên hệ chúng tôi</h1>
        <h2
          className="text-avis mb-3"
          style={{ fontWeight: "600", color: "black" }}
        >
          Liên hệ với chúng tôi để biết thêm thông tin
        </h2>
        <a href="mailto:sales@budget-id.com">
          <h3
            className="text-avis mb-2 email-link"
            style={{ fontWeight: "400" }}
          >
            sales@budget-id.com
          </h3>
        </a>
      </div>
      <FooterOne />
    </>
  );
};

export default ContactUs;
