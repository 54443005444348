import React from "react";

const Au = () => {
  return (
    <div className="container py-4">
      <p className="mb-2">
        Budget Car Rental là một trong những thương hiệu cho thuê xe hơi nổi
        tiếng nhất thế giới với khoảng 3.350 địa điểm tại hơn 120 quốc gia.
      </p>
      <p className="mb-2">
        Budget là công ty hàng đầu trong ngành cung cấp dịch vụ cho thuê xe cho
        những khách hàng tìm kiếm giá trị tốt và cũng điều hành doanh nghiệp cho
        thuê xe tải lớn thứ hai tại Hoa Kỳ, thông qua mạng lưới khoảng 1.650 địa
        điểm. Budget thuộc sở hữu của Avis Budget Group, Inc.
      </p>
      <p className="mb-2">
        Như tên gọi của chúng tôi, Budget luôn gắn liền với giá trị tuyệt vời
        với mức chi phí hợp lý.
      </p>
      <p className="mb-2">
        Để đảm bảo sự yên tâm cho bạn, tất cả các xe của chúng tôi đều được kiểm
        tra an toàn và làm sạch trước mỗi lần cho thuê. Và vì chúng tôi luôn đổi
        mới đội xe của mình, bạn sẽ lái những chiếc xe mới nhất với mức giá
        thấp.
      </p>
    </div>
  );
};

export default Au;
