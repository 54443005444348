import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OfferDetails from "../components/onewaycarrental/OfferDetails";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";
import Title from "../Title";

const OneWayCarRentalN = () => {
  Title("One-way car rental");
  const breadcrumb = ["Sản phẩm và Dịch vụ", "Dịch vụ", "Thuê xe một chiều"];

  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <LandingSelectCar
        image=""
        title="Thuê xe một chiều"
        sub="Sự tiện lợi và các tùy chọn tốt nhất"
      />

      <OfferDetails />
      <FooterOne />
    </>
  );
};

export default OneWayCarRentalN;
