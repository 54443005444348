import React from "react";
import { Link } from "react-router-dom";

export default class DealsOne extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myStyle = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/deals-1.webp)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    };
    const mySection = {
      backgroundColor: "rgb(247, 139, 0)",
    };
    const myIsi = {
      maxWidth: "30%",
      margin: "auto",
    };
    const myNav = {
      backgroundColor: "rgb(237, 85, 5)",
    };
    return (
      <>
        {/* Bắt đầu Slogan One */}
        <section style={mySection}>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 px-0 py-3">
                <h3 className="text-white">
                  Tiết kiệm lớn với các ưu đãi thuê xe tốt nhất
                </h3>
              </div>
            </div>
          </div>
          <div className="container pb-5">
            <div className="row">
              <div className="col-xl-12 px-0 py-3">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      className="nav-link active text-white"
                      style={myNav}
                      to={process.env.PUBLIC_URL + `/deals`}
                    >
                      Ưu đãi tại Mỹ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link text-white"
                      to={process.env.PUBLIC_URL + `/international`}
                    >
                      Quốc tế
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link text-white">Ưu đãi đối tác</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 px-0">
                <div
                  className="col-xl-4 col-lg-4 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="blog-one__single">
                    <div className="blog-one__single-img">
                      <img
                        src={publicUrl + "assets/images/blog/blog-v1-img1.jpg"}
                        alt=""
                      />
                    </div>

                    <div className="blog-one__single-content">
                      <p className="blog-one__single-content-tagline">
                        Excepteur occaecat cupidatat{" "}
                      </p>
                      {/* <h2><Link to={process.env.PUBLIC_URL + `/blog-details`}>Besting and commercial We <br /> with This Blog.</Link></h2> */}
                      {/* <div className="line"></div> */}
                      <div className="text">
                        <p>
                          Có nhiều biến thể của các đoạn văn bản Lorem Ipsum có
                          sẵn..{" "}
                        </p>
                      </div>
                      <button className="btn btn-primary w-100">
                        Tiết kiệm ngay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Kết thúc Slogan One */}
      </>
    );
  }
}
