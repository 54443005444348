import React from "react";
import Card from "../../common/card/Card";

const ContentAbout = () => {
  return (
    <div className="carsContainer">
      <div className="container py-5 px-4">
        <div className="row py-4">
          <Card
            title="Thông Tin Công Ty"
            description="Budget kết hợp sức mạnh của một trong những thương hiệu cho thuê xe lớn nhất thế giới với kiến thức địa phương chi tiết, sự linh hoạt và giá trị tuyệt vời cho đồng tiền."
            link="/about-budget/company-information"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentAbout;
