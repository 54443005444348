import React from "react";

const OfferDetails = () => {
  return (
    <div className="container">
      <hr />
      <h3>Thuê xe một chiều từ một địa điểm và trả xe ở địa điểm khác!</h3>
      <p className="mt-3 mb-3">
        Đang lên kế hoạch cho chuyến đi đường bộ lớn nhất trong đời bạn? Hoặc có
        thể bạn chỉ cần nhận xe từ sân bay và trả xe gần khách sạn của bạn? Dù
        lý do của bạn là gì, tại nhiều địa điểm của Budget, bạn có thể thuê xe
        từ một chi nhánh và trả tại chi nhánh khác (thuê xe một chiều), giúp bạn
        linh hoạt lên kế hoạch cho kỳ nghỉ hoặc chuyến đi công tác phù hợp với
        nhu cầu của mình.
      </p>
      <b className="mt-3">Thuê xe một chiều là gì?</b>
      <p className="mt-3 mb-3">
        Thuê xe một chiều từ Budget cho phép bạn nhận xe tại một địa điểm và trả
        xe tại một địa điểm khác, rất tiện lợi cho những ai không quay lại khu
        vực ban đầu nhưng muốn di chuyển đến một nơi khác.
      </p>
      <b className="mt-3">Làm thế nào để đặt thuê xe một chiều?</b>
      <p className="mt-3 mb-3">
        Để bắt đầu thuê xe một chiều, hãy điền thông tin địa điểm nhận xe, ngày
        và giờ thuê vào trang đặt chỗ, sau đó đánh dấu vào ô "Trả xe tại địa
        điểm khác" và điền thông tin địa điểm trả xe để nhận báo giá.
      </p>
      <p className="mt-3 mb-3">
        Thuê xe một chiều quốc tế đôi khi có thể được cung cấp giữa các quốc
        gia. Để biết thêm thông tin, vui lòng gọi cho nhóm đặt chỗ của chúng tôi
        để được hỗ trợ.
      </p>
      <b className="mt-3">Chi phí thuê xe một chiều là bao nhiêu?</b>
      <p className="mt-3 mb-3">
        Thuê xe một chiều có sẵn tại hầu hết các địa điểm của Budget, nhưng có
        thể có thêm phí tùy thuộc vào địa điểm nhận và trả xe mà bạn chọn. Phí
        sẽ khác nhau giữa các quốc gia.
      </p>
      <p className="mt-3 mb-3">
        Giá được cung cấp trực tuyến sẽ tự động bao gồm cả chi phí thuê xe và
        phí thuê xe một chiều (nếu có). Thuê xe nhận và trả trong cùng một thành
        phố thường sẽ không phải chịu phí thuê xe một chiều, nhưng vui lòng kiểm
        tra khi đặt chỗ.
      </p>
      <b className="mt-3">
        Những địa điểm nào cung cấp dịch vụ thuê xe một chiều?
      </b>
      <ol className="mb-3">
        <li>
          • Thuê xe một chiều phổ biến nhất giữa các chi nhánh trong cùng một
          quốc gia. Tại Châu Âu lục địa, thuê xe một chiều có sẵn giữa một số
          quốc gia. Lưu ý rằng bạn có thể nhận xe từ Vương quốc Anh để lái sang
          Châu Âu, nhưng không được phép thuê xe một chiều rời khỏi Vương quốc
          Anh.
        </li>
        <li>
          • Thuê xe một chiều ở Châu Mỹ <br />
          Do đại dịch vi-rút corona, vui lòng kiểm tra các khuyến nghị du lịch
          của Mexico hoặc Canada để đảm bảo rằng việc di chuyển quốc tế được cho
          phép. <br />
          Các quy tắc thuê xe một chiều của Budget từ Hoa Kỳ đến các quốc gia
          khác như sau:
          <ol>
            <li>
              + Không thể thuê xe một chiều để lái vào Mexico, nhưng bạn có thể
              lái xe thuê từ Hoa Kỳ vào Mexico nếu trả xe tại Hoa Kỳ—kiểm tra
              các điều kiện thuê xe từ Hoa Kỳ đến Mexico.
            </li>
            <li>
              + Một số địa điểm của Budget cho phép thuê xe một chiều để đi vào
              Canada. Vui lòng kiểm tra các điều khoản và điều kiện tại địa điểm
              thuê của bạn.
            </li>
            <li>
              + Thuê xe một chiều đến Canada phụ thuộc vào tình trạng sẵn có và
              phải yêu cầu trước.
            </li>
          </ol>
        </li>
      </ol>
      <b className="mt-3">
        Yêu cầu thay đổi địa điểm trả xe sau khi đã nhận xe
      </b>
      <p className="mt-3 mb-3">
        Budget hiểu rằng kế hoạch của bạn có thể thay đổi sau khi bắt đầu thuê
        xe. Nếu bạn muốn nhận xe từ một địa điểm và trả xe tại một địa điểm
        khác, bạn cần liên hệ với địa điểm của Budget trước để kiểm tra bất kỳ
        giới hạn nào có thể áp dụng. Nhân viên tại địa điểm đó sẽ thông báo cho
        bạn nếu có bất kỳ hạn chế nào về việc trả xe.
      </p>
      <p className="mt-3 mb-5">
        Lưu ý rằng bạn phải liên hệ với chúng tôi trước khi trả xe tại địa điểm
        khác. Nếu không liên hệ trước, bạn sẽ bị tính phí trả xe không được phép
        và có thể bị tính mức giá cao hơn dựa trên khoảng cách giữa các địa điểm
        nhận và trả xe được chỉ định trong đặt chỗ của bạn.
      </p>
    </div>
  );
};

export default OfferDetails;
