import React from "react";
import { Link } from "react-router-dom";

export default class DP extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    const myParas = {
      lineHeight: "1.5",
      height: "150px",
      fontSize: "15px",
      color: "#000",
    };

    return (
      <>
        <section className="border-top border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 pt-2">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link>Sản phẩm và Dịch vụ</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link>Bảo vệ và Bảo hiểm</Link>
                    </li>
                    <li className="breadcrumb-item myLink" aria-current="page">
                      {this.props.data}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container py-5">
            <div className="row">
              {this.props.data === "Loss Damage Waiver (LDW)" ? (
                <div className="col-sm-12">
                  <h1>{this.props.data}</h1>
                  {/* LDW */}
                  <p className="mb-4">
                    Đây là tùy chọn giúp bạn không phải chịu trách nhiệm tài
                    chính nếu xe thuê của bạn bị hư hỏng trong thời gian thuê.
                    Tuy nhiên, bạn sẽ cần trả khoản khấu trừ. Ở một số quốc gia,
                    điều này được gọi là miễn trừ thiệt hại do va chạm (CDW).
                  </p>
                  <b>Bảo hiểm bao gồm những gì?</b>
                  <p className="mb-4">
                    Bạn sẽ được bảo hiểm cho các chi phí sửa chữa xe của Budget
                    nếu bị hư hỏng. Lưu ý rằng bạn sẽ phải trả một khoản cố định
                    cho các chi phí sửa chữa, gọi là khoản khấu trừ. Khoản này
                    sẽ được áp dụng dù bạn có lỗi hay không. Nếu chúng tôi có
                    thể thu hồi các chi phí này từ bên thứ ba chịu trách nhiệm,
                    chúng tôi sẽ hoàn trả cho bạn.
                  </p>
                  <b>Những gì không được bảo hiểm?</b>
                  <ul>
                    <li>
                      - Thiệt hại do hành vi cố ý hoặc sơ suất nghiêm trọng.
                    </li>
                    <li>- Thiệt hại do không sử dụng đúng loại nhiên liệu.</li>
                    <li>- Thiệt hại phát sinh do vi phạm hợp đồng thuê xe.</li>
                    <li>- Thiệt hại đối với kính chắn gió.</li>
                    <li>- Thiệt hại đối với lốp xe.</li>
                  </ul>
                  <p className="mb-4">
                    Miễn trừ thiệt hại nâng cao (SCDW) là sản phẩm giảm khoản
                    khấu trừ xuống còn 0, ngoại trừ xe van trong trường hợp xảy
                    ra tai nạn với xe thuê. Giá cả khác nhau tùy theo loại xe và
                    địa điểm, và có thể mua tại quầy với các điều kiện và điều
                    khoản áp dụng.
                  </p>
                  <b>Đối với thuê xe tại Hoa Kỳ và Canada</b>
                  <p className="mb-4">
                    Nếu bạn chấp nhận LDW đầy đủ bằng cách ký vào tài liệu thuê
                    xe với mức phí hàng ngày bổ sung, mỗi ngày bạn thuê xe hoàn
                    chỉnh hoặc một phần và xe tuân thủ các thỏa thuận, chúng tôi
                    sẽ chịu trách nhiệm về việc mất mát hoặc hư hỏng xe, ngoại
                    trừ nếu pháp luật cho phép, đối với chìa khóa bị mất, hỏng
                    hoặc bị đánh cắp, hoặc thiết bị điều khiển từ xa, dịch vụ
                    kéo xe hoặc dịch vụ lốp xe, trừ khi liên quan đến tai nạn,
                    hoặc khôi phục xe nếu bị đánh cắp (trừ bang Alaska) và trừ
                    số "trách nhiệm" của bạn nếu có, được nêu trong tài liệu
                    thuê xe.
                  </p>
                  <p className="mb-4">
                    Nếu bạn không chấp nhận LDW, bạn sẽ phải trả tiền cho tất cả
                    các tổn thất hoặc thiệt hại đối với xe. Tổn thất và thiệt
                    hại được mô tả trong tài liệu thuê xe. Bạn thừa nhận rằng
                    bạn đã được thông báo rằng bảo hiểm của bạn có thể bao gồm
                    tổn thất hoặc thiệt hại đối với xe. Bạn cũng thừa nhận đã
                    đọc thông báo về tổn thất hoặc thiệt hại được hiển thị trong
                    tài liệu thuê xe hoặc trong các điều khoản và điều kiện này
                    hoặc trong một mẫu thông báo riêng biệt.
                  </p>
                  <p className="mb-4">
                    Tại Canada, nếu bạn chọn LDW và xe thuê của bạn bị hư hỏng
                    hoặc mất mát, bạn có thể phải chịu trách nhiệm cho khoản
                    khấu trừ cho tổn thất hoặc thiệt hại. Số tiền này khác nhau
                    tùy theo loại xe bạn thuê và sẽ được nêu trong hợp đồng thuê
                    xe của bạn. Hiện tại, số tiền này dao động từ 300 CAD đến
                    5.000 CAD.
                  </p>
                  <p className="mb-4">
                    Để biết thêm chi tiết về LDW, vui lòng xem các điều khoản và
                    điều kiện tại địa điểm thuê xe.
                  </p>
                  {/* LDW */}
                </div>
              ) : this.props.data === "Theft Protection (TP)" ? (
                <div className="col-sm-12">
                  <h1>{this.props.data}</h1>

                  {/* TP */}
                  <p className="mb-4">
                    Nếu xe thuê của bạn bị đánh cắp, bảo vệ này sẽ bao gồm chi
                    phí thay thế xe (hoặc các bộ phận của nó). Tuy nhiên, bạn sẽ
                    phải trả khoản khấu trừ.
                  </p>
                  <b>Bảo hiểm bao gồm những gì?</b>
                  <p className="mb-4">
                    Bạn sẽ được bảo hiểm cho chi phí thay thế xe của Budget
                    (hoặc các bộ phận của nó) nếu nó bị đánh cắp. Lưu ý rằng bạn
                    sẽ phải trả một khoản cố định cho chi phí thay thế, gọi là
                    khoản khấu trừ.
                  </p>
                  <b>Những gì không được bảo hiểm?</b>
                  <p className="mb-4">
                    Sơ suất liên quan đến việc để đồ trong xe (chẳng hạn như để
                    xe không có người trông coi) hoặc không bảo quản chìa khóa
                    an toàn (chẳng hạn như để chìa khóa trong ổ khóa).
                  </p>
                  <p className="mb-4">
                    * Khoản khấu trừ khác nhau tùy theo quốc gia và nên được
                    kiểm tra khi đặt chỗ. Ngoài ra, liên hệ với trung tâm dịch
                    vụ khách hàng của Budget hoặc chi nhánh địa phương để biết
                    thêm chi tiết.
                  </p>
                  <p className="mb-4">
                    Bảo vệ này làm giảm trách nhiệm từ toàn bộ chi phí xe xuống
                    khoản khấu trừ tiêu chuẩn. Khoản khấu trừ khác nhau tùy theo
                    quốc gia và nên được kiểm tra khi đặt chỗ. Ngoài ra, liên hệ
                    với đội đặt chỗ của chúng tôi hoặc địa điểm thuê để biết
                    thêm chi tiết. Bạn có thể xem thêm thông tin trong các điều
                    khoản và điều kiện tại địa điểm thuê xe.
                  </p>
                  <p className="mb-4">
                    Nếu bạn muốn sản phẩm giảm khoản khấu trừ hoặc khấu trừ bằng
                    0, chúng có sẵn tại quầy thuê xe ở một số địa điểm. Vui lòng
                    kiểm tra với nhân viên cho thuê xe của chúng tôi tại quầy
                    khi nhận xe của bạn.
                  </p>
                  {/* TP */}
                </div>
              ) : this.props.data === "Personal Accident and Effects (PAE)" ? (
                <div className="col-sm-12">
                  <h1>{this.props.data}</h1>

                  {/* PAE */}
                  <b>Đối với thuê xe tại Hoa Kỳ</b>
                  <p className="mb-4">
                    Bạn sẽ phải trả phí cho{" "}
                    <b>Bảo hiểm tai nạn và tài sản cá nhân (PAE)</b> nếu bạn
                    chọn sử dụng. Bạn hiểu rằng sẽ có mức phí hàng ngày ngay cả
                    khi bạn không có xe cả ngày.
                  </p>
                  <b>
                    <u>
                      PAE – Bảo hiểm tai nạn và tài sản cá nhân (khi có sẵn)
                    </u>
                  </b>
                  <p className="mb-4">
                    PAE cung cấp bảo hiểm y tế, điều trị tại bệnh viện, tử vong,
                    và mất bộ phận cơ thể do chấn thương từ tai nạn, và cung cấp
                    bảo hiểm cho tổn thất hoặc hư hỏng tài sản cá nhân nhất định
                    khi ở trong xe thuê. Bảo hiểm này có thời hạn trong thời
                    gian thuê và không quá 30 ngày liên tục.
                  </p>
                  <p className="mb-4">
                    PAE cung cấp các lợi ích tối đa sau đây cho chấn thương do
                    tai nạn:
                  </p>
                  <ul className="mb-4">
                    <li>
                      • Tử vong và mất bộ phận cơ thể lên tới $175,000* (người
                      thuê) và $25,000** (hành khách)
                    </li>
                    <li>• Chi phí y tế do tai nạn lên tới $10,000</li>
                    <li>
                      • Lợi ích điều trị tại bệnh viện $500 mỗi ngày, tối đa 30
                      ngày
                    </li>
                    <li>
                      • Bảo hiểm tài sản cá nhân cho mỗi mục là $1,000; tổng tối
                      đa cho tất cả các mục được bảo hiểm trong xe thuê là
                      $3,000****
                    </li>
                  </ul>
                  <p className="mb-4">
                    *$200,000 ở New York; $50,000 ở New Hampshire
                  </p>
                  <p className="mb-4">
                    **$200,000 ở New York; $175,000 ở Connecticut, Indiana,
                    Kansas, Missouri, Montana, Vermont, Virginia, và Washington;
                    $50,000 ở New Hampshire
                  </p>
                  <p className="mb-4">
                    ***Lợi ích điều trị tại bệnh viện không có ở New York
                  </p>
                  <p className="mb-4">****$2,000 ở New York</p>
                  <b>
                    <u>
                      PAE Plus – Bảo hiểm tai nạn và tài sản cá nhân PLUS (khi
                      có sẵn)
                    </u>
                  </b>
                  <p className="mb-4">
                    PAE cung cấp bảo hiểm y tế, điều trị tại bệnh viện, tử vong,
                    và mất bộ phận cơ thể do chấn thương từ tai nạn, và cung cấp
                    bảo hiểm cho tổn thất hoặc hư hỏng tài sản cá nhân nhất định
                    khi ở trong xe thuê. Bảo hiểm này có thời hạn trong thời
                    gian thuê và không quá 30 ngày liên tục.
                  </p>
                  <p className="mb-4">
                    PAE Plus cung cấp các lợi ích tối đa sau đây cho chấn thương
                    do tai nạn:
                  </p>
                  <ul className="mb-4">
                    <li>
                      • Tử vong và mất bộ phận cơ thể lên tới $250,000* (người
                      thuê) và $125,000** (hành khách) đối với xe có sức chứa
                      tối đa 15 người.
                    </li>
                    <li>• Chi phí y tế do tai nạn lên tới $15,000</li>
                    <li>
                      • Lợi ích điều trị tại bệnh viện $500 mỗi ngày, tối đa 30
                      ngày
                    </li>
                    <li>
                      • Bảo hiểm tài sản cá nhân cho mỗi mục là $1,000; tổng tối
                      đa cho tất cả các mục được bảo hiểm trong xe thuê là
                      $3,000
                    </li>
                  </ul>
                  <p className="mb-4">*$50,000 ở New Hampshire</p>
                  <p className="mb-4">
                    **$250,000 ở New York; $250,000 ở Connecticut, Indiana,
                    Kansas, Missouri, Montana, Vermont, Virginia, và Washington;
                    $50,000 ở New Hampshire
                  </p>
                  <p className="mb-4">
                    ***Lợi ích điều trị tại bệnh viện không có ở New York
                  </p>
                  <p className="mb-4">****$2,000 ở New York</p>

                  <p className="mb-4">
                    Để xem toàn bộ tập sách, vui lòng{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-us-protections-coverages-brochure-062321.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      nhấp vào đây
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    Để xem chứng nhận chính sách bảo hiểm tai nạn cá nhân cho
                    các bang chung, vui lòng{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pai-cert-general-states.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      nhấp vào đây
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    Để xem chứng nhận chính sách bảo hiểm tai nạn cá nhân cho
                    New York, vui lòng{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pai-cert-ny.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      nhấp vào đây
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    Để xem chứng nhận chính sách bảo hiểm tai nạn cá nhân cho
                    Connecticut, Indiana, Kansas, Missouri, Montana, Vermont,
                    Virginia, và Washington, vui lòng{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pai-cert-CT-IN-KS-MO-MT-VT-VA-WA.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      nhấp vào đây
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    Để xem chứng nhận chính sách tài sản cá nhân cho các bang
                    chung, vui lòng{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pep-cert-general-states.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      nhấp vào đây
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    Để xem chứng nhận chính sách tài sản cá nhân cho New York,
                    vui lòng{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pep-cert-ny.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      nhấp vào đây
                    </a>
                    .
                  </p>
                  <p className="mb-4">
                    Để xem chứng nhận chính sách tài sản cá nhân cho
                    Connecticut, Indiana, Kansas, Missouri, Montana, Vermont,
                    Virginia, và Washington, vui lòng{" "}
                    <a
                      href="https://www.budget.com/content/dam/budget/na/us/common/PDF-Files/budget-pep-cert-CT-IN-KS-MO-MT-VT-VA-WA.pdf"
                      target="_blank"
                      style={myLink}
                    >
                      nhấp vào đây
                    </a>
                    .
                  </p>
                  {/* PAE */}
                </div>
              ) : (
                <div className="col-sm-12">
                  <h1>{this.props.data}</h1>
                  {/* SLI */}
                  <b>Đối với thuê xe tại Hoa Kỳ và Canada</b>
                  <p className="mb-4">
                    Nếu bạn chọn mua SLI, bảo hiểm sẽ được cung cấp từ hợp đồng
                    bảo hiểm ô tô bổ sung được phát hành cho Budget. SLI cung
                    cấp bảo hiểm cho các yêu cầu bồi thường từ bên thứ ba đối
                    với sự khác biệt giữa giới hạn trách nhiệm tài chính tối
                    thiểu và giới hạn trách nhiệm tối đa kết hợp là $1,000,000
                    hoặc $2,000,000, tùy thuộc vào khu vực thuê xe và loại xe,
                    đối với thương tích, tử vong, hoặc thiệt hại tài sản từ mỗi
                    tai nạn. Bảo hiểm này được cung cấp theo chính sách bảo hiểm
                    trách nhiệm bổ sung được mô tả trong tài liệu và tuân thủ
                    tất cả các điều kiện.
                    <b>
                      SLI không áp dụng cho trách nhiệm đối với thương tích cá
                      nhân hoặc thiệt hại tài sản gây ra bởi "việc sử dụng xe
                      không được phép" như mô tả trong điều khoản 15 của thỏa
                      thuận và các loại trừ khác của SLI được liệt kê trong
                      chính sách SLI
                    </b>
                    . Bạn hiểu rằng bạn sẽ bị tính phí hàng ngày cho toàn bộ
                    ngày, ngay cả khi bạn không sử dụng xe suốt cả ngày.
                    <b>
                      Thông báo về bảo hiểm trách nhiệm bổ sung tại bang Florida
                    </b>
                    .
                  </p>
                  <b>Bảo hiểm trách nhiệm bổ sung (SLI) là gì?</b>
                  <p className="mb-4">
                    <b>Budget</b> cung cấp bảo hiểm trách nhiệm bổ sung (SLI)
                    tại hầu hết các địa điểm tại Hoa Kỳ. SLI là một dịch vụ bổ
                    sung do Budget cung cấp khi bạn thuê xe từ Budget. Đây là
                    "hợp đồng bảo hiểm trách nhiệm bổ sung" cung cấp bảo hiểm bổ
                    sung trong giới hạn được chỉ định, vượt quá giới hạn được
                    quy định trong thỏa thuận. SLI bảo hiểm cho bạn và những
                    người lái xe được phép được chỉ định trong thỏa thuận khỏi
                    các yêu cầu bồi thường từ bên thứ ba đối với thương tích/tử
                    vong và thiệt hại tài sản do việc sử dụng hoặc điều khiển xe
                    thuê từ Budget. SLI là hợp đồng bảo hiểm riêng biệt được
                    phát hành cho Budget bởi Công ty Bảo hiểm ACE American.
                  </p>
                  <p className="mb-4">
                    Nếu bạn chọn nhận SLI, bạn sẽ phải trả phí bổ sung như được
                    hiển thị trong thỏa thuận.{" "}
                    <b>Việc mua SLI không bắt buộc khi thuê xe từ Budget</b>.
                  </p>
                  <b>SLI cung cấp giới hạn bảo hiểm nào?</b>
                  <p className="mb-4">
                    Giới hạn bảo hiểm của SLI là sự khác biệt giữa giới hạn
                    trách nhiệm tối đa của SLI $1,000,000 (hoặc $2,000,000 tại
                    các khu vực sau: California, Florida, Hawaii và New York) và
                    giới hạn bảo hiểm trong thỏa thuận thuê xe của Budget.{" "}
                    <b>
                      Lưu ý rằng tại tất cả các bang, chúng tôi cung cấp giới
                      hạn trách nhiệm kết hợp là $1,000,000 chỉ dành cho xe van
                      hành khách có 10, 12, và 15 chỗ ngồi
                    </b>
                    .
                  </p>
                  <b>SLI áp dụng khi nào và ở đâu?</b>
                  <p className="mb-4">
                    Bạn và người lái xe được phép sẽ được bảo hiểm khi lái xe
                    thuê trong Hoa Kỳ và Canada, nhưng chỉ khi xe được thuê và
                    trả lại tại Hoa Kỳ. Bảo hiểm này không áp dụng tại Mexico.
                  </p>
                  <b>Làm thế nào để báo cáo yêu cầu bồi thường?</b>
                  <p className="mb-4">
                    Nếu bạn gặp tai nạn, bạn phải hoàn thành báo cáo tai nạn và
                    nộp nó tại địa điểm thuê xe của Budget. Để báo cáo yêu cầu
                    bồi thường, vui lòng gửi bằng văn bản đến:
                  </p>
                  <p className="mb-4">Budget Rent A Car System, Inc.</p>
                  <p className="mb-4">Attn: Claims</p>
                  <p className="mb-4">P.O. Box 61247</p>
                  <p className="mb-4">Virginia Beach, VA 23466</p>
                  <p className="mb-4">1-866-446-8376</p>
                  <b>
                    SLI ảnh hưởng đến bảo hiểm xe cá nhân hoặc hợp đồng bảo hiểm
                    bổ sung của bạn như thế nào?
                  </b>
                  <p className="mb-4">
                    Hợp đồng bảo hiểm cá nhân của bạn, bảo hiểm xe mà bạn sở
                    hữu, hoặc hợp đồng bảo hiểm cá nhân khác có thể cung cấp bảo
                    hiểm bổ sung, và trong phạm vi đó, SLI có thể tạo ra sự
                    trùng lặp bảo hiểm. Thời điểm và mức độ mà hợp đồng bảo hiểm
                    của bạn có hiệu lực có thể được xác định bằng cách xem xét
                    các điều khoản của hợp đồng. Tuy nhiên, bảo hiểm do SLI cung
                    cấp (nếu được chấp nhận) sẽ áp dụng trước hợp đồng của bạn,
                    nghĩa là giới hạn bảo hiểm $1,000,000 (hoặc $2,000,000 tại
                    các khu vực sau: California, Florida, Hawaii và New York)
                    phải được sử dụng hết trước khi hợp đồng của bạn có hiệu lực
                    chi trả cho yêu cầu bồi thường.
                  </p>
                  <b>
                    Lưu ý rằng tại tất cả các bang, chúng tôi cung cấp giới hạn
                    trách nhiệm kết hợp là $1,000,000 chỉ dành cho xe van hành
                    khách có 10, 12, và 15 chỗ ngồi
                  </b>
                  .<p className="mb-4"></p>
                  <b>Các loại trừ nào áp dụng cho SLI?</b>
                  <p className="mb-4">
                    Dưới đây là một số điểm nổi bật của các loại trừ khiến SLI
                    không thể áp dụng. Điều quan trọng là đọc kỹ hợp đồng thuê
                    xe và chính sách bảo hiểm để biết tất cả các loại trừ.
                  </p>
                  <ul className="mb-4">
                    <li>
                      • Sử dụng xe trái phép như được mô tả trong điều khoản 15
                      của thỏa thuận
                    </li>
                    <li>
                      • Thương tích cá nhân hoặc thiệt hại tài sản đối với người
                      được bảo hiểm, hoặc thương tích cá nhân hoặc thiệt hại tài
                      sản đối với bất kỳ ai liên quan đến người được bảo hiểm
                      theo luật pháp tại bang nơi hợp đồng thuê xe được ký tại
                      thời điểm thuê xe
                    </li>
                    <li>
                      • Bảo hiểm "người lái xe không bảo hiểm" / "người lái xe
                      không đủ bảo hiểm" không được bảo hiểm trong chính sách,
                      trừ các bang bắt buộc theo luật pháp, với giới hạn không
                      quá $100,000 ($1MM tại FL và NH)
                    </li>
                    <li>
                      • "No Fault" và các bảo hiểm bổ sung khác không được bảo
                      hiểm trong chính sách
                    </li>
                    <li>
                      • Thiệt hại mang tính trừng phạt hoặc gương mẫu theo phạm
                      vi mà luật pháp cho phép
                    </li>
                  </ul>
                  <b>Có hạn chế đặc biệt nào khi mua SLI không?</b>
                  <p className="mb-4">
                    Tại Florida, không thể mua SLI nếu thời hạn của hợp đồng
                    thuê xe vượt quá 30 ngày. Bảo hiểm không thể vượt quá 30
                    ngày liên tục, và nếu vượt quá 30 ngày, chỉ có thể gia hạn
                    một lần và không quá 30 ngày.
                  </p>
                  <p className="mb-4">
                    Để biết thêm thông tin, gọi Budget miễn phí: 1-800-527-7000
                  </p>
                  <p className="mb-4">
                    Tham quan Budget trực tuyến tại Budget.com
                  </p>
                  <p className="mb-4">
                    Đây chỉ là bản tóm tắt của SLI. Điều kiện cụ thể, điều
                    khoản, và các loại trừ đầy đủ sẽ được quy định trong hợp
                    đồng thuê xe và chính sách SLI do ACE American Insurance
                    Company phát hành. Một trong những công ty con của ACE
                    Limited (NYSE: ACE). "ACE" và logo ACE là nhãn hiệu dịch vụ
                    của ACE Group, bao gồm ACE Limited và các công ty con.
                  </p>
                  <p className="mb-4">
                    Tóm tắt này không nhằm mục đích cung cấp mô tả đầy đủ về
                    điều kiện, điều khoản, và các loại trừ của chính sách. Để
                    biết thêm chi tiết, chúng tôi mời bạn xem lại bản sao chính
                    sách, có sẵn để kiểm tra theo yêu cầu tại địa điểm thuê xe
                    của Budget.
                  </p>
                  <p className="mb-4">
                    Nhân viên Budget, đại lý, hoặc nhà bảo lãnh không đủ điều
                    kiện để đánh giá mức độ đầy đủ của bảo hiểm có sẵn của người
                    thuê.
                  </p>
                  {/* SLI */}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-sm-12 mt-2">
                <Link
                  to={`${process.env.PUBLIC_URL}/makereservation`}
                  className="btnCar"
                >
                  Đặt chỗ
                </Link>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
