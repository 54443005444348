import React from "react";

export default class PS extends React.Component {
  render() {
    const myStyle = {
      height: "120px",
    };
    return (
      <>
        <section style={myStyle}>
          <div className="container pt-5">
            <div className="row">
              <div className="col-sm-12">
                <h1>Chương trình cho thuê xe của Budget</h1>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Qatar Airlines</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Thành viên Burgundy có thể nhận được 2 Avios cơ bản cho mỗi
                  USD chi tiêu. Thành viên Silver có thể nhận được 2 Avios cơ
                  bản và 1 Avios thưởng cho mỗi USD chi tiêu. Thành viên Gold có
                  thể nhận được 2 Avios cơ bản và 2 Avios thưởng cho mỗi USD chi
                  tiêu.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Thành viên Platinum có thể nhận được 2 Avios cơ bản và 3 Avios
                  thưởng cho mỗi USD chi tiêu.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Thành viên chương trình nhân viên có thể nhận được chiết khấu
                  10% cho thuê xe đủ điều kiện.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Vui lòng cung cấp số thành viên của bạn trong quá trình đặt xe
                  và xuất trình thẻ thành viên của bạn tại quầy khi nhận xe để
                  nhận dặm bay.
                </p>
              </div>
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Singapore Airlines</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Tất cả các thành viên có thể nhận 500 dặm cho mỗi lượt thuê đủ
                  điều kiện với mức giá bán lẻ giải trí.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Vui lòng cung cấp số thành viên của bạn trong quá trình đặt xe
                  và xuất trình thẻ thành viên của bạn tại quầy khi nhận xe để
                  nhận dặm bay.
                </p>
              </div>
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Turkish Airlines</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Tất cả các thành viên có thể nhận 500 dặm cho mỗi lượt thuê đủ
                  điều kiện. Thành viên chương trình nhân viên có thể nhận chiết
                  khấu 10% cho mỗi lượt thuê đủ điều kiện.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Vui lòng cung cấp số thành viên của bạn trong quá trình đặt xe
                  và xuất trình thẻ thành viên của bạn tại quầy khi nhận xe để
                  nhận dặm bay.
                </p>
              </div>
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Master Card</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Thành viên World Elite, World, Platinum, Gold, Titanium,
                  Business có thể nhận được chiết khấu 10%.
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Vui lòng cung cấp số thành viên của bạn trong quá trình đặt xe
                  và xuất trình thẻ thành viên của bạn tại quầy khi nhận xe để
                  nhận dặm bay.
                </p>
              </div>
              <div className="col-12 mb-3">
                <h4 style={{ color: "rgb(237, 85, 5)" }}>Visa</h4>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Thành viên Infinite, Infinite Privilege, Signature, Platinum
                  và Gold có thể nhận được chiết khấu 10% trên toàn cầu (dành
                  cho cư dân khu vực EAMEA - Châu Âu, Trung Đông, Châu Phi và
                  Châu Á).
                </p>
                <p
                  style={{
                    lineHeight: 1.5,
                    fontSize: "15px",
                    color: "rgb(0, 0, 0)",
                  }}
                >
                  Vui lòng cung cấp số thành viên của bạn trong quá trình đặt xe
                  và xuất trình thẻ thành viên của bạn tại quầy khi nhận xe để
                  nhận dặm bay.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
