import React from "react";
import { Link } from "react-router-dom";

export default class CC extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myBg = {
      backgroundColor: "#eaeaea",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myHead = {
      fontSize: "18px",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
      color: "#000",
      height: "175px",
    };
    const myCard = {
      backgroundColor: "#fff",
    };
    return (
      <>
        {/* เริ่ม Slogan หนึ่ง */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xl-12 py-5">
                <h2 className="">ฝ่ายดูแลลูกค้า</h2>
                <p className="mt-2" style={myLink}>
                  มีคำถามหรือไม่? คุณมาถูกที่แล้ว!
                </p>
              </div>
            </div>
          </div>
        </section>
        <section style={myBg} className="py-5">
          <div className="container">
            <div className="row">
              <a href="#" className="col-lg-4 col-sm-6 pe-5 pb-5">
                <div className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      การฉ้อโกงและกลโกง
                    </label>
                    <label style={myPara}>
                      ปกป้องตัวเองจากการตกเป็นเหยื่อของการฉ้อโกงและกลโกงการเช่ารถ
                      คลิกที่ลิงก์ด้านล่างเพื่อดูสัญญาณทั่วไปของผู้ที่อาจพยายามโกงทั้งคุณและ
                      Budget
                    </label>
                    <label style={myLink}>เรียนรู้เพิ่มเติม </label>
                  </div>
                </div>
              </a>
              <a href="#" className="col-lg-4 col-sm-6 pe-5 pb-5">
                <div className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      การฉ้อโกงและกลโกง
                    </label>
                    <label style={myPara}>
                      ปกป้องตัวเองจากการตกเป็นเหยื่อของการฉ้อโกงและกลโกงการเช่ารถ
                      คลิกที่ลิงก์ด้านล่างเพื่อดูสัญญาณทั่วไปของผู้ที่อาจพยายามโกงทั้งคุณและ
                      Budget
                    </label>
                    <label style={myLink}>เรียนรู้เพิ่มเติม </label>
                  </div>
                </div>
              </a>
              <a href="#" className="col-lg-4 col-sm-6 pe-5 pb-5">
                <div className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      การฉ้อโกงและกลโกง
                    </label>
                    <label style={myPara}>
                      ปกป้องตัวเองจากการตกเป็นเหยื่อของการฉ้อโกงและกลโกงการเช่ารถ
                      คลิกที่ลิงก์ด้านล่างเพื่อดูสัญญาณทั่วไปของผู้ที่อาจพยายามโกงทั้งคุณและ
                      Budget
                    </label>
                    <label style={myLink}>เรียนรู้เพิ่มเติม </label>
                  </div>
                </div>
              </a>
              <a href="#" className="col-lg-4 col-sm-6 pe-5 pb-5">
                <div className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      การฉ้อโกงและกลโกง
                    </label>
                    <label style={myPara}>
                      ปกป้องตัวเองจากการตกเป็นเหยื่อของการฉ้อโกงและกลโกงการเช่ารถ
                      คลิกที่ลิงก์ด้านล่างเพื่อดูสัญญาณทั่วไปของผู้ที่อาจพยายามโกงทั้งคุณและ
                      Budget
                    </label>
                    <label style={myLink}>เรียนรู้เพิ่มเติม </label>
                  </div>
                </div>
              </a>
              <a href="#" className="col-lg-4 col-sm-6 pe-5 pb-5">
                <div className="row">
                  <div className="col-12 p-3" style={myCard}>
                    <label className="mb-2" style={myHead}>
                      การฉ้อโกงและกลโกง
                    </label>
                    <label style={myPara}>
                      ปกป้องตัวเองจากการตกเป็นเหยื่อของการฉ้อโกงและกลโกงการเช่ารถ
                      คลิกที่ลิงก์ด้านล่างเพื่อดูสัญญาณทั่วไปของผู้ที่อาจพยายามโกงทั้งคุณและ
                      Budget
                    </label>
                    <label style={myLink}>เรียนรู้เพิ่มเติม </label>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>
        {/* สิ้นสุด Slogan หนึ่ง */}
      </>
    );
  }
}
