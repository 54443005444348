import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import axios from "axios";
import HeaderAlt from "../common/header/HeaderAlt";
import { generateRandomNumbers } from "../core/functions";
import { API_URL, BRAND } from "../core/constant.js";
import { Link } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";
import { FaCheck } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { format } from "date-fns";

const ConfirmBookings = () => {
  const [header, setHeader] = useState([]);
  const locate = useLocation();
  const queryParams = new URLSearchParams(locate.search);

  const orderId = queryParams.get("order_id");

  const make = localStorage.getItem("make");
  const name = localStorage.getItem("name");
  const image = localStorage.getItem("image");
  const model = localStorage.getItem("model");
  const transmission = localStorage.getItem("transmission");
  // const extraData = JSON.parse(localStorage.getItem("extraData"));
  const sipp_code = localStorage.getItem("vehicle_sipp_code");

  useEffect(() => {
    const fetchBookingSearch = async () => {
      try {
        const res = await axios.post(
          `${API_URL}/api/booking/search`,
          {
            book: orderId,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );

        if (res.status === 200) {
          console.log(res.data);
          setHeader(res.data.booking.header);
        }
      } catch (error) {
        console.error(error);
      }
    };
  });

  const [confirm_number, setConfirmNumber] = useState("");
  const [pickup_date, setPickupDate] = useState(
    localStorage.getItem("pickup_date")
  );
  const [pickup_time, setPickupTime] = useState(
    localStorage.getItem("pickup_time")
  );
  const [pickup_location, setPickupLocation] = useState(
    localStorage.getItem("nameLocation")
  );
  const [dropoff_date, setDropoffDate] = useState(
    localStorage.getItem("dropoff_date")
  );
  const [dropoff_time, setDropOffTime] = useState(
    localStorage.getItem("dropoff_time")
  );
  const [dropoff_location, setDropoffLocation] = useState(
    localStorage.getItem("nameLocation")
  );
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [total_price, setTotalPrice] = useState(localStorage.getItem("total"));
  const [vehicle_sipp_code, setVehicleSippCode] = useState("");
  const [rate_code, setRateCode] = useState("");
  const [location, setLocation] = useState(
    localStorage.getItem("nameLocation")
  );

  const [pickupLat, setPickupLat] = useState("");
  const [pickupLng, setPickupLng] = useState("");
  const [dropoffLat, setDropoffLat] = useState("");
  const [dropoffLng, setDropoffLng] = useState("");
  const [requestTime, setRequestTime] = useState(
    localStorage.getItem("requestTime")
  );

  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [errorGetCurrentLocation, setErrorGetCurrentLocation] = useState("");

  const cityLocation = localStorage.getItem("cityLocation") ?? "";
  const codeLocation = localStorage.getItem("codeLocation") ?? "";
  const addressLine1Location =
    localStorage.getItem("addressLine1Location") ?? "";
  const addressLine2Location =
    localStorage.getItem("addressLine2Location") ?? "";
  const postalCodeLocation = localStorage.getItem("postalCodeLocation") ?? "";
  const countryCodePick = localStorage.getItem("countryCodePick") ?? "";
  const telephoneLocation = localStorage.getItem("telephoneLocation") ?? "";

  useEffect(() => {
    const fetchData = async () => {
      const pickupLocation = localStorage.getItem("pickup_location");
      const clientId = "04e19f27";

      if (pickupLocation) {
        try {
          const response = await axios.get(
            `https://stage.abgapiservices.com/cars/locations/v1?keyword=${pickupLocation}&client_id=${clientId}`,
            {
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("bebe"),
                client_id: clientId,
              },
            }
          );

          const pickupData = response.data.locations.find(
            (item) =>
              item.brand === BRAND &&
              item.code === localStorage.getItem("pickup_location")
          );

          const dropoffData = response.data.locations.find(
            (item) =>
              item.brand === BRAND &&
              item.code === localStorage.getItem("dropoff_location")
          );

          setPickupLat(pickupData.address.lat);
          setPickupLng(pickupData.address.long);
          setDropoffLat(dropoffData.address.lat);
          setDropoffLng(dropoffData.address.long);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        successGetCurrentLocation,
        errorMessageGetCurrentLocation
      );
    } else {
      setErrorGetCurrentLocation(
        "Geolocation is not supported in this browser"
      );
    }
  }, []);

  const successGetCurrentLocation = (position) => {
    setCurrentLatitude(position.coords.latitude);
    setCurrentLongitude(position.coords.longitude);
    setErrorGetCurrentLocation(null);
  };

  const errorMessageGetCurrentLocation = (error) => {
    setErrorGetCurrentLocation(error.message);
  };

  const getPickUpDirectionUrl = () => {
    if (currentLatitude && currentLongitude && pickupLat && pickupLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${pickupLat},${pickupLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  const getDropOffDirectionUrl = () => {
    if (currentLatitude && currentLongitude && dropoffLat && dropoffLng) {
      const currentLocation = `${currentLatitude},${currentLongitude}`;
      const targetLocation = `${dropoffLat},${dropoffLng}`;
      const url = `https://www.google.com/maps/dir/${currentLocation}/${targetLocation}`;
      return window.open(url, "_blank");
    }
  };

  let pickupDate = new Date(localStorage.getItem("pickup_date"));
  let dropoffDate = new Date(localStorage.getItem("dropoff_date"));

  let pickupDateYear = pickupDate.getFullYear();
  let pickupDatemonth = (pickupDate.getMonth() + 1).toString().padStart(2, "0");
  let pickupDateday = pickupDate.getDate().toString().padStart(2, "0");

  let dropoffDateYear = dropoffDate.getFullYear();
  let dropoffDatemonth = (dropoffDate.getMonth() + 1)
    .toString()
    .padStart(2, "0");
  let dropoffDateday = dropoffDate.getDate().toString().padStart(2, "0");

  pickupDate = pickupDateYear + "-" + pickupDatemonth + "-" + pickupDateday;
  dropoffDate = dropoffDateYear + "-" + dropoffDatemonth + "-" + dropoffDateday;

  let publicUrl = process.env.PUBLIC_URL + "/";

  const customIcon = new Icon({
    iconUrl: publicUrl + "assets/images/icon/marker.png",
    iconSize: [38, 38],
  });

  var dataReserve = {
    product: {
      brand: BRAND,
      iata_number: "0113105R",
    },
    transaction: {
      transaction_id: generateRandomNumbers().toString(),
    },
    reservation: {
      email_notification: true,
      pickup_date: format(
        localStorage.getItem("pickup_date"),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      pickup_location: localStorage.getItem("nameLocation"),
      dropoff_date: format(
        localStorage.getItem("dropoff_date"),
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      dropoff_location: localStorage.getItem("dropoff_location"),
      vehicle_sipp_code: localStorage.getItem("vehicle_sipp_code"),
    },
    rate_totals: {
      rate: {
        rate_code: localStorage.getItem("rate_code"),
        country_code: "ID",
      },
    },
    customer: {
      contact: {
        first_name: localStorage.getItem("first_name"),
        last_name: localStorage.getItem("last_name"),
        telephone: localStorage.getItem("telephone")
          ? localStorage.getItem("telephone").toString()
          : "",
        email: localStorage.getItem("email"),
        age: parseInt(localStorage.getItem("age")),
      },
      address: {
        country_code: "ID",
        address_line_1:
          localStorage.getItem("addressPick") == null
            ? ""
            : localStorage.getItem("addressPick"),
        address_line_2:
          localStorage.getItem("addressPick2") == null
            ? ""
            : localStorage.getItem("addressPick2"),
        address_line_3: "",
        city:
          localStorage.getItem("cityPick") == null
            ? ""
            : localStorage.getItem("cityPick"),
        postal_code:
          localStorage.getItem("postalPick") == null
            ? ""
            : localStorage.getItem("postalPick"),
      },
    },
    payment_preference: {
      type: "CentralBillingAccount",
      id: generateRandomNumbers().toString(),
      fixed_value: "FixedValue",
      electronic_indicator: true,
      amount: parseInt(localStorage.getItem("amount")),
      currency: "USD",
    },
  };

  console.log("tes", localStorage.getItem("pickup_date"), "tes");

  console.log(sipp_code);

  return (
    <div className="px-5">
      {/* Tiêu đề */}
      <section
        id="header"
        className="d-flex justify-content-between mt-4 border border-danger px-4 py-3"
      >
        <div className="d-flex d-grid gap-3">
          <Link to={process.env.PUBLIC_URL + `/`}>
            <img
              src={publicUrl + "assets/images/icon/budget.png"}
              alt=""
              width={100}
              height={30}
            />
          </Link>
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            Quyền lợi
          </Link>
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            CATHAY BUSINESS
          </Link>
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            BUDGET PREFERRED
          </Link>
        </div>
        <div className="d-flex d-grid gap-3">
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            Liên hệ
          </Link>
          <Link to={process.env.PUBLIC_URL + `/`} className="text-danger">
            Đặt chỗ của tôi
          </Link>
        </div>
      </section>
      {/* Thông điệp cảm ơn */}
      <section id="thank-you-note" className="mt-4">
        <div className="d-flex flex-column w-100 align-items-center text-center grid gap-3">
          <CiCircleCheck style={{ fontSize: "100px", color: "#4fdb6d" }} />
          <div
            style={{ color: "#4fdb6d", fontWeight: "bolder" }}
            className="fs-2"
          >
            Cảm ơn
          </div>
          <div className="fs-5">
            Đặt chỗ của bạn đã hoàn tất. Chúng tôi sẽ gửi email xác nhận cho bạn
            sớm.
          </div>
          <div className="fs-5">
            Mã tham chiếu của bạn: {localStorage.getItem("bookingNumber")}
          </div>
          <div style={{ fontSize: "18px" }}>
            Vui lòng đọc{" "}
            <Link
              to={process.env.PUBLIC_URL + `/`}
              style={{
                textDecoration: "underline",
                color: "red",
              }}
            >
              Điều khoản và Điều kiện
            </Link>
          </div>
        </div>
      </section>
      {/* Thông tin xe */}
      <section id="car-information" className="mt-4">
        <div
          style={{ backgroundColor: "#ebe8e8" }}
          className="d-flex flex-column grid"
        >
          <div className="d-flex justify-content-between">
            <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
              Đặt chỗ của bạn
            </div>
            <div className="p-4 fs-5" style={{ fontWeight: "bold" }}>
              Ngày đặt chỗ: {format(requestTime, "dd MMMM yyyy HH:mm")}
            </div>
          </div>

          <div
            style={{
              borderTop: "1px solid #d1d1d1",
              borderBottom: "1px solid #d1d1d1",
            }}
          >
            <div className="px-4">
              <div className="row">
                <div
                  className="col-sm py-4"
                  style={{ borderRight: "1px solid #d1d1d1" }}
                >
                  <div className="text-danger">Thời gian và địa điểm</div>
                  <p>
                    {format(new Date(pickup_date), "dd MMM yyyy")} {pickup_time}{" "}
                    - {format(new Date(dropoff_date), "dd MMM yyyy")}{" "}
                    {dropoff_time}
                  </p>
                  <p>{location}</p>
                </div>
                <div
                  className="col-sm py-4"
                  style={{ borderRight: "1px solid #d1d1d1" }}
                >
                  <div className="row mt-2">
                    <div className="col-sm-12">
                      <p>
                        <h5 className="text-dark">{make ?? ""}</h5>
                      </p>
                      <p>
                        {make ?? ""} {model ?? ""}
                      </p>
                      <p>{transmission ?? ""}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex justify-content-center">
                    <img
                      alt={image}
                      src={image ?? ""}
                      className="img-fluid"
                      width={200}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end p-4">
            <div style={{ marginRight: "250px" }}>Tổng cộng</div>
            <div>
              IDR{" "}
              {Number(localStorage.getItem("totalIdr")).toLocaleString("id")}
            </div>
          </div>
        </div>
      </section>
      {/* Thông tin */}
      <section id="information" className="mt-4">
        <div className="px-4">
          <div className="row">
            <div className="col-sm d-flex flex-column grid gap-3">
              <div className="fs-5" style={{ fontWeight: "bold" }}>
                Khi bạn nhận xe
              </div>
              <div>
                Một trong các thẻ thanh toán của bạn phải có tên của người lái
                xe chính trừ khi đặt qua thẻ tín dụng của công ty hoặc tài khoản
                và bạn cần xuất trình các giấy tờ sau tại quầy:
              </div>
              <div className="d-flex grid gap-3 align-items-center">
                <FaCheck color="red" />
                <div>Bằng lái xe hợp lệ</div>
              </div>
              <div className="d-flex grid gap-3 align-items-center">
                <FaCheck color="red" />
                <div>1 thẻ thanh toán</div>
              </div>
            </div>
            <div className="col-sm d-flex flex-column grid gap-3">
              <div className="fs-5">Đặt chỗ của bạn bao gồm:</div>
              <div className="fs-5">
                (Bao gồm dựa trên tỷ lệ thỏa thuận của bạn)
              </div>
              <div className="d-flex">
                <div
                  className="d-flex flex-column grid gap-3"
                  style={{ marginRight: "150px" }}
                >
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>Phụ phí sân bay</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>Thuế địa phương</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>Miễn phí hành lý</div>
                  </div>
                </div>
                <div className="d-flex flex-column grid gap-3">
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>Bảo hiểm trộm cắp</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>Bảo hiểm thiệt hại</div>
                  </div>
                  <div className="d-flex grid gap-3 align-items-center">
                    <FaCheck color="red" />
                    <div>Không giới hạn</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Thông tin thuê xe */}
      <section id="rental-information" className="my-4">
        <div style={{ backgroundColor: "#ebe8e8" }} className="p-4">
          <div className="fs-5" style={{ fontWeight: "bold" }}>
            Cách đến trạm cho thuê xe của bạn
          </div>
          <div class="mt-4">
            <div class="row">
              <div class="col-sm grid gap-2 d-flex flex-column">
                <div style={{ color: "rgb(212, 0, 42)" }}>Nhận xe</div>
                <p>
                  <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                  <br />
                  {localStorage.getItem("cityLocation") ?? ""},{" "}
                  {localStorage.getItem("codeLocation") ?? ""}
                  <br />
                  {localStorage.getItem("addressLine1Location") ?? ""},{" "}
                  {localStorage.getItem("addressLine2Location") ?? ""},{" "}
                  {localStorage.getItem("postalCodeLocation") ?? ""},{" "}
                  {localStorage.getItem("countryCodePick") ?? ""}
                  <br />
                  <b>Mã địa điểm: </b>{" "}
                  {localStorage.getItem("codeLocation") ?? ""}
                </p>
                <div className="d-flex grid gap-3 align-items-center">
                  <FaPhoneAlt color="red" />
                  <div>
                    tel: {localStorage.getItem("telephoneLocation") ?? ""}
                  </div>
                </div>
                {pickupLat && pickupLng && (
                  <Link
                    onClick={getPickUpDirectionUrl}
                    style={{
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    Nhận chỉ đường
                  </Link>
                )}

                {!pickupLat && !pickupLng && (
                  <div className="mt-3 text-danger">
                    Xin lỗi, chúng tôi không thể tìm thấy đường dẫn dẫn đường
                    nhận xe.
                  </div>
                )}
                {pickupLat && pickupLng && (
                  <div className="mt-3">
                    <MapContainer
                      center={[pickupLat, pickupLng]}
                      zoom={13}
                      scrollWheelZoom={false}
                      style={{ height: "350px" }}
                      className="w-100"
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        icon={customIcon}
                        position={[pickupLat, pickupLng]}
                      >
                        <Popup>
                          <p>
                            <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                            <br />
                            {localStorage.getItem("cityLocation") ?? ""},{" "}
                            {localStorage.getItem("codeLocation") ?? ""}
                            <br />
                            {localStorage.getItem("addressLine1Location") ?? ""}
                            ,{" "}
                            {localStorage.getItem("addressLine2Location") ?? ""}
                            , {localStorage.getItem("postalCodeLocation") ?? ""}
                            , {localStorage.getItem("countryCodePick") ?? ""}
                            <br />
                            <b>
                              {localStorage.getItem("telephoneLocation") ?? ""}
                            </b>
                            <br />
                            <b>Mã địa điểm: </b>{" "}
                            {localStorage.getItem("codeLocation") ?? ""}
                          </p>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                )}
              </div>
              <div class="col-sm grid gap-2 d-flex flex-column">
                <div style={{ color: "rgb(212, 0, 42)" }}>Trả xe</div>
                <p>
                  <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                  <br />
                  {localStorage.getItem("cityLocation") ?? ""},{" "}
                  {localStorage.getItem("codeLocation") ?? ""}
                  <br />
                  {localStorage.getItem("addressLine1Location") ?? ""},{" "}
                  {localStorage.getItem("addressLine2Location") ?? ""},{" "}
                  {localStorage.getItem("postalCodeLocation") ?? ""},{" "}
                  {localStorage.getItem("countryCodePick") ?? ""}
                  <br />
                  <b>Mã địa điểm: </b>{" "}
                  {localStorage.getItem("codeLocation") ?? ""}
                </p>
                <div className="d-flex grid gap-3 align-items-center">
                  <FaPhoneAlt color="red" />
                  <div>
                    tel: {localStorage.getItem("telephoneLocation") ?? ""}
                  </div>
                </div>
                {dropoffLat && dropoffLng && (
                  <Link
                    onClick={getDropOffDirectionUrl}
                    style={{
                      textDecoration: "underline",
                      color: "red",
                    }}
                  >
                    Nhận chỉ đường
                  </Link>
                )}

                {!dropoffLat && !dropoffLng && (
                  <div className="mt-3 text-danger">
                    Xin lỗi, chúng tôi không thể tìm thấy đường dẫn dẫn đường
                    trả xe.
                  </div>
                )}
                {dropoffLat && dropoffLng && (
                  <div className="mt-3">
                    <MapContainer
                      center={[dropoffLat, dropoffLng]}
                      zoom={13}
                      scrollWheelZoom={false}
                      style={{ height: "350px" }}
                      className="w-100"
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        icon={customIcon}
                        position={[dropoffLat, dropoffLng]}
                      >
                        <Popup>
                          <p>
                            <b>{localStorage.getItem("nameLocation") ?? ""}</b>
                            <br />
                            {localStorage.getItem("cityLocation") ?? ""},{" "}
                            {localStorage.getItem("codeLocation") ?? ""}
                            <br />
                            {localStorage.getItem("addressLine1Location") ?? ""}
                            ,{" "}
                            {localStorage.getItem("addressLine2Location") ?? ""}
                            , {localStorage.getItem("postalCodeLocation") ?? ""}
                            , {localStorage.getItem("countryCodePick") ?? ""}
                            <br />
                            <b>
                              {localStorage.getItem("telephoneLocation") ?? ""}
                            </b>
                            <br />
                            <b>Mã địa điểm: </b>{" "}
                            {localStorage.getItem("codeLocation") ?? ""}
                          </p>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />,
    </div>
  );
};

export default ConfirmBookings;
