import React from "react";
import { Link } from "react-router-dom";

export default class CG extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const myBg = {
      backgroundImage:
        "url(" + publicUrl + "assets/images/resources/carguides.avif)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "250px",
    };
    const myText = {
      color: "#000",
    };
    const myLink = {
      color: "rgb(237, 85, 5)",
    };
    const myPara = {
      lineHeight: "1.5",
      fontSize: "15px",
    };
    return (
      <>
        {/* Bắt đầu Slogan One */}
        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3">
              <div className="col-lg-3">
                <select className="form-control">
                  <option>Tất cả các xe</option>
                </select>
              </div>
              <div className="col-lg-3">
                <select className="form-control">
                  <option>Hoa Kỳ</option>
                </select>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row pt-3 pb-3">
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>Xe minivan</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica hoặc tương tự{" "}
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button style={myLink}>Thông tin xe</button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button
                          className="btn align-self-center"
                          style={{
                            backgroundColor: "rgb(0, 40, 95)",
                            color: "#fff",
                            padding: "0px 50px",
                            height: "50px",
                          }}
                        >
                          Đặt ngay
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>Xe minivan</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica hoặc tương tự{" "}
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button style={myLink}>Thông tin xe</button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button
                          className="btn align-self-center"
                          style={{
                            backgroundColor: "rgb(0, 40, 95)",
                            color: "#fff",
                            padding: "0px 50px",
                            height: "50px",
                          }}
                        >
                          Đặt ngay
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 mb-4">
                <div className="col-sm-12" style={{ backgroundColor: "#fff" }}>
                  <div className="row py-4 px-2">
                    <div className="col-sm-6">
                      <p style={{ fontSize: "20px" }}>Xe minivan</p>
                      <p style={{ fontSize: "15px" }}>
                        Chrysler Pacifica hoặc tương tự{" "}
                      </p>
                    </div>
                    <div className="col-sm-6 text-end">
                      <button style={myLink}>Thông tin xe</button>
                    </div>
                  </div>
                  <div className="row px-2">
                    <img
                      className="col-sm-12"
                      src={publicUrl + "assets/images/resources/cars1.avif"}
                    />
                  </div>
                  <div className="row px-2 pb-3">
                    <div className="col-sm-12 text-center">
                      <a href="#">
                        <button
                          className="btn align-self-center"
                          style={{
                            backgroundColor: "rgb(0, 40, 95)",
                            color: "#fff",
                            padding: "0px 50px",
                            height: "50px",
                          }}
                        >
                          Đặt ngay
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container pt-3">
            <div className="row">
              <div className="col-sm-12">
                <p
                  className="mb-3"
                  style={{ fontSize: "25px", color: "rgb(0, 40, 95)" }}
                >
                  Các lựa chọn thuê xe của Budget
                </p>
                <p className="mb-3" style={myPara}>
                  Tìm mọi loại xe bạn cần với giá tuyệt vời khi thuê xe từ
                  Budget. Cho dù bạn đang đi công tác một mình hay lên kế hoạch
                  cho kỳ nghỉ cùng gia đình, một trong các loại xe của chúng tôi
                  sẽ hoàn toàn phù hợp với bạn.{" "}
                </p>
                <p className="mb-3" style={myPara}>
                  Danh sách xe cho thuê của chúng tôi bao gồm:
                </p>
                <ul style={myPara} className="ps-3 mb-3">
                  <li>
                    <Link to={"/van-rental"} style={myLink}>
                      Xe tải với chỗ ngồi từ 7-15 người
                    </Link>
                  </li>
                  <li>
                    <Link to={"/suv-rental"} style={myLink}>
                      SUV rộng rãi với mọi kích thước
                    </Link>
                  </li>
                  <li>
                    <Link to={"/pickup-truck-rent"} style={myLink}>
                      Xe bán tải cho việc vận chuyển
                    </Link>
                  </li>
                  <li>
                    <Link to={"/rent-car-sizes"} style={myLink}>
                      Xe từ tiết kiệm đến sang trọng
                    </Link>
                  </li>
                </ul>
                <p className="mb-3" style={myPara}>
                  Muốn có một chuyến đi đặc biệt? Kiểm tra{" "}
                  <Link to={"/sweeter-rides"} style={myLink}>
                    Các xe cho thuê cao cấp và xe sang
                  </Link>
                  ! Đưa chiếc xe tiết kiệm nhiên liệu tuyệt vời lên chuyến đi mà
                  bạn mơ ước hoặc đưa chiếc xe trong mơ của bạn đi nghỉ cuối
                  tuần. Thuê xe từ Budget cung cấp nhiều tùy chọn để di chuyển.{" "}
                </p>
                <p className="mb-3" style={myPara}>
                  Các loại xe cho thuê có sẵn phụ thuộc vào địa điểm và thời
                  gian bạn nhận xe. Đặt càng sớm, càng có nhiều lựa chọn, vì vậy
                  đừng chần chừ. Hãy đặt xe từ Budget trực tuyến ngay hôm nay.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Kết thúc Slogan One */}
      </>
    );
  }
}
