import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OptionHeader from "../common/header/OptionHeader";
import Benefit from "../components/luxury/Benefit";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import FAQ from "../components/luxury/FAQ";
import LuxuryCarDetail from "../components/luxury/LuxuryCarDetail";
import InfoDetailCar from "../components/rent/InfoDetailCar";
import Jumbotron from "../components/rent/Jumbotron";

const CarExploreDetails = () => {
  const breadcrumb = ["คู่มือการใช้รถ", "สหรัฐอเมริกา", "เช่ารถประหยัด"];
  return (
    <>
      <HeaderOne optionHeader={true} />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Jumbotron
        title="จองรถเช่าระดับไฮเอนด์"
        subtitle="Chrysler 300 และรุ่นที่คล้ายกัน"
      />
      <InfoDetailCar seats="7" large="2" small="2" />
      <LuxuryCarDetail
        title={"ตัวเลือกการเช่ารถระดับไฮเอนด์จาก Budget"}
        description={
          "เลือกเช่ารถหรูหากคุณต้องการยกระดับประสบการณ์การขับขี่ของคุณ ไม่ว่าคุณจะเดินทางเพื่อติดต่อธุรกิจหรือการพักผ่อนสุดพิเศษ คุณจะต้องหลงรักการขับขี่ในรถระดับไฮเอนด์ที่มีทั้งเครื่องยนต์ที่ทรงพลัง ภายในที่สะดวกสบาย และดีไซน์ภายนอกที่โดดเด่น Budget มีรถเช่าหรูให้เลือกมากมาย รวมถึงแบรนด์และรุ่นที่โดดเด่นดังต่อไปนี้:"
        }
        image={""}
      />
      <LuxuryCarDetail
        title={"Chrysler 300"}
        description={
          "เพลิดเพลินไปกับประสิทธิภาพของเครื่องยนต์ที่ทรงพลัง การขับขี่ที่สะดวกสบาย และระบบเสียงที่เหนือชั้นเมื่อคุณเช่ารถ Chrysler 300 LTD 3.6L จาก Budget"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car2.webp"}
        color={"bg-alicelightblue"}
      />
      <LuxuryCarDetail
        title={"Volvo XC60"}
        description={
          "เลือกเช่า Volvo XC60 และเพลิดเพลินไปกับภายในที่หรูหรา ระบบเบรกที่มีประสิทธิภาพสูง และพื้นที่กว้างขวางสำหรับความสะดวกสบายในการขับขี่"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car3.webp"}
      />
      <LuxuryCarDetail
        title={"Buick LaCrosse"}
        description={
          "Buick LaCrosse มีความคล่องตัวที่น่าประหลาดใจ ดีไซน์ที่สวยงาม และระบบอินโฟเทนเมนต์ที่ใช้งานง่าย คุณจะหลงรักห้องโดยสารที่หรูหราและกว้างขวางเช่นกัน"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car4.webp"}
        color={"bg-alicelightblue"}
      />
      <LuxuryCarDetail
        title={"Lincoln MKT"}
        description={
          "เพลิดเพลินไปกับคุณสมบัติความปลอดภัยขั้นสูงและระบบกันสะเทือนที่เน้นความสะดวกสบายด้วยการเช่า Lincoln MKT แรงม้าใต้ฝากระโปรงมากมายทำให้การขับขี่สนุกสนาน"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car5.webp"}
      />
      <LuxuryCarDetail
        title={"Lincoln MKX"}
        description={
          "การเช่า Lincoln MKX จาก Budget มาพร้อมกับคุณสมบัติที่น่าสนใจมากมาย เช่น ระบบอินโฟเทนเมนต์หน้าจอสัมผัส กล้องมองหลัง และเซ็นเซอร์จอดรถ"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car6.webp"}
        color={"bg-alicelightblue"}
      />
      <LuxuryCarDetail
        title={"Lincoln MKZ"}
        description={
          "การเช่า Lincoln MKZ หรือ Lincoln MKZ Hybrid ช่วยให้คุณได้ขับรถที่ตอบสนองรวดเร็ว ขับขี่สะดวกสบาย และมีการควบคุมที่ดีเยี่ยม"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car7.webp"}
      />
      <LuxuryCarDetail
        title={"Lincoln MKC"}
        description={
          "การเช่า Lincoln MKC ที่เงียบและกว้างขวางช่วยให้ผู้ขับขี่มีประสบการณ์การขับขี่ที่เป็นธรรมชาติและง่ายดาย ด้วยพวงมาลัยคุณภาพสูงและเครื่องยนต์ที่มีประสิทธิภาพบนทุกถนน"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car8.webp"}
        color={"bg-alicelightblue"}
      />
      <LuxuryCarDetail
        title={"Lincoln Nautilus"}
        description={
          "คุณจะเพลิดเพลินไปกับความสมดุลระหว่างความหรูหราและความเป็นประโยชน์ของการเช่า Lincoln Nautilus จาก Budget ซึ่งมีพื้นที่เก็บสัมภาระมากมายและการควบคุมที่ยอดเยี่ยม"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car9.webp"}
      />
      <LuxuryCarDetail
        title={"Cadillac XT5"}
        description={
          "ด้วยกุญแจรถ Cadillac XT5 ที่เช่าจาก Budget คุณจะได้สัมผัสประสบการณ์การขับขี่ที่น่าทึ่ง ไม่ว่าจะเป็นการเดินทางไกลหรือการขับรถในสภาพอากาศหนาวเย็น"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car10.webp"}
        color={"bg-alicelightblue"}
      />
      <LuxuryCarDetail
        title={"INFINITI Q50"}
        description={
          "การเช่า INFINITI Q50 มาพร้อมกับการควบคุมที่ยอดเยี่ยม ระบบเกียร์อัตโนมัติ 7 สปีด และการออกแบบที่โดดเด่นทั้งหมดในรุ่นที่มีสไตล์"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car11.webp"}
      />
      <LuxuryCarDetail
        title={"INFINITI Q70"}
        description={
          "คุณจะหลงรัก INFINITI Q70 ที่เช่าเพราะเครื่องยนต์ที่ทรงพลัง การออกแบบโค้งเว้าที่เป็นเอกลักษณ์ และคุณสมบัติมาตรฐานมากมาย รวมถึงระบบเสียงชั้นยอดและซันรูฟ"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car12.webp"}
        color={"bg-alicelightblue"}
      />
      <LuxuryCarDetail
        title={"INFINITI QX30"}
        description={
          "การเช่า INFINITI QX30 มาพร้อมกับการเร่งความเร็วที่รวดเร็ว พวงมาลัยที่แม่นยำ และพื้นที่เก็บสัมภาระที่สะดวกสบายภายในรถครอสโอเวอร์ซีดาน"
        }
        image={process.env.PUBLIC_URL + "/assets/cars-budget/car13.webp"}
      />
      <div className="container mb-5">
        <i>
          ยี่ห้อรถ รุ่น การตกแต่ง คุณสมบัติ
          และสีของยานพาหนะไม่สามารถรับประกันได้
          และขึ้นอยู่กับสถานที่และความพร้อมใช้งาน
          รายละเอียดของยานพาหนะอาจมีการเปลี่ยนแปลงขึ้นอยู่กับปีของรุ่นยานพาหนะ
        </i>
      </div>
      <div className="bg-findOut pt-4">
        <FAQ />
      </div>
      <Benefit />
      <FooterOne />
    </>
  );
};

export default CarExploreDetails;
