import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import LandingSelectCar from "../components/road/LandingSelectCar";
import FooterOne from "../common/footer/FooterOne";
import Title from "../Title";

export default function GpsNavigation() {
  Title("GPS Navigation | Budget Car Rental");
  return (
    <>
      <HeaderOne />
      <LandingSelectCar
        image="cq5dam.web.1536.1008.avif"
        title="Dẫn dắt chuyến đi của bạn với GPS"
        sub=""
        page=""
      />
      <section>
        <div className="container pt-3">
          <div className="row">
            <div className="col-sm-12">
              <h6>Thêm hệ thống định vị GPS vào xe thuê của bạn</h6>
              <p>
                Khi chuyến đi đưa bạn đến những nơi mới, không có thời gian để
                chơi tìm đường. Với GPS, bạn có thể dễ dàng tìm thấy địa chỉ,
                khách sạn và các điểm du lịch.
              </p>
              <hr />
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </>
  );
}
