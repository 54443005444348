import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const Nav = (props) => {
  const [btnMobile, setBtnMobile] = useState(false);

  useEffect(() => {
    props.setIsMenu(btnMobile);
  }, [btnMobile]);

  return (
    <>
      <div className="main-menu__inner">
        <MobileMenu btnIsMobile={btnMobile} setIsMobile={setBtnMobile} />
        <ul className="main-menu__list">
          <li className="dropdown">
            <Link
              style={{ fontSize: "17px" }}
              to={process.env.PUBLIC_URL + `/`}
            >
              Đặt chỗ
            </Link>
            <ul>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={process.env.PUBLIC_URL + `/makereservation`}
                >
                  Thực hiện đặt chỗ
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={`${process.env.PUBLIC_URL}/view-cancel`}
                >
                  Xem / Hủy
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link
              style={{ fontSize: "17px" }}
              to={process.env.PUBLIC_URL + `/`}
            >
              Sự kiện và Khuyến mãi đặc biệt
            </Link>
            <ul>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={process.env.PUBLIC_URL + `/international`}
                >
                  Khuyến mãi
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link
              style={{ fontSize: "17px" }}
              to={process.env.PUBLIC_URL + `/carguides`}
            >
              Hướng dẫn xe
            </Link>
          </li>
          <li className="dropdown">
            <Link
              style={{ fontSize: "17px" }}
              to={process.env.PUBLIC_URL + `#`}
            >
              Sản phẩm và Dịch vụ
            </Link>
            <ul>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={`${process.env.PUBLIC_URL}/productservice/products`}
                >
                  Sản phẩm
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={process.env.PUBLIC_URL + `/productservice/services`}
                >
                  Dịch vụ
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={process.env.PUBLIC_URL + `/productservice/protection`}
                >
                  Bảo vệ và Bảo hiểm
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={process.env.PUBLIC_URL + `/productservice/program`}
                >
                  Chương trình
                </Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <Link
              style={{ fontSize: "17px" }}
              to={process.env.PUBLIC_URL + `#`}
            >
              Địa điểm
            </Link>
            <ul>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={process.env.PUBLIC_URL + `/findlocation`}
                >
                  Tìm địa điểm
                </Link>
              </li>
              <li>
                <Link
                  style={{ fontSize: "17px" }}
                  to={process.env.PUBLIC_URL + `/airportlocation`}
                >
                  Sân bay phổ biến
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Nav;
