import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import PS from "../components/productservice/PS";
import Title from "../Title";
import { Link } from "react-router-dom";

export default function ChildSafetySeats() {
  Title("Child Safety Seats with your Car Rental | Budget Car Rental");
  return (
    <>
      <HeaderOne />
      <section className="border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 pt-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link>Sản phẩm và Dịch vụ</Link>
                  </li>
                  <li className="breadcrumb-item">Sản phẩm</li>
                  <li className="breadcrumb-item linked">
                    <Link to={"/productservice/products/child-safety-seats"}>
                      Ghế an toàn cho trẻ em
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container pt-5">
          <div className="row">
            <div className="col-sm-12">
              <h1>Ghế an toàn cho trẻ em với xe thuê của bạn</h1>
              <p className="mt-3">
                An toàn là ưu tiên hàng đầu của chúng tôi tại Budget. Chúng tôi
                muốn đảm bảo rằng con bạn được trang bị ghế an toàn phù hợp.
                Dưới đây là hướng dẫn của chúng tôi về cách chọn ghế an toàn cho
                trẻ em và trẻ sơ sinh:
              </p>
              <ol className="mt-2">
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>Dưới 1 tuổi (và nặng dưới 20-22 pound)</p>
                    <p>
                      Ghế cho trẻ sơ sinh quay mặt về phía sau, ghế Convertible
                      hoặc ghế 3-trong-1.
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>1-3 tuổi (và nặng 20 đến 40 pound)</p>
                    <p>
                      Ghế quay mặt về phía sau cho đến khi vượt quá giới hạn cân
                      nặng và chiều cao do nhà sản xuất đặt ra.
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>4-7 tuổi (hoặc cao đến 4'9")</p>
                    <p>
                      Ghế quay mặt về phía trước cho đến khi vượt quá giới hạn
                      cân nặng và chiều cao do nhà sản xuất đặt ra.
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>8-12 tuổi (hoặc ít nhất cao hơn 4'9")</p>
                    <p>
                      Ghế nâng với dây an toàn ở thắt lưng và vai cho đến khi
                      vượt quá giới hạn cân nặng và chiều cao do nhà sản xuất
                      đặt ra.
                    </p>
                  </div>
                </li>
                <li
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    rowGap: "5px",
                  }}
                  className="mt-1"
                >
                  <div>•</div>
                  <div>
                    <p>Trẻ lớn hơn nên sử dụng ghế nâng</p>
                    <p>
                      Chính phủ Hoa Kỳ khuyến cáo rằng trẻ em nặng từ 40 đến
                      khoảng 80 pound và cao khoảng 4'9" luôn ngồi trên ghế
                      nâng. Ghế nâng giúp nâng trẻ lên để dây an toàn cho người
                      lớn được đặt đúng cách và an toàn ở vị trí thắt lưng và
                      vai. Ngoài ra, ghế nâng còn giúp tăng khả năng quan sát và
                      sự thoải mái cho trẻ.
                    </p>
                  </div>
                </li>
              </ol>
              <p className="mt-3 mb-5">
                Tại các{" "}
                <Link to={"/findlocation"} className="linked">
                  địa điểm do công ty vận hành
                </Link>
                , nếu bạn đặt trước 48 giờ trước khi nhận xe, chúng tôi đảm bảo
                sẽ có sẵn ghế an toàn cho trẻ em. Trong vòng 48 giờ trước khi
                nhận xe và tại các địa điểm ngoài Hoa Kỳ và{" "}
                <Link to={"/findlocation"} className="linked">
                  các địa điểm do người được nhượng quyền điều hành
                </Link>{" "}
                , yêu cầu của bạn sẽ được ghi lại cùng với{" "}
                <Link to={"/makereservation"} className="linked">
                  đặt chỗ của bạn
                </Link>
                , nhưng việc thuê sẽ được thực hiện theo thứ tự ưu tiên, vì vậy
                không đảm bảo rằng sẽ có sẵn sản phẩm.
              </p>
              <div className="mb-5">
                <Link to={"/makereservation"}>
                  <button
                    className="btn align-self-center"
                    style={{
                      backgroundColor: "rgb(0, 40, 95)",
                      color: "#fff",
                      padding: "0px 50px",
                      height: "50px",
                    }}
                  >
                    Đặt chỗ
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </>
  );
}
