import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import ContentFraudScam from "../components/customer-care/ContentFraudScam";
import Header from "../common/header/Header";
import Title from "../Title";

const FraudScams = () => {
  Title("Customer Care");
  return (
    <>
      <HeaderOne />
      <Header
        title="Dịch vụ chăm sóc khách hàng"
        subtitle="Bạn có câu hỏi? Bạn đã đến đúng nơi!"
      />

      <ContentFraudScam />
      <FooterOne />
    </>
  );
};

export default FraudScams;
