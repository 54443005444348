import React from "react";

const FuelPlan = () => {
  return (
    <div className="container">
      <hr />
      <h3>Kế hoạch nhiên liệu</h3>
      <p className="mt-3 mb-3">
        Budget cung cấp nhiều tùy chọn về nhiên liệu. Cho dù bạn chọn tự đổ
        nhiên liệu hay để chúng tôi xử lý, tại hầu hết các địa điểm, khi bạn
        nhận xe, đại diện bán hàng sẽ giải thích các tùy chọn nhiên liệu có sẵn
        và thực hiện theo yêu cầu của bạn. Bạn sẽ được yêu cầu chọn từ các tùy
        chọn dưới đây:
      </p>
      <b className="mt-3">Đổ nhiên liệu trước</b>
      <p className="mt-3 mb-3">
        Tùy chọn này cho phép bạn mua đầy bình nhiên liệu khi bắt đầu thuê xe.
        Bạn có thể trả xe mà không cần đổ lại nhiên liệu. Chúng tôi cung cấp giá
        nhiên liệu trung bình theo thị trường.
      </p>
      <b className="mt-3">Thanh toán khi trả xe</b>
      <p className="mt-3 mb-3">
        Nếu bạn không chọn tùy chọn đổ nhiên liệu trước và thấy rằng bạn không
        có thời gian để đổ nhiên liệu khi kết thúc thuê xe, chúng tôi sẽ đổ
        nhiên liệu khi bạn trả xe. Xin lưu ý rằng sẽ có phụ phí trên giá nhiên
        liệu.
      </p>
      <b className="mt-3">EZ Fuel™</b>
      <p className="mt-3 mb-3">
        Đi dưới 75 dặm? Với EZ Fuel, bạn có thể để chúng tôi xử lý việc đổ nhiên
        liệu cho bạn.
      </p>
      <b className="mt-3">Trả xe với đầy bình nhiên liệu</b>
      <p className="mt-3 mb-3">
        Budget sẽ cung cấp xe với bình nhiên liệu đầy khi bắt đầu thuê xe. Bạn
        phải trả xe với đầy bình nhiên liệu. Nếu nhiên liệu không đầy, bạn sẽ bị
        tính phí theo giá đổ nhiên liệu khi trả xe.
      </p>
      <p className="mt-3">
        Xin lưu ý rằng giá nhiên liệu và kế hoạch đổ nhiên liệu có thể khác nhau
        giữa các quốc gia.
      </p>
      <p className="mb-3">Kế hoạch có thể được mua tại quầy khi bạn nhận xe.</p>
    </div>
  );
};

export default FuelPlan;
