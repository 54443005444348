import React from "react";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import Info from "../components/about/Info";
import Header from "../common/header/Header";

const CompanyInfo = () => {
  const breadcrumb = ["Về Budget Rent A Car", "Thông Tin Công Ty"];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <Header title="Budget: Thông Tin Công Ty" />
      <Info />
      <FooterOne />
    </>
  );
};

export default CompanyInfo;
