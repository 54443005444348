import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import OfferDetails from "../components/budgetworrypromise/OfferDetails";
import BreadcrumbCar from "../components/luxury/BreadcrumbCar";
import LandingSelectCar from "../components/road/LandingSelectCar";

const BudgetWorryPromise = () => {
  const breadcrumb = [
    "Sản phẩm và Dịch vụ",
    "Dịch vụ",
    "Cam kết Không lo lắng của Budget",
  ];
  return (
    <>
      <HeaderOne />
      <BreadcrumbCar breadcrumb={breadcrumb} />
      <LandingSelectCar
        image=""
        title="Cam kết Không lo lắng của Budget"
        sub="Sự tiện lợi và các tùy chọn tốt nhất"
      />
      <OfferDetails />
      <FooterOne />
    </>
  );
};

export default BudgetWorryPromise;
