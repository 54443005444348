import React from "react";
import { Link } from "react-router-dom";
import CardPrivacy from "./CardPrivacy";

const ContentPrivacy = () => {
  const handleTopScroll = () => {
    const element = document.getElementById("privacy");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="container py-4 text-dark" id="welcome">
      <p className="mb-3">
        Chào mừng bạn đến với Thông báo về Quyền riêng tư của các công ty Avis
        và Budget thuộc Avis Budget Group, Inc. và các công ty con và chi nhánh
        của họ, bao gồm nhưng không giới hạn Avis Rent A Car System, LLC, Budget
        Rent A Car System, Inc., Payless Car Rental, Inc. và Budget Truck
        Rental, LLC (chúng tôi gọi các công ty này là{" "}
        <b>“ABG,” “chúng tôi,” “của chúng tôi”</b> trong Thông báo Quyền riêng
        tư này). Thông báo Quyền riêng tư này không áp dụng cho Zipcar. Thông
        báo Quyền riêng tư này bao gồm thông tin cá nhân mà chúng tôi thu thập,
        sử dụng và tiết lộ thông qua việc bạn sử dụng các trang web, ứng dụng di
        động (<b>“Ứng dụng”</b>), sản phẩm và dịch vụ của chúng tôi, trực tuyến
        hoặc ngoại tuyến (gọi chung là <b>“dịch vụ”</b>). Tuy nhiên, Thông báo
        Quyền riêng tư này không phải là một hợp đồng và không tạo ra bất kỳ
        quyền hoặc nghĩa vụ pháp lý nào ngoài những quy định pháp luật. Trong
        một số trường hợp, chúng tôi có thể cung cấp các thông báo bổ sung hoặc
        thông báo quyền riêng tư cụ thể cho một số sản phẩm, dịch vụ hoặc chương
        trình. Các thông báo bổ sung đó bổ sung và cần được đọc cùng với Thông
        báo Quyền riêng tư này.
      </p>
      <p className="mb-3">
        <b>Cập nhật Thông báo Quyền riêng tư này</b>
      </p>
      <p className="mb-3">
        Khi chúng tôi thực hiện các thay đổi đối với Thông báo Quyền riêng tư
        này, chúng tôi sẽ đăng các thay đổi tại đây và sửa đổi ngày “Cập nhật
        lần cuối” ở đầu trang này. Nếu chúng tôi thực hiện một thay đổi ảnh
        hưởng đáng kể đến quyền của bạn, chúng tôi sẽ thông báo cho bạn qua các
        kênh liên lạc phù hợp (chẳng hạn như bằng cách đăng nổi bật trên các
        trang web và trong Ứng dụng của chúng tôi), trước khi thay đổi có hiệu
        lực. Nếu bạn có tài khoản với chúng tôi, chúng tôi cũng có thể thông báo
        cho bạn về các thay đổi quan trọng đối với Thông báo Quyền riêng tư này
        qua e-mail (gửi đến địa chỉ e-mail được cung cấp trong tài khoản của
        bạn), thông qua Ứng dụng của chúng tôi, hoặc theo yêu cầu hoặc được pháp
        luật cho phép. Mọi thay đổi đối với Thông báo Quyền riêng tư này sẽ có
        hiệu lực ngay sau khi đăng Thông báo Quyền riêng tư đã sửa đổi.
      </p>
      <p className="mb-3">
        Việc sử dụng trang web, Ứng dụng, sản phẩm và dịch vụ của chúng tôi sau
        khi đăng Thông báo Quyền riêng tư mới có nghĩa là bạn chấp nhận Thông
        báo Quyền riêng tư đã sửa đổi hiện hành. Do đó, chúng tôi khuyến khích
        bạn thường xuyên xem lại trang này để có thông tin mới nhất về các thực
        hành quyền riêng tư của chúng tôi.{" "}
      </p>
      <p className="mb-3">
        <b>Tiết lộ của các bên được cấp phép</b>
      </p>
      <p className="mb-3">
        Nhiều địa điểm hoặc nhượng quyền độc lập, mà chúng tôi gọi là “bên được
        cấp phép,” sử dụng tên của chúng tôi nhưng không thuộc sở hữu hoặc kiểm
        soát của chúng tôi. Thông báo Quyền riêng tư này không áp dụng cho việc
        xử lý thông tin cá nhân của bạn bởi các bên được cấp phép độc lập này.{" "}
        <Link className="linked text-decoration-none">Nhấp vào đây</Link> để tìm
        hiểu thêm về các bên được cấp phép.
      </p>
      <p className="mb-3">
        <b>Tiết lộ theo khu vực</b>
      </p>
      <p className="mb-3">
        <b>
          <i>Dành cho cư dân Hoa Kỳ:</i>
        </b>{" "}
        Nếu bạn cư trú tại bang California hoặc Virginia ở Hoa Kỳ, vui lòng{" "}
        <Link className="linked text-decoration-none">Nhấp vào đây</Link> để
        biết thêm thông tin tiết lộ quyền riêng tư theo bang.
      </p>
      <p className="mb-3">
        <b>
          <i>Dành cho cư dân Vương quốc Anh và Liên minh Châu Âu:</i>
        </b>{" "}
        Nếu bạn cư trú tại Vương quốc Anh hoặc Liên minh Châu Âu, vui lòng sử
        dụng trang web phù hợp cho vị trí của bạn, có thể tìm thấy{" "}
        <Link className="linked text-decoration-none">ở đây</Link>, thay vì sử
        dụng trang web này.
      </p>
      <div className="row my-4 d-xl-flex d-none">
        <CardPrivacy
          image="types_information.png"
          text="CÁC LOẠI THÔNG TIN CHÚNG TÔI THU THẬP VÀ CÁCH CHÚNG TÔI THU THẬP"
          direct="types_information"
        />
        <CardPrivacy
          image="how_used_information.png"
          text="CÁCH SỬ DỤNG THÔNG TIN"
          direct="how_used_information"
        />
        <CardPrivacy
          image="how_shared_information.png"
          text="CÁCH THÔNG TIN ĐƯỢC CHIA SẺ"
          direct="how_shared_information"
        />
        <CardPrivacy
          image="how_protected_information.png"
          text="CÁCH BẢO VỆ THÔNG TIN"
          direct="how_protected_information"
        />
        <CardPrivacy
          image="children_privacy.png"
          text="QUYỀN RIÊNG TƯ CỦA TRẺ EM"
          direct="children_privacy"
        />
        <CardPrivacy
          image="third_party_collection.png"
          text="THU THẬP BỞI BÊN THỨ BA"
          direct="third_party_collection"
        />
        <CardPrivacy
          image="contact_us.png"
          text="LIÊN HỆ CHÚNG TÔI"
          direct="contact_us"
        />
        <CardPrivacy
          image="independent_locations.png"
          text="ĐỊA ĐIỂM ĐỘC LẬP"
          direct="independent_locations"
        />
        <CardPrivacy
          image="state_privacy.png"
          text="TIẾT LỘ QUYỀN RIÊNG TƯ CỦA CÁC BANG HOA KỲ"
          direct="state_privacy"
        />
      </div>
      <hr />
      <p className="mt-5 mb-3">
        Nếu bạn có câu hỏi, nhận xét hoặc gợi ý, bạn có thể liên hệ với chúng
        tôi bằng bất kỳ cách nào được mô tả trong phần{" "}
        <Link className="linked text-decoration-none">LIÊN HỆ CHÚNG TÔI</Link>.
      </p>

      <div className="pt-5" id="types_information">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>
            1. CÁC LOẠI THÔNG TIN CHÚNG TÔI THU THẬP VÀ CÁCH CHÚNG TÔI THU THẬP
            CHÚNG
          </b>
        </p>
        <p className="mb-3">
          Chúng tôi thu thập thông tin từ bạn, trực tiếp và thông qua các bên
          thứ ba, phù hợp với Thông báo về Quyền riêng tư này bất cứ lúc nào bạn
          liên hệ với chúng tôi, tương tác với chúng tôi trực tiếp, thông qua
          dịch vụ của chúng tôi hoặc trên các nền tảng của bên thứ ba, hoặc sử
          dụng trang web, Ứng dụng, sản phẩm hoặc dịch vụ của chúng tôi. Nếu bạn
          không cung cấp thông tin chúng tôi yêu cầu, chúng tôi có thể không
          cung cấp được sản phẩm hoặc dịch vụ cho bạn.
        </p>
        <p className="mb-3">
          <b>
            <u>Thông tin cá nhân.</u>
          </b>
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Một số thông tin chúng tôi thu thập có thể nhận dạng cá nhân bạn,
            bao gồm, ví dụ, tên và thông tin liên hệ của bạn, ngày sinh, giấy tờ
            nhận dạng của chính phủ, thông tin thanh toán, mã số thành viên, mã
            số chương trình, liệu bạn có tham gia chương trình doanh nghiệp hoặc
            chương trình thưởng, các sắp xếp thanh toán, sắp xếp bảo hiểm và
            thông tin liên quan đến việc bạn thuê hoặc sử dụng sản phẩm hoặc
            dịch vụ mà chúng tôi cung cấp. Ví dụ, khi bạn đặt chỗ hoặc tham gia
            vào một trong các chương trình của chúng tôi, chúng tôi thu thập
            thông tin cá nhân của bạn như: tên và thông tin liên hệ; ngày sinh;
            giấy phép lái xe và các giấy tờ nhận dạng chính phủ khác; thông tin
            thanh toán, bao gồm thông tin thẻ tín dụng hoặc thẻ ghi nợ (chúng
            tôi thu thập mã bảo mật khi bạn thanh toán, nhưng chúng tôi không
            lưu trữ chúng); thông tin để xử lý các ưu đãi giảm giá của bạn,
            chẳng hạn như phiếu giảm giá, mã giảm giá và số hoặc mã thành viên;
            và các thông tin khác có thể cần thiết để thuê xe cho bạn, cung cấp
            dịch vụ cho bạn, liên hệ với bạn về các dịch vụ này hoặc xác minh
            danh tính của bạn. Nếu bạn tham gia vào một chương trình bảo hiểm
            thông qua chúng tôi hoặc có liên quan đến một vụ tai nạn hoặc thiệt
            hại tài sản, chúng tôi có thể thu thập thêm thông tin về nhà cung
            cấp bảo hiểm của bạn và/hoặc phạm vi bảo hiểm.
          </li>
          <li>
            Chúng tôi có thể ghi âm hoặc giám sát bất kỳ cuộc gọi nào bạn thực
            hiện cho chúng tôi vì mục đích kiểm soát chất lượng, để thực thi các
            quyền của chúng tôi hoặc vì mục đích đào tạo hoặc tương tự. Chúng
            tôi có thể thu thập ảnh và video trong một số trường hợp, chẳng hạn
            khi bạn chọn chia sẻ chúng với chúng tôi liên quan đến một trong
            những dịch vụ thành viên tùy chọn của chúng tôi hoặc trên mạng xã
            hội hoặc khi chúng tôi nhận được thông báo về vi phạm giao thông có
            kèm theo hình ảnh hoặc video. Các địa điểm cho thuê của chúng tôi
            cũng có thể được trang bị camera và/hoặc hệ thống giám sát video.
          </li>
          <li>
            Khi bạn tải xuống, truy cập hoặc sử dụng trang web hoặc ứng dụng của
            chúng tôi hoặc tương tác với chúng tôi qua email, tin nhắn hoặc các
            hình thức giao tiếp điện tử khác, chúng tôi có thể sử dụng cookie,
            thẻ pixel, đèn hiệu, kịch bản và các công nghệ tương tự để tự động
            thu thập một số thông tin nhất định, bao gồm, ví dụ, địa chỉ IP của
            bạn, địa chỉ MAC, số nhận dạng thiết bị, loại trình duyệt, nhà cung
            cấp dịch vụ Internet (“ISP”), vị trí, cách bạn tương tác với trang
            web và/hoặc ứng dụng của chúng tôi, trang giới thiệu/thoát, hệ điều
            hành, ngày/giờ và dữ liệu nhấp chuột.
          </li>
          <li>
            Chúng tôi cũng thu thập thông tin cá nhân về bạn từ bên thứ ba, bao
            gồm thông tin từ các kênh đặt chỗ du lịch bên thứ ba; nhà cung cấp
            dịch vụ; người được cấp phép; hãng hàng không, khách sạn, câu lạc bộ
            thành viên, công ty thẻ thanh toán, nhà quảng cáo và các đối tác
            khác; từ người sử dụng lao động của bạn; từ dịch vụ mạng xã hội của
            bạn phù hợp với cài đặt chia sẻ thông tin của bạn trên các dịch vụ
            đó; và từ các nguồn bên thứ ba khác được pháp luật cho phép chia sẻ
            thông tin của họ với chúng tôi.
          </li>
          <li>
            Chúng tôi có thể thu thập thông tin về các điều kiện sức khỏe liên
            quan khi cần thiết để cung cấp thiết bị lái xe thích ứng.
          </li>
        </ul>
        <p className="mb-3">
          <b>
            <u>Dữ liệu Xe Kết Nối</u>
          </b>
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Một số phương tiện cho thuê của chúng tôi là xe kết nối. Một chiếc
            xe kết nối là một phương tiện được trang bị các tính năng, hệ thống
            viễn thông, thiết bị trên xe hoặc các dịch vụ và công nghệ tích hợp
            có khả năng thu thập dữ liệu về phương tiện và việc sử dụng nó, bao
            gồm, ví dụ, tình trạng phương tiện, sự cố tai nạn, hiệu suất, dữ
            liệu chẩn đoán, thông tin về quãng đường đi, tăng tốc, phanh, tốc
            độ, mức tiêu thụ nhiên liệu, mức nhiên liệu, áp suất lốp, đồng hồ đo
            quãng đường, vị trí và hướng đi, và các thông tin liên quan đến xe
            khác (gọi chung là <b>“Dữ liệu Xe Kết Nối”</b> hoặc{" "}
            <b>“Dữ liệu Xe”</b>). Thông thường, thông tin này chỉ liên quan đến
            phương tiện và được duy trì dưới dạng thông tin không cá nhân, như
            được mô tả trong phần Thông tin Không Cá nhân bên dưới. Nếu chúng
            tôi liên kết thông tin này với bạn, chúng tôi sẽ coi nó là thông tin
            cá nhân.
          </li>
          <li>
            Việc sử dụng các tính năng và dịch vụ, công nghệ tích hợp trong các
            xe kết nối tuân theo các điều khoản và điều kiện được đăng bởi nhà
            sản xuất phương tiện và/hoặc nhà cung cấp công nghệ, có thể bao gồm
            các hạn chế về hệ thống và dịch vụ, loại trừ bảo hành, giới hạn
            trách nhiệm pháp lý, các điều khoản của nhà cung cấp dịch vụ không
            dây và trách nhiệm của người dùng. Một số hoặc tất cả các tính năng
            này được bật liên tục, ngay cả khi các dịch vụ khác trong phương
            tiện đã tắt. Nếu bạn không chắc chắn liệu phương tiện cho thuê của
            bạn có phải là xe kết nối hay không, vui lòng liên hệ với thành viên
            của đội ngũ tại địa điểm thuê hoặc liên hệ với Quản lý Trạm Thuê.
            Thông tin chi tiết về các tính năng xe kết nối trong các phương tiện
            cho thuê của chúng tôi cũng được nêu trong phần “Chi tiết Phương
            tiện” của Thỏa thuận Thuê. Chúng tôi không thể đảm bảo rằng sẽ có
            sẵn phương tiện không có tính năng xe kết nối vào thời điểm bạn thuê
            xe.
          </li>
          <li>
            Tùy thuộc vào công nghệ được sử dụng trong phương tiện cho thuê của
            bạn, các nhà sản xuất phương tiện và các dịch vụ tích hợp như 'Apple
            CarPlay' hoặc 'OnStar' cũng có thể thu thập Dữ liệu Xe Kết Nối hoặc
            thông tin cá nhân khác từ phương tiện theo chính sách quyền riêng tư
            của họ. Để biết thêm thông tin về việc thu thập thông tin từ bên thứ
            ba này, vui lòng tham khảo phần{" "}
            <Link className="linked text-decoration-none">
              Thu Thập từ Bên Thứ Ba
            </Link>
            .
          </li>
        </ul>
        <p className="mb-3">
          <b>
            <u>Thông tin Vị trí</u>
          </b>
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Chúng tôi có thể thu thập thông tin về vị trí của bạn từ các trang
            web của chúng tôi, thiết bị di động của bạn và các lần bạn đến thăm
            địa điểm của chúng tôi. Bạn có thể{" "}
            <Link className="linked text-decoration-none">chọn không</Link> thu
            thập thông tin về vị trí của mình từ các thiết bị di động bất cứ lúc
            nào bằng cách tắt truyền dữ liệu vị trí và thông báo đẩy trên từng
            thiết bị di động của bạn.
          </li>
          <li>
            Chúng tôi cũng có thể đôi khi liên kết một số Dữ liệu Xe Kết Nối
            liên quan đến vị trí với bạn.
          </li>
        </ul>
        <p className="mb-3">
          <b>
            <u>Thông tin Không Cá nhân</u>
          </b>
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Chúng tôi cũng có thể thu thập hoặc chuyển đổi thông tin để nó được
            duy trì dưới dạng không liên kết với một cá nhân cụ thể, chẳng hạn
            như thông tin về phương tiện không được liên kết với hành khách hoặc
            thông tin tổng hợp, ẩn danh hoặc được ẩn danh khác ("thông tin không
            cá nhân"). Thông báo về Quyền riêng tư này không áp dụng cho thông
            tin không cá nhân mà chúng tôi thu thập, sử dụng và tiết lộ vì nhiều
            mục đích khác nhau. Tuy nhiên, bất kỳ thông tin không cá nhân nào
            được liên kết hoặc kết hợp với thông tin cá nhân, khi được phép theo
            luật, sẽ được coi là thông tin cá nhân và sẽ tuân theo Thông báo về
            Quyền riêng tư này.
          </li>
        </ul>
        <p className="mb-3">
          <b>
            <u>Thông tin Sinh trắc học</u>
          </b>
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            Xác minh Danh tính Sinh trắc học QuickPass: Avis và Budget có thể
            cung cấp cho khách hàng trải nghiệm thuê không tiếp xúc thông qua
            dịch vụ QuickPass của chúng tôi. Chúng tôi dựa vào các nhà cung cấp
            xác minh danh tính bên thứ ba đáng tin cậy để tạo điều kiện xác minh
            danh tính của khách hàng bằng cách sử dụng công nghệ so sánh sinh
            trắc học khuôn mặt để cung cấp dịch vụ QuickPass cho khách hàng.
          </li>
        </ul>
        <p className="mb-3 ms-5">
          Để xác minh danh tính của khách hàng thông qua quy trình này, khách
          hàng được yêu cầu chụp ảnh giấy phép lái xe của họ và chụp ảnh selfie
          trực tiếp của khuôn mặt họ. Các nhà cung cấp xác minh danh tính của
          chúng tôi sau đó sử dụng sự kết hợp của các công cụ học máy và các
          thuật toán thống kê để xác nhận tính xác thực của giấy phép lái xe và
          thực hiện so sánh sinh trắc học khuôn mặt để xác định xem các khuôn
          mặt trong giấy phép lái xe và ảnh selfie có thuộc về cùng một người
          hay không. Thông qua quá trình này, nhà cung cấp xác minh sẽ thường
          tạo ra "điểm tin cậy" đại diện cho mức độ tự tin rằng hình ảnh của các
          cá nhân khớp nhau, mà chúng tôi hoặc nhà cung cấp liên quan có thể sử
          dụng để xác định mức độ tự tin rằng cá nhân gửi ảnh selfie là cùng một
          người với cá nhân trên giấy phép lái xe.
        </p>
        <p className="mb-3 ms-5">
          Chúng tôi không nhận bất kỳ thông tin sinh trắc học khuôn mặt nào được
          tạo ra từ các hình ảnh, và chúng tôi yêu cầu hợp đồng với các nhà cung
          cấp xác minh danh tính tích hợp của chúng tôi, những người xử lý thông
          tin này thay mặt cho chúng tôi, phải hủy ngay hình ảnh và bất kỳ thông
          tin sinh trắc học nào theo lịch trình lưu trữ dữ liệu. Chúng tôi không
          sử dụng, tiết lộ hoặc giữ lại thông tin sinh trắc học khuôn mặt cho
          bất kỳ mục đích thương mại nào khác.
        </p>
        <p className="mb-3 ms-5">
          Trong một số trường hợp, chúng tôi có thể dựa vào một nhà cung cấp xác
          minh danh tính bên thứ ba độc lập yêu cầu khách hàng của chúng tôi tạo
          và duy trì một tài khoản trực tiếp với nhà cung cấp trên trang web
          hoặc dịch vụ của nhà cung cấp. Nếu khách hàng tạo tài khoản với một
          trong các nhà cung cấp xác minh danh tính bên thứ ba của chúng tôi,
          việc xử lý thông tin cá nhân, bao gồm thông tin sinh trắc học, của nhà
          cung cấp sẽ tuân theo các điều khoản và chính sách quyền riêng tư của
          nhà cung cấp đó (sẽ được trình bày cho khách hàng trong quá trình tạo
          tài khoản của nhà cung cấp). Trong trường hợp này, nhà cung cấp có thể
          giữ lại bản sao ảnh selfie và/hoặc giấy phép lái xe của khách hàng
          (cũng như hình học sinh trắc học khuôn mặt được trích xuất từ các hình
          ảnh) cho các mục đích và thời gian được mô tả trong chính sách quyền
          riêng tư của nhà cung cấp đó, bao gồm cả việc hợp lý hóa các lần xác
          minh danh tính trong tương lai bằng nền tảng xác minh danh tính của
          nhà cung cấp đó. Vui lòng xem kỹ chính sách quyền riêng tư của nhà
          cung cấp để hiểu rõ cách thông tin cá nhân của bạn, bao gồm thông tin
          sinh trắc học, sẽ được sử dụng, tiết lộ và giữ lại bởi nhà cung cấp
          xác minh danh tính.
        </p>
        <ul className="similarCarOpt ps-5 mb-5">
          <li>
            <u>Xác thực Ứng dụng Di động:</u> Một số ứng dụng di động của chúng
            tôi yêu cầu bạn đăng nhập để truy cập tài khoản hoặc tương tác với
            xe kết nối của bạn. Chúng tôi có thể cung cấp cho bạn khả năng đăng
            nhập hoặc xác thực bản thân bằng công nghệ nhận dạng khuôn mặt, dấu
            vân tay hoặc nhận dạng sinh trắc học khác có sẵn trên thiết bị di
            động của bạn. Nếu bạn chọn sử dụng các tính năng đăng nhập này,
            thông tin về hình học khuôn mặt, dấu vân tay hoặc thông tin sinh
            trắc học khác của bạn sẽ được thiết bị di động của bạn thu thập cho
            mục đích xác thực. Chúng tôi không lưu trữ hoặc truy cập thông tin
            sinh trắc học này. Thay vào đó, thiết bị di động của bạn sẽ thực
            hiện quy trình xác thực sinh trắc học và chỉ cho chúng tôi biết liệu
            quá trình xác thực có thành công hay không. Nếu xác thực thành công,
            bạn sẽ có thể truy cập ứng dụng di động hoặc tính năng liên quan mà
            không cần cung cấp thông tin đăng nhập riêng biệt.
          </li>
        </ul>
      </div>

      <div className="pt-5" id="how_used_information">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>2. CÁCH SỬ DỤNG THÔNG TIN</b>
        </p>
        <p className="mb-3">
          <b>
            <u>Thông Tin Cá Nhân</u>
          </b>
        </p>
        <p className="mb-3">Chúng tôi sử dụng thông tin cá nhân của bạn để:</p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            thực hiện hoặc đáp ứng lý do bạn cung cấp thông tin, chẳng hạn như
            tạo tài khoản của bạn, xử lý giao dịch của bạn hoặc trả lời yêu cầu
            của bạn;
          </li>
          <li>
            vận hành, duy trì và cung cấp cho bạn các trang web, ứng dụng, sản
            phẩm và dịch vụ của chúng tôi;
          </li>
          <li>
            nhận dạng bạn và nhớ thông tin của bạn khi bạn quay lại trang web và
            dịch vụ của chúng tôi;
          </li>
          <li>
            xác minh danh tính của bạn và xác nhận tính xác thực của các tài
            liệu nhận dạng của bạn;
          </li>
          <li>
            cung cấp cho bạn các dịch vụ hoặc chức năng bổ sung (ví dụ: tạo điều
            kiện cho thuê không tiếp xúc, cung cấp tính năng khóa/mở khóa từ xa,
            tắt động cơ từ xa/hủy kích hoạt đánh lửa và tự động nhận vị trí xe,
            đồng hồ đo quãng đường, mức nhiên liệu và các thông tin khác liên
            quan đến việc thuê của bạn);
          </li>
          <li>
            quản lý việc thuê của bạn, quản lý hồ sơ thuê của bạn và tính phí
            (ví dụ: tự động ghi lại thời gian bắt đầu thuê của bạn, bất kỳ lần
            trao đổi hoặc nâng cấp nào, và việc sử dụng nhiên liệu hoặc quãng
            đường và các khoản phí);
          </li>
          <li>
            giao tiếp thông tin liên quan đến đặt chỗ hoặc thuê xe của bạn. Các
            liên lạc này là giao dịch hoặc liên quan đến dịch vụ và sẽ được gửi
            qua email, tin nhắn văn bản, thông báo đẩy hoặc cuộc gọi điện thoại;
          </li>
          <li>
            chẩn đoán các vấn đề, phân tích xu hướng và đánh giá hiệu suất của
            chúng tôi;
          </li>
          <li>cung cấp hỗ trợ bên đường;</li>
          <li>cung cấp cho bạn các giảm giá;</li>
          <li>cải thiện hoạt động và dịch vụ của chúng tôi;</li>
          <li>hiểu rõ hơn sở thích của khách hàng của chúng tôi;</li>
          <li>hiểu rõ hơn cách phương tiện của chúng tôi đang được sử dụng;</li>
          <li>
            điều chỉnh và cá nhân hóa dịch vụ, chương trình khuyến mãi và ưu đãi
            của chúng tôi cho khách hàng, bao gồm các ưu đãi và quảng cáo được
            nhắm mục tiêu phù hợp với sở thích của bạn thông qua các trang web,
            ứng dụng, trang web của bên thứ ba hoặc trên các thiết bị khác mà
            bạn có thể sử dụng;
          </li>
          <li>
            kiểm tra, đào tạo, nghiên cứu, phân tích và phát triển các sản phẩm
            và dịch vụ của chúng tôi;
          </li>
          <li>duy trì đội xe của chúng tôi;</li>
          <li>
            thu hồi các phương tiện cho thuê bị mất, bị đánh cắp hoặc bị kéo đi;
          </li>
          <li>
            tương tác với bạn trực tiếp, thông qua các dịch vụ của chúng tôi,
            qua email, tin nhắn văn bản, thông báo đẩy, cuộc gọi điện thoại hoặc
            trên các nền tảng của bên thứ ba (chẳng hạn như các trang mạng xã
            hội);
          </li>
          <li>
            cung cấp cho bạn, và cải thiện, các chiến dịch tiếp thị phù hợp (bao
            gồm qua email, thư trực tiếp, điện thoại hoặc tin nhắn văn bản) hoặc
            các ưu đãi từ chúng tôi và đối tác của chúng tôi, và thông tin cho
            các chiến dịch quảng cáo và tiếp thị của chúng tôi;
          </li>
          <li>
            chạy các cuộc thi, chương trình xổ số hoặc khuyến mãi của chúng tôi;
          </li>
          <li>kích hoạt các ứng dụng và dịch vụ của xe từ bên thứ ba;</li>
          <li>cải thiện an toàn giao thông và giảm ùn tắc;</li>
          <li>
            đánh giá, đàm phán hoặc thực hiện một vụ sáp nhập, phân chia, tái
            cấu trúc, tổ chức lại, giải thể hoặc bán hoặc chuyển nhượng một phần
            hoặc toàn bộ tài sản của ABG, dù là hoạt động tiếp tục hay như một
            phần của phá sản, thanh lý hoặc các thủ tục tương tự, trong đó thông
            tin cá nhân do ABG nắm giữ về người tiêu dùng nằm trong số tài sản
            được chuyển nhượng hoặc có liên quan đến việc đánh giá, đàm phán
            hoặc thực hiện giao dịch.
          </li>
          <li>
            trả lời các yêu cầu pháp lý từ cơ quan thực thi pháp luật, cơ quan
            quản lý hoặc bên thứ ba khác;
          </li>
          <li>
            duy trì an toàn, bảo mật và toàn vẹn của tài sản, dịch vụ hoặc tài
            sản khác của chúng tôi hoặc của người khác, bao gồm cả các phương
            tiện, tài sản công nghệ và doanh nghiệp của ABG;
          </li>
          <li>
            bảo vệ, bảo vệ hoặc thực thi quyền của chúng tôi hoặc các điều khoản
            dịch vụ áp dụng;
          </li>
          <li>ngăn chặn gian lận hoặc tái diễn gian lận;</li>
          <li>hỗ trợ trong trường hợp khẩn cấp; và</li>
          <li>tuân thủ các luật hiện hành.</li>
        </ul>
        <p className="mb-3">
          Chúng tôi sử dụng cookie, thẻ pixel, đèn hiệu, kịch bản và các công
          nghệ tương tự để điều chỉnh và tối ưu hóa nội dung theo sở thích của
          bạn, cung cấp cho bạn các chức năng hữu ích, nhớ các lần thuê trước và
          sở thích du lịch của bạn, giúp chúng tôi làm cho trang web, ứng dụng,
          sản phẩm và dịch vụ của chúng tôi thuận tiện và hiệu quả hơn, cải
          thiện dịch vụ của chúng tôi, giúp chúng tôi phân tích các xu hướng và
          mẫu sử dụng, và điều chỉnh các quảng cáo trực tuyến của chúng tôi và
          đối tác cho bạn, và chúng tôi có thể sử dụng các nhà cung cấp bên thứ
          ba để hỗ trợ những nỗ lực này. Các nhà cung cấp và đối tác bên thứ ba
          của chúng tôi có thể sử dụng các công nghệ này để thu thập thông tin
          về các hoạt động trực tuyến của bạn theo thời gian và trên các trang
          web hoặc dịch vụ trực tuyến khác khi bạn sử dụng hoặc tương tác với
          trang web và các dịch vụ trực tuyến của chúng tôi.
        </p>
        <p className="mb-3">
          Chúng tôi cũng có thể sử dụng các công cụ của bên thứ ba để theo dõi
          thông tin trải nghiệm người dùng trên Trang web của chúng tôi. Những
          công cụ này tự động ghi lại cách bạn tương tác với Trang web của chúng
          tôi, bao gồm các lần nhấp chuột và di chuyển chuột, cuộn trang và bất
          kỳ văn bản nào được nhập vào các biểu mẫu trên trang web. Chúng tôi sử
          dụng thông tin này cho phân tích trang web, tối ưu hóa và cải thiện
          khả năng sử dụng trang web. Chúng tôi cũng sử dụng phần mềm phân tích,
          chẳng hạn như Google Analytics và các phần mềm khác, để giúp chúng tôi
          hiểu rõ hơn cách trang web và ứng dụng của chúng tôi hoạt động trên
          thiết bị của bạn và cho mục đích nghiên cứu và phát triển. Chúng tôi
          cũng có thể sử dụng một số dạng quảng cáo hiển thị và các tính năng
          nâng cao khác thông qua Google Analytics, chẳng hạn như Tiếp thị lại
          với Google Analytics, sử dụng cookie của bên thứ ba (chẳng hạn như
          cookie quảng cáo DoubleClick) hoặc các cookie của bên thứ ba khác cùng
          nhau để thông báo, tối ưu hóa và hiển thị quảng cáo dựa trên các lần
          truy cập trước của bạn vào các dịch vụ. Để biết thông tin về cách
          Google Analytics thu thập và xử lý dữ liệu, cũng như cách bạn có thể
          kiểm soát thông tin được gửi đến Google, vui lòng xem trang web của
          Google "Cách Google sử dụng dữ liệu khi bạn sử dụng các trang web hoặc
          ứng dụng của đối tác của chúng tôi" tại
          <Link className="linked text-decoration-none">
            www.google.com/policies/privacy/partners/
          </Link>{" "}
          và bạn có thể tìm hiểu về các lựa chọn từ chối hiện có của Google
          Analytics, bao gồm Tiện ích Trình duyệt của Google Analytics, tại{" "}
          <Link className="linked text-decoration-none">
            https://tools.google.com/dlpage/gaoptout/
          </Link>
          .
        </p>
        <p className="mb-3">
          Chúng tôi có thể sử dụng các nền tảng của bên thứ ba để giúp chúng tôi
          quảng bá các quảng cáo dựa trên sở thích đến bạn hoặc để cung cấp tích
          hợp với các dịch vụ của bên thứ ba (chẳng hạn như nút Thích của
          Facebook). Các nhà cung cấp này sử dụng cookie hoặc các công nghệ theo
          dõi tương tự để thu thập thông tin về việc bạn sử dụng các trang web
          của chúng tôi, bao gồm các ứng dụng di động của chúng tôi, theo thời
          gian để họ có thể cung cấp các dịch vụ tích hợp hoặc hiển thị các
          quảng cáo trên các dịch vụ của chúng tôi, trên các trang web, ứng dụng
          hoặc dịch vụ khác mà bạn có thể sử dụng, và trên các thiết bị khác mà
          bạn có thể sử dụng. Chúng tôi có thể chia sẻ một mã nhận dạng tài
          khoản chung (chẳng hạn như địa chỉ email được băm hoặc ID người dùng)
          với các đối tác quảng cáo bên thứ ba của chúng tôi để giúp nhận dạng
          bạn trên các thiết bị. Chúng tôi và các đối tác bên thứ ba của chúng
          tôi sử dụng thông tin này để tạo điều kiện cho các dịch vụ tích hợp
          hoặc làm cho các quảng cáo bạn thấy trực tuyến liên quan hơn đến sở
          thích của bạn, cũng như cung cấp các dịch vụ liên quan đến quảng cáo
          như báo cáo, phân bổ, phân tích và nghiên cứu thị trường. Bạn có các
          tùy chọn khi nói đến cookie và các công cụ theo dõi web khác.
        </p>
        <p className="mb-3">
          Bạn có thể đặt trình duyệt hoặc thiết bị của mình từ chối tất cả
          cookie hoặc chỉ ra khi nào cookie được gửi. Tuy nhiên, một số tính
          năng hoặc dịch vụ có thể không hoạt động bình thường nếu không có
          cookie.
        </p>
        <p className="mb-3">
          Chúng tôi sử dụng thông tin về vị trí của bạn (bao gồm, đôi khi, bằng
          cách liên kết thông tin cá nhân với thông tin vị trí của phương tiện
          cho thuê mà bạn đang sử dụng) chủ yếu để định vị địa điểm cho thuê gần
          nhất của chúng tôi, gửi cho bạn thông báo dựa trên vị trí (tức là, các
          ưu đãi đặc biệt gần đó), thông báo về việc đến và trả xe, vị trí
          phương tiện và khả năng nâng cấp. Chúng tôi cũng sử dụng thông tin về
          vị trí của bạn cho các mục đích tương tự như cách chúng tôi sử dụng
          thông tin cá nhân của bạn, bao gồm cung cấp và cải thiện các chiến
          dịch tiếp thị hoặc ưu đãi từ chúng tôi và đối tác của chúng tôi.
        </p>
        <p className="mb-3">
          <b>
            <u>Lưu Trữ Dữ Liệu</u>
          </b>
        </p>
        <p className="mb-3">
          Chúng tôi có thể giữ lại thông tin của bạn miễn là bạn có tài khoản
          với Avis, Budget, Payless hoặc Budget Truck Rental, khi cần thiết để
          cung cấp cho bạn các dịch vụ của chúng tôi hoặc khi cần thiết cho mục
          đích xử lý mà thông tin ban đầu được thu thập và bất kỳ mục đích kinh
          doanh hợp pháp nào khác, bao gồm cho các mục đích pháp lý, lưu trữ, kế
          toán và/hoặc kiểm toán.
        </p>
        <p className="mb-3">
          Để xác định thời gian lưu trữ thông tin cá nhân phù hợp, chúng tôi xem
          xét số lượng, tính chất và mức độ nhạy cảm của thông tin cá nhân, nguy
          cơ tiềm ẩn gây hại do sử dụng hoặc tiết lộ thông tin cá nhân trái phép
          và liệu chúng tôi có thể đạt được mục tiêu của mình bằng các phương
          tiện khác hay không, cũng như các nghĩa vụ pháp lý, quy định, thuế, kế
          toán và các nghĩa vụ áp dụng khác của chúng tôi.
        </p>
        <p className="mb-3">
          Khi việc lưu giữ thông tin cá nhân không còn cần thiết cho các mục
          đích được nêu ở trên, chúng tôi sẽ xóa hoặc ẩn danh thông tin cá nhân
          hoặc, nếu điều này không thể thực hiện được (ví dụ: vì thông tin cá
          nhân đã được lưu trữ trong các bản sao lưu), thì chúng tôi sẽ không xử
          lý thông tin cá nhân cho đến khi có thể xóa hoặc ẩn danh.
        </p>
        <p className="mb-3">
          <b>
            <u>Các Tùy Chọn Từ Chối Của Bạn</u>
          </b>
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            <u>Nếu bạn muốn từ chối nhận email tiếp thị:</u> Nhấp vào “hủy đăng
            ký” ở cuối email chúng tôi đã gửi cho bạn, hoặc đăng nhập vào tài
            khoản của bạn và cập nhật hồ sơ của bạn, hoặc liên hệ với bất kỳ đại
            diện dịch vụ khách hàng nào.
          </li>
          <li>
            <u>Nếu bạn muốn từ chối nhận tin nhắn tiếp thị:</u> Đối với Avis,
            nhắn tin từ “STOP” để phản hồi tin nhắn bạn nhận được. Đối với
            Budget, nhắn tin từ “STOP” để phản hồi tin nhắn bạn nhận được. Để
            biết thêm thông tin về các liên lạc tin nhắn văn bản của chúng tôi
            và các tùy chọn từ chối của bạn, cũng như các điều khoản bổ sung,
            vui lòng xem Chính sách SMS của chúng tôi tại <Link>đây</Link>.
          </li>
          <li>
            <u>
              Nếu bạn muốn từ chối nhận các quảng cáo trực tuyến tùy chỉnh từ
              chúng tôi và đối tác của chúng tôi:
            </u>{" "}
            Đặt trình duyệt hoặc thiết bị của bạn từ chối tất cả cookie hoặc chỉ
            ra khi nào cookie được gửi. Kiểm tra trang web trình duyệt cụ thể
            của bạn để tìm hiểu cách điều chỉnh cài đặt cookie của bạn. Lưu ý
            rằng nếu bạn từ chối quảng cáo tùy chỉnh, bạn sẽ tiếp tục nhận được
            quảng cáo chung. Để tìm hiểu về quảng cáo trực tuyến và các tùy chọn
            của bạn, hãy nhấp vào đây:
            <Link className="linked text-decoration-none">
              http://www.networkadvertising.org/choices/
            </Link>{" "}
            hoặc{" "}
            <Link className="linked text-decoration-none">
              http://www.aboutads.info/choices/
            </Link>{" "}
            (nếu bạn là khách truy cập tại Hoa Kỳ) hoặc{" "}
            <Link className="linked text-decoration-none">
              http://www.networkadvertising.org/choices/
            </Link>{" "}
            hoặc{" "}
            <Link className="linked text-decoration-none">
              http://youradchoices.ca/choices/
            </Link>{" "}
            (nếu bạn là khách truy cập tại Canada). Bạn có thể kiểm soát sở
            thích quảng cáo của mình hoặc từ chối một số sản phẩm quảng cáo của
            Google bằng cách truy cập Trình quản lý Tùy chọn Quảng cáo của
            Google, hiện có tại{" "}
            <Link className="linked text-decoration-none">
              https://google.com/ads/preferences
            </Link>
            . Một số công ty ABG hợp tác với công ty quảng cáo (Rokt). Trong
            phạm vi các công ty ABG đã hợp tác với Rokt, bạn cũng có thể từ chối
            một số ưu đãi quảng cáo do đối tác quảng cáo của chúng tôi cung cấp,
            Rokt, bằng cách làm theo hướng dẫn được nêu trong Chính sách Quyền
            Riêng tư của Rokt, hiện có tại{" "}
            <Link className="linked text-decoration-none">
              https://rokt.com/privacy-policy/
            </Link>
            . Bạn phải thực hiện từ chối trên từng trình duyệt hoặc thiết bị bạn
            sử dụng. Các tùy chọn từ chối này có thể không ảnh hưởng đến quảng
            cáo trực tuyến không dựa trên cookie. Một số tùy chọn từ chối này có
            thể không hiệu quả trừ khi trình duyệt của bạn được đặt để chấp nhận
            cookie. Nếu bạn xóa cookie, thay đổi cài đặt trình duyệt, chuyển
            trình duyệt hoặc máy tính, hoặc sử dụng hệ điều hành khác, bạn có
            thể cần phải từ chối lại. Xin lưu ý rằng khi bạn “từ chối” nhận các
            quảng cáo dựa trên sở thích, điều này không có nghĩa là bạn sẽ không
            còn thấy quảng cáo từ chúng tôi hoặc trên các dịch vụ của chúng tôi.
            Ngoài ra, các bên thứ ba vẫn có thể sử dụng cookie để thu thập thông
            tin về việc bạn sử dụng dịch vụ của chúng tôi, bao gồm cho mục đích
            phân tích và ngăn chặn gian lận cũng như bất kỳ mục đích nào khác
            không liên quan đến quảng cáo.
          </li>
          <li>
            <u>
              Bạn có thể ngừng chia sẻ thông tin vị trí của thiết bị di động của
              mình thông qua Ứng dụng ABG áp dụng bằng cách tắt dịch vụ vị trí
              hoặc bằng cách gỡ cài đặt Ứng dụng. Nếu bạn muốn gỡ cài đặt ứng
              dụng ABG:
            </u>{" "}
            Sử dụng quy trình gỡ cài đặt tiêu chuẩn có thể có sẵn như một phần
            của thiết bị di động của bạn hoặc qua chợ ứng dụng hoặc mạng mà bạn
            đã tải xuống ứng dụng.
          </li>
        </ul>
        <p className="mb-5">
          Bạn cũng có thể giới hạn quảng cáo dựa trên sở thích trên thiết bị di
          động của mình thông qua cài đặt trên thiết bị di động của bạn bằng
          cách chọn “giới hạn theo dõi quảng cáo” (iOS) hoặc “từ chối quảng cáo
          dựa trên sở thích” (Android). Bạn cũng có thể từ chối một số - nhưng
          không phải tất cả - quảng cáo dựa trên sở thích được cung cấp bởi các
          mạng quảng cáo di động bằng cách truy cập{" "}
          <Link className="linked text-decoration-none">
            http://youradchoices.com/appchoices
          </Link>{" "}
          và tải xuống ứng dụng AppChoices di động. Bạn có thể giới hạn việc sử
          dụng dữ liệu vị trí cho các mục đích quảng cáo bằng cách điều chỉnh
          cài đặt dịch vụ vị trí trên thiết bị di động của bạn.
        </p>
      </div>

      <div className="pt-5" id="how_shared_information">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>3. CÁCH CHIA SẺ THÔNG TIN</b>
        </p>
        <p className="mb-3">
          Để tạo điều kiện thuận lợi cho việc sử dụng thông tin cá nhân được xác
          định trong Thông báo Quyền riêng tư này, chúng tôi có thể chia sẻ
          thông tin cá nhân của bạn với nhiều bên thứ ba, bao gồm các đối tác
          dịch vụ và đối tác kinh doanh của chúng tôi.
        </p>
        <p className="mb-3">
          Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các nhà cung
          cấp dịch vụ và đối tác bên thứ ba của chúng tôi để giúp chúng tôi với
          các dự án tiếp thị và quảng cáo của mình, chẳng hạn như quản lý các
          trang mạng xã hội của chúng tôi, tổ chức các cuộc thi, xổ số và các
          chương trình khuyến mãi khác, hoặc tuân thủ chỉ dẫn của bạn hoặc bất
          kỳ sự đồng ý bổ sung nào mà bạn đã cung cấp cho chúng tôi.
        </p>
        <p className="mb-3">
          Ngoài ra, Budget Truck có thể chia sẻ thông tin cá nhân của bạn với
          các bên thứ ba sử dụng thông tin này để cung cấp cho bạn thông tin và
          tin nhắn tiếp thị về các sản phẩm hoặc dịch vụ mà bạn có thể quan tâm.
          Các bên này có thể sử dụng thông tin của bạn phù hợp với chính sách
          quyền riêng tư và điều khoản riêng của họ.
        </p>
        <p className="mb-3">
          Nếu bạn đang thuê với một tài khoản doanh nghiệp, thành viên hoặc tài
          khoản thương mại tương tự khác, chúng tôi có thể chia sẻ thông tin cá
          nhân với tổ chức quản lý tài khoản vì mục đích riêng của họ. Chúng tôi
          khuyến nghị bạn xem lại chính sách quyền riêng tư của họ để tìm hiểu
          thêm về thực tiễn quyền riêng tư của họ.
        </p>
        <p className="mb-3">
          Chúng tôi và các đối tác kinh doanh và quảng cáo của chúng tôi gửi cho
          bạn thông tin về các sản phẩm và dịch vụ mà bạn có thể thích. Ví dụ:
          chúng tôi có thể giúp sắp xếp việc thuê khách sạn, chuyến bay hoặc các
          dịch vụ khác từ các đối tác kinh doanh của chúng tôi và chia sẻ thông
          tin cá nhân với các đối tác kinh doanh của chúng tôi để cung cấp các
          dịch vụ đó và tạo điều kiện cho việc đặt chỗ của bạn. Chúng tôi cũng
          có thể làm việc với các bên thứ ba, chẳng hạn như đối tác hàng không
          và thẻ thanh toán của chúng tôi, để cho phép chúng tôi và đối tác của
          chúng tôi cung cấp quảng cáo cho khách hàng chung của chúng tôi. Các
          đối tác của chúng tôi có thể cung cấp các ưu đãi phù hợp hơn cho bạn
          dựa trên thông tin mà chúng tôi chia sẻ về các lần thuê xe của bạn,
          cũng như thông tin trong hồ sơ chương trình khách hàng thân thiết của
          bạn. Ngoài ra, chúng tôi có thể cho phép các đối tác bên thứ ba nhận
          dạng bạn khi bạn truy cập trang web hoặc ứng dụng của đối tác đó, hoặc
          nhận dạng bạn là một trong những khách hàng của họ khi bạn truy cập
          trang web hoặc ứng dụng của chúng tôi để họ có thể cung cấp các ưu đãi
          phù hợp hơn cho bạn. Bạn có thể{" "}
          <Link className="linked text-decoration-none">chọn không</Link> nhận
          các email và tin nhắn tiếp thị và quảng cáo từ chúng tôi và các đối
          tác kinh doanh và quảng cáo của chúng tôi. Xin lưu ý rằng việc thu hồi
          sự đồng ý nhận email và tin nhắn tiếp thị không ảnh hưởng đến các liên
          lạc không quảng cáo hoặc không tiếp thị của chúng tôi, bao gồm, ví dụ,
          các liên lạc về trạng thái và hoạt động của tài khoản của bạn, các
          liên lạc thanh toán, phản hồi yêu cầu của bạn, yêu cầu phản hồi sau
          dịch vụ hoặc khảo sát, xác nhận và các liên lạc tương tự.
        </p>
        <p className="mb-3">
          Chúng tôi có thể chia sẻ thông tin sử dụng trang web, bao gồm các tìm
          kiếm, giao dịch và thông tin hồ sơ (nhưng không phải tên của bạn) với
          các đối tác của chúng tôi nhằm mục đích làm cho các quảng cáo trực
          tuyến trên các trang web của chúng tôi và các trang web khác hiển thị
          quảng cáo của chúng tôi phù hợp hơn.
        </p>
        <p className="mb-3">
          Chúng tôi cũng có thể cho phép bạn chia sẻ một số nội dung trực tuyến
          của chúng tôi trên các trang web mạng xã hội thông qua các liên kết
          chia sẻ và biểu tượng được lưu trữ. Chúng tôi không theo dõi hoặc lưu
          trữ bản ghi mỗi khi bên thứ ba chia sẻ nội dung trực tuyến của chúng
          tôi. Nếu bạn chọn tham gia các hoạt động đăng hoặc chia sẻ, bạn nên
          nhớ rằng nó có thể được công khai thông qua trang web của ABG, trong
          các nguồn cấp dữ liệu RSS, API (giao diện chương trình ứng dụng) và
          cũng có thể được cung cấp cho các trang web khác. Do đó, các hoạt động
          công khai của bạn có thể xuất hiện trên các trang web, blog hoặc nguồn
          cấp dữ liệu của bên thứ ba. ABG không chịu trách nhiệm về thông tin có
          thể được chia sẻ công khai. Trang web của chúng tôi có thể bao gồm các
          tính năng mạng xã hội, chẳng hạn như nút Like của Facebook. Các tính
          năng này có thể thu thập địa chỉ IP của bạn, trang bạn đang truy cập
          trên trang web của chúng tôi và có thể đặt cookie để tính năng nút
          Like hoạt động đúng cách. Bất kỳ tính năng mạng xã hội nào cũng được
          điều chỉnh bởi chính sách quyền riêng tư của trang web cung cấp tính
          năng này. Chúng tôi khuyến khích bạn đọc các chính sách quyền riêng tư
          của bất kỳ trang web nào bạn truy cập.
        </p>
        <p className="mb-3">
          Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các cơ quan
          thực thi pháp luật và cơ quan quản lý hoặc các bên thứ ba khác khi
          được yêu cầu hoặc cho phép bởi pháp luật, bao gồm cho các mục đích:
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            trả lời trát đòi hầu tòa, lệnh của tòa án hoặc các quy trình pháp lý
            khác;
          </li>
          <li>bảo vệ, bảo vệ hoặc thực thi các quyền của chúng tôi;</li>
          <li>hỗ trợ trong trường hợp khẩn cấp; và</li>
          <li>tuân thủ luật pháp hiện hành.</li>
        </ul>
        <p className="mb-3">
          Chúng tôi cũng có thể chia sẻ thông tin cá nhân với các bên thứ ba
          trong trường hợp sáp nhập hoặc mua lại thực tế hoặc tiềm năng, hợp
          nhất, thay đổi quyền kiểm soát, chuyển nhượng tài sản, phá sản, tái tổ
          chức hoặc thanh lý. Trong phạm vi luật pháp địa phương yêu cầu, chúng
          tôi sẽ cung cấp cho bạn thông báo trên trang web của chúng tôi hoặc
          qua email về ý định chuyển thông tin cá nhân cho bên thứ ba vì mục
          đích này.
        </p>
        <p className="mb-3">
          <b>
            <u>Thông Tin Vị Trí Cá Nhân.</u>
          </b>
        </p>
        <p className="mb-3">
          Chúng tôi chỉ chia sẻ thông tin về vị trí của bạn với:
        </p>
        <ul className="similarCarOpt ps-5 mb-3">
          <li>
            các nhà cung cấp dịch vụ bên thứ ba của chúng tôi để giúp cung cấp
            các dịch vụ của chúng tôi hoặc các dịch vụ của bên thứ ba mà bạn đã
            yêu cầu;
          </li>
          <li>
            các bên thứ ba mà bạn đã cung cấp sự đồng ý, bao gồm, ví dụ, với các
            đối tác cung cấp tiếp thị dựa trên vị trí; hoặc
          </li>
          <li>
            các cơ quan thực thi pháp luật và cơ quan quản lý hoặc các bên thứ
            ba khác khi được yêu cầu hoặc cho phép bởi pháp luật, bao gồm cho
            các mục đích:
          </li>
        </ul>
        <ul className="ps-4 pt-4 mb-5 privacylist">
          <li>
            trả lời trát đòi hầu tòa, lệnh của tòa án hoặc các quy trình pháp lý
            khác;
          </li>
          <li>bảo vệ, bảo vệ hoặc thực thi các quyền của chúng tôi;</li>
          <li>
            định vị phương tiện bị trễ hạn hoặc nghi ngờ bị đánh cắp hoặc bị mất
          </li>
          <li>hỗ trợ trong trường hợp khẩn cấp; và</li>
          <li>tuân thủ luật pháp hiện hành.</li>
        </ul>
      </div>

      <div className="pt-5" id="how_protected_information">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>4. CÁCH THÔNG TIN ĐƯỢC BẢO VỆ</b>
        </p>
        <p className="mb-5">
          Bảo mật thông tin cá nhân rất quan trọng đối với chúng tôi. Chúng tôi
          thực hiện các bước hợp lý nhằm bảo vệ thông tin cá nhân khỏi việc sử
          dụng, truy cập, tiết lộ, thay đổi, phá hủy hoặc mất mát trái phép. Bạn
          chịu trách nhiệm bảo vệ tài khoản của mình. Không chia sẻ mật mã, số
          thành viên và mã PIN của bạn với bất kỳ ai. Xin lưu ý rằng các tin tặc
          và kẻ lừa đảo có thể giả mạo chúng tôi hoặc trang web của chúng tôi.
          Để biết thêm thông tin về lừa đảo qua mạng (phishing), vui lòng truy
          cập https://www.consumer.ftc.gov/articles/0003-phishing. Nếu bạn nghi
          ngờ có việc sử dụng trái phép hoặc vi phạm bảo mật thông tin của bạn,
          bạn phải thông báo cho{" "}
          <Link className="linked text-decoration-none">
            dịch vụ khách hàng
          </Link>{" "}
          của chúng tôi càng sớm càng tốt! Chúng tôi không chịu trách nhiệm hoặc
          trách nhiệm pháp lý cho bất kỳ tổn thất hoặc thiệt hại nào nếu mật mã
          tài khoản, số thành viên và mã PIN của bạn bị đánh cắp hoặc sử dụng
          không đúng cách.
        </p>
      </div>
      <div className="pt-5" id="children_privacy">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>5. QUYỀN RIÊNG TƯ CỦA TRẺ EM</b>
        </p>
        <p className="mb-5">
          Các trang web, Ứng dụng, sản phẩm và dịch vụ của chúng tôi không dành
          cho trẻ em dưới 13 tuổi. Chúng tôi không cố ý thu thập hoặc yêu cầu
          thông tin cá nhân từ trẻ em dưới 13 tuổi. Nếu bạn dưới 13 tuổi, vui
          lòng không sử dụng trang web hoặc dịch vụ trực tuyến của chúng tôi
          hoặc cung cấp cho chúng tôi bất kỳ thông tin cá nhân nào, dù trực tiếp
          hay bằng bất kỳ phương tiện nào khác. Nếu bạn cho rằng chúng tôi đã
          nhận được thông tin cá nhân từ một đứa trẻ dưới 13 tuổi, vui lòng liên
          hệ với chúng tôi ngay lập tức. Nếu chúng tôi phát hiện rằng bất kỳ
          thông tin cá nhân nào chúng tôi đã thu thập được cung cấp bởi một đứa
          trẻ dưới 13 tuổi, chúng tôi sẽ nhanh chóng xóa thông tin cá nhân đó.
        </p>
      </div>

      <div className="pt-5" id="third_party_collection">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>6. THU THẬP TỪ BÊN THỨ BA</b>
        </p>
        <p className="mb-3">
          Các trang web và Ứng dụng của chúng tôi có thể bao gồm các liên kết
          đến và từ các trang web, plug-in và ứng dụng của bên thứ ba. Thông báo
          Quyền Riêng tư này không áp dụng cho các dịch vụ của bên thứ ba đó, và
          chúng tôi không kiểm soát cách thông tin cá nhân được thu thập hoặc sử
          dụng trên các dịch vụ của bên thứ ba đó, vì vậy vui lòng xem lại chính
          sách quyền riêng tư của bất kỳ dịch vụ bên thứ ba nào bạn truy cập.
        </p>
        <p className="mb-3">
          Nhiều địa điểm hoặc nhượng quyền thương hiệu ABG được sở hữu và điều
          hành độc lập. Thông báo Quyền Riêng tư này không áp dụng cho việc xử
          lý thông tin cá nhân của bạn tại các địa điểm hoặc nhượng quyền độc
          lập này. Nhấp{" "}
          <Link className="linked text-decoration-none">vào đây</Link> để tìm
          hiểu thêm về các địa điểm hoặc nhượng quyền độc lập.
        </p>
        <p className="mb-5">
          Tùy thuộc vào công nghệ được sử dụng trong phương tiện cho thuê của
          bạn, nhà sản xuất phương tiện và các dịch vụ tích hợp, chẳng hạn như
          OnStar, Uconnect, Bluetooth, Apple CarPlay và Android Auto, cũng có
          thể thu thập Dữ liệu Xe Kết Nối từ các phương tiện của chúng tôi theo
          chính sách quyền riêng tư của họ và chia sẻ Dữ liệu Xe Kết Nối với
          chúng tôi. Xin lưu ý rằng, Dữ liệu Xe Kết Nối có thể được nhà sản xuất
          xe kết nối hoặc chủ sở hữu của dịch vụ tích hợp nhận trước khi chúng
          tôi nhận được. Chúng tôi khuyến khích bạn xem lại chính sách quyền
          riêng tư của nhà sản xuất và dịch vụ tích hợp liên quan cùng với các
          điều khoản và điều kiện khác liên quan đến Dữ liệu Xe Kết Nối để hiểu
          rõ thông tin cá nhân nào đang được thu thập từ xe kết nối và mục đích
          mà nó được thu thập. Nếu bạn chọn đồng bộ hóa thông tin hoặc thiết bị
          của mình với xe (sử dụng Bluetooth, USB hoặc các phương tiện khác), dữ
          liệu từ bạn hoặc thiết bị của bạn có thể được truy cập và lưu trữ trên
          các hệ thống của xe, chẳng hạn như hệ thống giải trí.{" "}
          <b>
            Vui lòng luôn hủy ghép nối thiết bị của bạn và đảm bảo rằng bạn thực
            hiện các bước cần thiết để xóa thông tin của mình khỏi hệ thống khi
            bạn trả lại xe vào cuối kỳ thuê. Nếu bạn không làm điều này, thông
            tin của bạn có thể được giữ lại trong hệ thống và có thể được truy
            cập bởi bất kỳ ai có quyền sử dụng xe
          </b>
          . Chúng tôi không chịu trách nhiệm về bất kỳ dữ liệu nào còn lại trong
          xe do việc bạn sử dụng.
        </p>
      </div>

      <div className="pt-5" id="contact_us">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>7. LIÊN HỆ VỚI CHÚNG TÔI</b>
        </p>
        <p className="mb-4">
          Nếu bạn có câu hỏi, nhận xét hoặc đề xuất, bạn luôn có thể liên hệ với
          chúng tôi theo các cách sau. Chúng tôi có thể cần xác minh danh tính
          của bạn trước khi hỗ trợ yêu cầu của bạn.
        </p>
        <table
          border="1"
          cellspacing="0"
          cellpadding="0"
          className="tablePrivacy mb-5"
        >
          <tbody>
            <tr>
              <td width="400" valign="top">
                <p>
                  <b>&nbsp;Tôi muốn…</b>
                </p>
                <p>&nbsp;</p>
              </td>
              <td width="400" valign="top">
                <p>
                  <b>&nbsp;Cách liên hệ với chúng tôi</b>
                </p>
              </td>
            </tr>
            <tr>
              <td width="292" valign="top">
                <p>&nbsp;Liên hệ dịch vụ khách hàng của Avis</p>
              </td>
              <td width="331" valign="top">
                <p>&nbsp;Điện thoại: 1-800-352-7900</p>
                <p>
                  &nbsp;Email:&nbsp;{" "}
                  <Link className="linked text-decoration-none">
                    custserv@avis.com
                  </Link>
                </p>
                <p>
                  &nbsp;Thư:&nbsp;&nbsp;&nbsp;&nbsp; P.O. Box
                  699000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Tulsa, OK 74169-9000
                </p>
                <p>&nbsp;Giờ làm việc: 7:30 sáng - 7:30 tối CST</p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td width="292" valign="top">
                <p>&nbsp;Liên hệ dịch vụ khách hàng của Budget</p>
              </td>
              <td width="331" valign="top">
                <p>&nbsp;Điện thoại: 1-800-621-2844</p>
                <p>
                  &nbsp;Email:&nbsp;{" "}
                  <Link
                    className="linked text-decoration-none"
                    to="mailto:budgetcustomerservice@budgetgroup.com"
                  >
                    budgetcustomerservice@budgetgroup.com
                  </Link>
                </p>
                <p>
                  &nbsp;Thư:&nbsp;&nbsp;&nbsp;&nbsp; P.O. Box 699000
                  <br /> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Tulsa,
                  OK 74169-9000
                </p>
                <p>&nbsp;Giờ làm việc: 7:30 sáng - 7:30 tối CST</p>
                <p>&nbsp;</p>
              </td>
            </tr>

            <tr>
              <td width="292" valign="top">
                <p>&nbsp;Liên hệ dịch vụ khách hàng của Budget Truck Rental</p>
              </td>
              <td width="331" valign="top">
                <p>
                  &nbsp;Điện thoại: 1-800-462-8343
                  <br /> &nbsp;Email:&nbsp;{" "}
                  <Link
                    className="linked text-decoration-none"
                    to="mailto:btr-customerserviceVAB@avisbudget.com"
                  >
                    btr-customerserviceVAB@avisbudget.com
                  </Link>
                  <br /> &nbsp;Thư:&nbsp;&nbsp;&nbsp;&nbsp; P.O. Box 699000
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Tulsa, OK
                  74169-9000
                </p>
                <p>&nbsp;Giờ làm việc: 7:30 sáng - 7:30 tối CST</p>
                <p>&nbsp;</p>
              </td>
            </tr>

            <tr>
              <td width="292" valign="top">
                <p>&nbsp;Liên hệ dịch vụ khách hàng của Payless</p>
              </td>
              <td width="331" valign="top">
                <p>&nbsp;Điện thoại: 1-800-729-5377</p>
                <p>
                  &nbsp;Email:&nbsp;&nbsp;
                  <Link
                    className="linked text-decoration-none"
                    to="mailto:customerservice@paylesscar.com"
                  >
                    customerservice@paylesscar.com
                  </Link>
                </p>
                <p>
                  &nbsp;Thư:&nbsp;&nbsp;&nbsp;&nbsp; P.O. Box
                  699000&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Tulsa, OK 74169-9000
                </p>
                <p>&nbsp;Giờ làm việc: 7:30 sáng - 7:30 tối CST</p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td width="292" valign="top">
                <p id="accessupdate">
                  &nbsp;Truy cập, cập nhật hoặc chỉnh sửa một số thông tin của
                  tôi
                </p>
              </td>
              <td width="331" valign="top">
                <p>
                  &nbsp;(1) Đăng nhập vào tài khoản của bạn và cập nhật hồ sơ
                  của bạn; hoặc
                </p>
                <p>
                  &nbsp;(2) Liên hệ với bất kỳ đại diện dịch vụ khách hàng nào ở
                  trên
                </p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td width="292" valign="top">
                <p>
                  &nbsp;·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Đặt
                  câu hỏi về Thông báo và thực tiễn Quyền riêng tư của ABG
                </p>
                <p>
                  &nbsp;·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Thông
                  báo cho ABG rằng chúng tôi có thể đã nhận được thông tin cá
                  nhân của một đứa trẻ dưới 13 tuổi
                </p>
              </td>
              <td width="331" valign="top">
                <p>
                  &nbsp;Email:&nbsp;{" "}
                  <Link
                    className="linked text-decoration-none"
                    to="mailto:ABGprivacy@avisbudget.com"
                  >
                    ABGprivacy@avisbudget.com
                  </Link>
                </p>
                <p>&nbsp;Thư:&nbsp;&nbsp;&nbsp;&nbsp; Avis Budget Group</p>
                <p>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;6 Sylvan Way
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Parsippany, NJ
                  07054
                  <br />
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Attn: Privacy
                  Officer
                </p>
                <p>&nbsp;Điện thoại: 866-284-7669</p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td width="292" valign="top">
                <p>&nbsp;Yêu cầu ngừng nhận email tiếp thị từ ABG</p>
              </td>
              <td width="331" valign="top">
                <p>
                  &nbsp;(1) Nhấp vào “hủy đăng ký” ở cuối email chúng tôi đã gửi
                  cho bạn; hoặc
                </p>
                <p>
                  &nbsp;(2) Đăng nhập vào tài khoản của bạn và cập nhật hồ sơ
                  của bạn; hoặc
                </p>
                <p>
                  &nbsp;(3) Liên hệ với bất kỳ đại diện dịch vụ khách hàng nào ở
                  trên
                </p>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td width="292" valign="top">
                <p>
                  &nbsp;Yêu cầu ngừng nhận tin nhắn tiếp thị từ Avis và/hoặc
                  Budget
                </p>
              </td>
              <td width="331" valign="top">
                <p>
                  Nhắn tin từ “STOP” để phản hồi bất kỳ tin nhắn tiếp thị nào
                  bạn nhận được.
                </p>
                <p>&nbsp;</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="pt-5" id="independent_locations">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>8. ĐỊA ĐIỂM ĐỘC LẬP</b>
        </p>
        <p className="mb-3">
          Xin lưu ý, nhiều địa điểm của Avis, Budget, Payless và Budget Truck
          được sở hữu và điều hành bởi một đơn vị nhượng quyền độc lập và không
          thuộc sở hữu hoặc kiểm soát bởi ABG hoặc bất kỳ công ty con hoặc chi
          nhánh nào của ABG. Thông báo Quyền riêng tư này chỉ áp dụng cho việc
          xử lý thông tin cá nhân của chúng tôi và không áp dụng cho việc xử lý
          thông tin cá nhân của bạn bởi một đơn vị nhượng quyền độc lập. Mỗi đơn
          vị nhượng quyền độc lập này chịu trách nhiệm cho việc thu thập và xử
          lý thông tin cá nhân của mình và có thể thu thập, sử dụng hoặc tiết lộ
          thông tin cá nhân khác với những gì được mô tả trong Thông báo Quyền
          riêng tư này.
        </p>
        <p className="mb-5">
          Cụ thể, đơn vị nhượng quyền chịu trách nhiệm cho việc thu thập và xử
          lý các khoản thanh toán của mình và có thể thực hiện các chiến dịch
          e-mail riêng và các hình thức tiếp thị khác đối với bạn. Vì chúng tôi
          không kiểm soát việc sử dụng thông tin cá nhân của đơn vị nhượng quyền
          độc lập và chúng tôi không chịu trách nhiệm về việc tuân thủ luật bảo
          mật của đơn vị nhượng quyền độc lập, bạn nên gửi bất kỳ câu hỏi nào về
          thực tiễn quyền riêng tư của đơn vị nhượng quyền đến đơn vị nhượng
          quyền cụ thể đó.
        </p>
      </div>

      <div className="pt-5" id="state_privacy">
        <p className="mb-3 mt-5" style={{ fontSize: "20px" }}>
          <b>9. Tiết lộ Quyền Riêng tư Bổ sung của Tiểu bang Hoa Kỳ</b>
        </p>
        <p className="mb-3">
          <b>
            <i>Người cư trú Nevada:</i>
          </b>{" "}
          Nếu bạn là cư dân của tiểu bang Nevada tại Hoa Kỳ, xin lưu ý Chương
          603A của Bộ luật Nevada Revised cho phép cư dân Nevada chọn không tham
          gia các giao dịch bán trong tương lai của một số thông tin được trang
          web thu thập hoặc sẽ thu thập về cư dân đó. Để gửi yêu cầu như vậy,
          vui lòng liên hệ với chúng tôi tại{" "}
          <Link className="linked text-decoration-none">
            ABGprivacy@avisbudget.com
          </Link>{" "}
          với dòng tiêu đề “Nevada Opt-Out”.
        </p>
        <p className="mb-3">
          <b>
            <i>Người cư trú California và Virginia:</i>
          </b>{" "}
          Các Tiết lộ về Quyền Riêng tư của Tiểu bang Hoa Kỳ này (
          <b>"Tiết lộ của Tiểu bang Hoa Kỳ"</b>) bổ sung thông tin có trong
          Thông báo Quyền Riêng tư của chúng tôi và chỉ áp dụng cho các cá nhân
          cư trú tại Tiểu bang California và Virginia (<b>"người tiêu dùng"</b>{" "}
          hoặc <b>"bạn"</b>).
        </p>
        <p className="mb-3">
          <b>
            <u>Thu thập và Sử dụng Thông tin Cá nhân</u>
          </b>
        </p>
        <p className="mb-3">
          <u>Thông tin Cá nhân Được Thu thập</u>
        </p>
        <p className="mb-3">
          Như đã mô tả chi tiết hơn trong phần{" "}
          <Link className="linked text-decoration-none">
            Các Loại Thông tin Chúng tôi Thu thập và Cách Chúng tôi Thu thập
            Chúng
          </Link>{" "}
          của{" "}
          <Link className="linked text-decoration-none">
            Thông báo Quyền Riêng tư
          </Link>
          , chúng tôi thu thập các loại thông tin cá nhân sau đây:
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Nhận dạng,</b> như tên, địa chỉ, số
          điện thoại, địa chỉ email, ngày sinh, hộ chiếu hoặc thông tin nhận
          dạng của chính phủ khác, thông tin giấy phép lái xe, thông tin tài
          khoản, ID thành viên hoặc chương trình, hoặc các nhận dạng tương tự
          khác.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Hồ sơ Khách hàng,</b> như số giấy
          phép lái xe, cờ hoạt động gian lận, nhà cung cấp bảo hiểm và thông tin
          bảo hiểm, số hộ chiếu, thông tin thẻ ghi nợ, thông tin thẻ tín dụng,
          thông tin tài khoản ngân hàng hoặc thông tin thanh toán hoặc tài chính
          khác.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Đặc điểm Phân loại Được Bảo vệ,</b>{" "}
          chẳng hạn như tuổi, giới tính và thông tin khuyết tật liên quan để
          cung cấp các thiết bị lái xe thích ứng.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Thông tin Thương mại,</b> chẳng hạn
          như thông tin về việc bạn có tham gia chương trình công ty hoặc phần
          thưởng hay không, các sản phẩm hoặc dịch vụ đã mua hoặc được xem xét
          và việc bạn sử dụng các dịch vụ của chúng tôi, bao gồm chi tiết đặt
          chỗ.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Thông tin Sinh trắc học,</b> chẳng
          hạn như hình dạng khuôn mặt chỉ được đối tác bên thứ ba của chúng tôi
          sử dụng cho mục đích xác minh danh tính. Chúng tôi không nhận được bất
          kỳ thông tin sinh trắc học nào được tạo ra từ các quy trình này.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Thông tin Internet/Mạng,</b> chẳng
          hạn như thông tin thiết bị, nhật ký và dữ liệu phân tích.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Dữ liệu Vị trí Địa lý,</b> chẳng
          hạn như thông tin về vị trí thực tế của bạn được thu thập từ các tính
          năng định vị trên thiết bị hoặc xe ABG của bạn, bao gồm địa chỉ IP và
          GPS của bạn (ví dụ: vĩ độ và/hoặc kinh độ).
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Thông tin Cảm giác,</b> chẳng hạn
          như hình ảnh và video bạn cung cấp cho chúng tôi hoặc chúng tôi ghi
          lại liên quan đến các dịch vụ của chúng tôi hoặc việc giám sát cơ sở
          của chúng tôi, và các bản ghi âm cuộc gọi giữa bạn và chúng tôi, nếu
          được pháp luật cho phép.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <b>Thông tin Chuyên nghiệp/Nghề nghiệp</b>, bao gồm doanh nghiệp hoặc
          tổ chức mà bạn có liên kết và, nếu có, chức danh của bạn với doanh
          nghiệp hoặc tổ chức đó và thông tin liên quan đến vai trò của bạn với
          doanh nghiệp hoặc tổ chức.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <b>Thông tin Cá nhân Khác,</b> chẳng hạn như thông tin du lịch hoặc
          chuyến bay của bạn, hồ sơ lái xe của bạn, thông tin khiếu nại, thông
          tin hành vi lái xe và thông tin bổ sung mà bạn cung cấp trực tiếp cho
          chúng tôi hoặc thông qua dịch vụ của bên thứ ba, bao gồm tin nhắn bạn
          gửi cho chúng tôi thông qua dịch vụ của chúng tôi hoặc cung cấp cho
          chúng tôi trên mạng xã hội.
        </p>
        <p className="mb-3">
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <b>Suy luận,</b> bao gồm thông tin được tạo ra từ việc bạn sử dụng
          trang web của chúng tôi và lịch sử lái xe, xe và thuê xe của bạn phản
          ánh sở thích của bạn.
        </p>
        <p className="mb-3 text-decoration-underline">
          Nguồn Thông tin Cá nhân
        </p>
        <p className="mb-3">
          Chúng tôi thu thập thông tin cá nhân trực tiếp từ bạn, từ trình duyệt
          hoặc thiết bị của bạn khi bạn tương tác với các trang web, ứng dụng và
          dịch vụ trực tuyến khác của chúng tôi, từ xe ABG của bạn và từ các đối
          tác kinh doanh và chi nhánh của chúng tôi, các bên nhượng quyền ABG,
          bên thứ ba mà bạn chỉ định chia sẻ thông tin với chúng tôi và các nhà
          cung cấp thông tin bên thứ ba khác. Đối với đại diện của khách hàng
          doanh nghiệp, nhà cung cấp dịch vụ và các bên thứ ba khác của chúng
          tôi, chúng tôi cũng thu thập thông tin cá nhân từ thực thể mà bạn đại
          diện.
        </p>
        <p className="mb-3 text-decoration-underline">Mục đích Thu thập</p>
        <p className="mb-3">
          Chúng tôi thu thập thông tin cá nhân về bạn vì các mục đích được mô tả
          trong phần Cách Sử dụng Thông tin của Thông báo Quyền Riêng tư của
          chúng tôi. Đối với đại diện của khách hàng doanh nghiệp, nhà cung cấp
          dịch vụ và các bên thứ ba khác của chúng tôi, chúng tôi cũng thu thập
          thông tin cá nhân của bạn để duy trì mối quan hệ liên tục giữa chúng
          tôi và thực thể mà bạn đại diện và để liên hệ với bạn liên quan đến
          mối quan hệ của chúng tôi với thực thể mà bạn đại diện.
        </p>
        <p className="mb-3 text-decoration-underline">Thông tin Nhạy cảm</p>
        <p className="mb-3">
          Một số danh mục thông tin cá nhân mà chúng tôi thu thập có thể được
          phân loại là "nhạy cảm" theo luật bảo mật của một số tiểu bang Hoa Kỳ
          (“thông tin nhạy cảm”), bao gồm nhận dạng của chính phủ (chẳng hạn như
          số giấy phép lái xe), thông tin thẻ thanh toán, thông tin sinh trắc
          học (được thu thập và xử lý bởi các nhà cung cấp dịch vụ của chúng
          tôi), dữ liệu vị trí địa lý chính xác và thông tin y tế liên quan khi
          cần thiết để cung cấp các thiết bị lái xe thích ứng. Chúng tôi sử dụng
          thông tin nhạy cảm này vì các mục đích được nêu trong phần Cách Sử
          dụng Thông tin của Thông báo Quyền Riêng tư của chúng tôi trong phạm
          vi cần thiết cho việc vận hành Dịch vụ của chúng tôi, để ký kết và
          thực hiện hợp đồng với bạn, để tuân thủ các yêu cầu pháp lý và quy
          định, để bảo vệ tính mạng hoặc sự an toàn của bất kỳ ai hoặc khi được
          pháp luật cho phép cho các mục đích nội bộ của chúng tôi.
        </p>
        <p className="mb-3 text-decoration-underline">
          Thông tin Đã được Vô danh
        </p>
        <p className="mb-3">
          Đôi khi chúng tôi có thể nhận được hoặc xử lý thông tin cá nhân để tạo
          ra thông tin đã được vô danh mà không còn có thể được sử dụng để suy
          ra thông tin về, hoặc được liên kết với, một cá nhân hoặc hộ gia đình
          cụ thể. Khi chúng tôi duy trì thông tin đã được vô danh, chúng tôi sẽ
          duy trì và sử dụng thông tin ở dạng đã vô danh và không cố gắng tái
          nhận dạng thông tin đó trừ khi được yêu cầu hoặc cho phép theo luật
          pháp.
        </p>
        <p className="mb-3">
          <b>
            <u>
              Tiết lộ Thông tin Cá nhân, Bán và Sử dụng cho Quảng cáo Được Nhắm
              mục tiêu
            </u>
          </b>
        </p>
        <p className="mb-3">
          <u>Tiết lộ</u>
        </p>
        <p className="mb-3">
          Như đã mô tả chi tiết hơn trong phần Cách Thông tin được Chia sẻ của
          Thông báo Quyền Riêng tư của chúng tôi, chúng tôi có thể tiết lộ các
          danh mục thông tin cá nhân đã xác định ở trên cho các loại bên thứ ba
          sau đây cho các mục đích kinh doanh khác nhau: các công ty chi nhánh
          của chúng tôi, các bên nhượng quyền, nhà cung cấp bảo mật, nhà cung
          cấp dịch vụ, đối tác kinh doanh, nhà cung cấp tiếp thị và đối tác,
          mạng quảng cáo và đối tác quảng cáo, nhà cung cấp phân tích, mạng xã
          hội, các doanh nghiệp khác khi cần thiết để cung cấp dịch vụ của chúng
          tôi và một số bên thứ ba nhất định khi bạn yêu cầu hoặc đã cung cấp sự
          đồng ý, liên quan đến giao dịch doanh nghiệp hoặc khi chúng tôi được
          yêu cầu theo luật pháp hoặc liên quan đến các quy trình pháp lý khác.
        </p>
        <p className="mb-3">
          <u>Bán và Quảng cáo Được Nhắm mục tiêu</u>
        </p>
        <p className="mb-3">
          Trừ khi bạn đã thực hiện quyền chọn không tham gia, chúng tôi đã bán
          hoặc có thể bán trong tương lai, Nhận dạng (chẳng hạn như tên, địa chỉ
          email và thông tin liên hệ của bạn), Thông tin Thương mại (chẳng hạn
          như các sản phẩm đã bán hoặc được xem xét), Thông tin Internet/Mạng
          (chẳng hạn như dữ liệu phân tích) và Suy luận (được tạo ra từ các
          tương tác của bạn với dịch vụ của chúng tôi) cho bên thứ ba có thể sử
          dụng thông tin này để liên lạc với bạn hoặc để cung cấp cho bạn các
          sản phẩm và dịch vụ có thể quan tâm đến bạn, chẳng hạn như các dịch vụ
          liên quan đến việc thuê xe của bạn hoặc dịch vụ di chuyển hoặc liên
          quan đến ngôi nhà.
        </p>
        <p className="mb-3">
          Chúng tôi cũng có thể bán hoặc tiết lộ thông tin cá nhân (bao gồm Nhận
          dạng, Thông tin Thương mại, Thông tin Internet/Mạng và Suy luận) cho
          các mạng quảng cáo bên thứ ba nhất định, mạng xã hội và đối tác quảng
          cáo để cung cấp quảng cáo được nhắm mục tiêu (còn được gọi là "quảng
          cáo hành vi theo ngữ cảnh chéo") và nội dung được cá nhân hóa cho bạn
          trên các dịch vụ của chúng tôi, trên các trang web và dịch vụ khác mà
          bạn có thể sử dụng và trên các thiết bị khác mà bạn có thể sử dụng,
          cũng như để cung cấp các dịch vụ liên quan đến quảng cáo như báo cáo,
          phân bổ, phân tích và nghiên cứu thị trường.
        </p>
        <p className="mb-3">
          Tuy nhiên, chúng tôi không bán thông tin cá nhân của người tiêu dùng
          mà chúng tôi biết là dưới 16 tuổi hoặc chia sẻ thông tin đó cho các
          mục đích quảng cáo được nhắm mục tiêu.
        </p>
        <p className="mb-3">
          Tùy thuộc vào tiểu bang cư trú của bạn và các giới hạn pháp lý và
          ngoại lệ nhất định, bạn có thể hạn chế hoặc chọn không tham gia bán
          thông tin cá nhân hoặc xử lý thông tin cá nhân cho mục đích quảng cáo
          được nhắm mục tiêu (như được mô tả trong phần Lựa chọn Quyền Riêng tư
          của bạn dưới đây).
        </p>
        <p className="mb-3">
          <u>Thông tin Nhạy cảm</u>
        </p>
        <p className="mb-5">
          Mặc dù thông tin nhạy cảm có thể được tiết lộ cho các mục đích kinh
          doanh như đã mô tả ở trên, chúng tôi không bán thông tin nhạy cảm,
          cũng không xử lý hoặc chia sẻ thông tin nhạy cảm cho mục đích quảng
          cáo được nhắm mục tiêu. Tuy nhiên, tùy thuộc vào tiểu bang cư trú của
          bạn và các giới hạn pháp lý và ngoại lệ nhất định, bạn có thể hạn chế
          hoặc rút lại sự đồng ý của mình đối với việc xử lý thông tin nhạy cảm
          của chúng tôi (như được mô tả trong phần Lựa chọn Quyền Riêng tư của
          bạn dưới đây), trừ khi việc xử lý thông tin nhạy cảm của bạn là cần
          thiết để cung cấp cho bạn dịch vụ hoặc theo yêu cầu của pháp luật.
        </p>
      </div>
    </div>
  );
};

export default ContentPrivacy;
